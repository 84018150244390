import {reformatDatePickerTime} from "./general";
import {clearRosterChart, loadRosterChart} from "./roster";
import {setSelectedTabFromHash} from "./group";
import addPerson from "./cbar-add-person.module";
import {handleFavouriteButton} from "./favourite-handler.module";

var roleUpdateListener = null;
var roleRemoveListener = null;
var roleAddListener = null;

var selectedListenerElement = null;
var selectedListenerRoleList = null;

$(function () {
    "use strict";

    // Toggle the start time and end time datetime input fields (used for group roles)
    $(".toggle_time_inputs").on("click", function () {
        $(".group-op .start_time, .group-op .end_time").toggle();
    });

    $('md-dialog.add-group-owners, md-dialog.add-event-owners').on('open', function(){
        // fixes interaction with dropdown
        this.scroller.style.overflow = "visible";
        this.content.style.overflow = "visible";
    })

    $(".relationship-remove").on("tap", function (e) {
        var buttonClicked = this;
        var spinner = $(buttonClicked).siblings('paper-spinner')[0];
        var eventDiv = $(buttonClicked).closest('.event-list')[0];

        if(!eventDiv){
            // this should only fire for events i presume
            return;
        }

        var hiddenDiv = eventDiv.querySelector('.no-event-display');

        spinner.active = true;

        var relationshipData = {
            eventId: buttonClicked.dataset.event,
            entityId: buttonClicked.dataset.eventto,
            entity: "Event",
        }

        $.ajax({
            url: '/events/ajaxendeventrelationship',
            type: 'POST',
            data: relationshipData,
            success: function (data) {
                spinner.removeAttribute('active');
                var displayDiv = $(buttonClicked).closest('.event-list li');
                displayDiv.slideUp("fast");

                // we have to wait out jqueries animation here
                setTimeout( () => {
                    var shownDivContents = eventDiv.querySelectorAll('li:not(.ajax-blueprint):not([style*="display: none"])');

                    if(shownDivContents.length === 0){
                        hiddenDiv.removeAttribute('hidden');
                    }
                }, 250);
            }
        })
    });

    var thisPersonRole;
    var $paperRoleDropdown = $('paper-dropdown-menu.roles_to_add');

    $(".add_person_link").on("click", "a", function (event) {
        event.preventDefault();
        if ($(".add_person").is(":visible")) {
            $(".add_person").slideUp("fast");
        } else {
            $(".add_person").slideDown("fast");
        }
    });
    $('.user-actions').on('iron-select', function(event){
       var menu = event.target;
       var value = event.target.selected;

       // unset so its selectable again
       menu.selected = null;

       if(value === "add") {
           resetFields(this);

           thisPersonRole = getThisPersonRole(this);

           thisPersonRole.find("input.action").val("add");
           thisPersonRole.find(".delete").hide();
           thisPersonRole.find(".submit").text("Add");

           var usersName = $(this).attr('data-first-name') + " " + $(this).attr('data-last-name');

           addPerson.openDirectAddDialog(thisPersonRole.find("input.target_entity_term").val(), $(this).closest(".person_summary").attr("profile-id"), usersName, null, eventId);

           selectedListenerElement = $(this);

           setupRoleAddedListener(thisPersonRole);
       }
    });

    var $personSummary = $(".person_summary");

    // When the add role button is clicked OR role name is clicked
    $personSummary.on("click", "a.role_add, a.role_edit, a.role_invite, .group_role_assign, a.group_role_edit, .event_role_assign, a.event_role_edit ",
        function (event) {
            event.preventDefault();
            $paperRoleDropdown = $('paper-dropdown-menu.roles_to_add');
            var rosterUpdateRoleDialog = document.querySelector('cc-update-role#team-update-role-dialog');

            resetFields(this);
            thisPersonRole = getThisPersonRole(this);

            if ($(this).hasClass("role_edit")) {
                thisPersonRole.find("input.action").val("update");
                thisPersonRole.find(".delete").show();
                thisPersonRole.find(".submit").text("Update");
            } else if ($(this).hasClass("role_add")) {
                thisPersonRole.find("input.action").val("add");
                thisPersonRole.find(".delete").hide();
                thisPersonRole.find(".submit").text("Add");
            } else if ($(this).hasClass("role_invite")) {
                thisPersonRole.find("input.action").val("add");
                thisPersonRole.find(".delete").hide();
                thisPersonRole.find("button.decline").show();
                thisPersonRole.find(".submit").text("Add Role");
            } else if ($(this).is(".group_role_assign,.event_role_assign")) {
                var usersName = $(this).attr('data-first-name') + " " + $(this).attr('data-last-name');

                addPerson.openDirectAddDialog(thisPersonRole.find("input.target_entity_term").val(), $(this).closest(".person_summary").attr("profile-id"), usersName, null, eventId);
                selectedListenerElement = $(this);
                setupRoleAddedListener(thisPersonRole);
            } else if ($(this).is(".group_role_edit, .event_role_edit")) {
                thisPersonRole.find("input.source_entity_id").val($(this).closest(".person_summary").attr("profile-id"));
                thisPersonRole.find("input.action").val("update");
                thisPersonRole.find(".submit").text("Update");

                // If the current DOM displays multiple events (e.g. Person page, Events tab),
                // set the closest containing eventid and the current role being edited
                // Then get the available roles for the current event
                if (typeof $(this).closest("li.event_summary").attr("event-id") !== "undefined") {
                    thisPersonRole.find("select.event-op-id").val($(this).closest("li.event_summary").attr("event-id"));
                    thisPersonRole.find("select.event-op-id").hide();
                    thisPersonRole.find("input.target_entity_id").val($(this).closest("li.event_summary").attr("event-id"));
                    thisPersonRole.find("input.action").val("list-roles-operation");
                    thisPersonRole.find(".loading-roles").fadeIn("slow");
                    personRoleAction(this);
                }

                var role = {
                    Entity: thisPersonRole.find("input.target_entity_term").val(),
                    EntityID: thisPersonRole.find("input.target_entity_id").val(),
                    ProfileID: $(this).closest(".person_summary").attr("profile-id"),
                    role_id : $(this).attr("data-relationship"),
                    StartTime : $(this).attr("data-start-time"),
                    EndTime : $(this).attr("data-end-time"),
                    Quota: parseInt($(this).attr("data-quota")),
                    FirstName : $(this).attr('data-first-name'),
                    LastName : $(this).attr('data-last-name'),
                    Title : $(this).attr('data-relationship-title'),
                    RelationshipRole : $(this).attr('data-relationship-role'),
                };

                if(!rosterUpdateRoleDialog.entityId || rosterUpdateRoleDialog.entityId !== role.EntityID){
                    rosterUpdateRoleDialog.entityId = role.EntityID;
                }

                rosterUpdateRoleDialog.roleToUpdate = {};

                requestAnimationFrame(() => {
                    rosterUpdateRoleDialog.roleToUpdate = Object.assign({}, role);
                    rosterUpdateRoleDialog.firstName = role.FirstName;
                    rosterUpdateRoleDialog.lastName = role.LastName;

                    rosterUpdateRoleDialog.open();
                });

                var person = getThisPerson($(this));
                var personRoleListItem = $(person).find("div.person_roles .existing_roles>div").not(".ajax-blueprint");

                selectedListenerElement = $(this);
                selectedListenerRoleList = personRoleListItem;

                setupRoleRemovedListener(rosterUpdateRoleDialog, person, thisPersonRole);
                setupRoleUpdatedListener(rosterUpdateRoleDialog, person, thisPersonRole);
            }

            var dropdown = thisPersonRole.find(".roles_to_add");
            if(!!dropdown[0].contentElement) {
                // make sure we reset this here
                dropdown[0].contentElement.selected = 1;
                dropdown[0].contentElement.selected = $(this).attr("data-quota") ? $(this).attr("data-quota") : 0;
            } else {
                thisPersonRole.find("select.roles_to_add").val($(this).attr("data-quota"));
            }

            thisPersonRole.find(".start_time").val($(this).attr("data-start-time"));
            thisPersonRole.find(".end_time").val($(this).attr("data-end-time"));
            thisPersonRole.find("input.relationship_id").val($(this).attr("data-relationship"));
            thisPersonRole.find("input.quota").val($(this).attr("data-quota"));

            // Show the relationship status dropdown only if it is not "Accepted" (i.e. still pending).
            var statusId = $(this).attr("data-status");
            var statusSelect = thisPersonRole.find(".relationship_status");

            if(!!statusSelect[0]) {
                statusSelect[0].contentElement.selected = false;
                statusSelect[0].contentElement.selected = statusId;
            }

            if (typeof statusId !== "undefined" && parseInt(statusId) !== 1) {
                statusSelect.show();
            } else {
                statusSelect.hide();
            }
        });


    // When the role dropdown is changed
    var $paperRoleDropdown = $('paper-dropdown-menu.roles_to_add');
    $paperRoleDropdown.on("selected-item-changed", function (e) {
        // we need to make sure this isnt blank
        if(e.detail.value === null){
            return;
        }
        handleRoleChanged($(e.detail.value), $(this));
    });

    $personSummary.on("change", "select.roles_to_add", function (event) {
        handleRoleChanged($(this).find("option:selected"), $(this));
    });

    // Cancel button - hide the add roles dialogue
    $personSummary.on("click", ".cancel", function () {
        resetFields(this);
        thisPersonRole = getThisPersonRole(this);
        // Reset all fields
        thisPersonRole.find("paper-dropdown-menu.roles_to_add").val(0);
        thisPersonRole.hide();
    });

    // Delete button - hide the add roles dialogue
    $personSummary.on("click", ".delete", function () {
        thisPersonRole = getThisPersonRole(this);
        thisPersonRole.find("input.action").val("remove");
        thisPersonRole.find(".loading-roles").fadeIn("slow");
        personRoleAction(this);
    });

    // Delete button - hide the add roles dialogue
    $personSummary.on("click", ".decline", function () {
        thisPersonRole = getThisPersonRole(this);
        thisPersonRole.find("input.action").val("decline");
        thisPersonRole.find(".loading-roles").fadeIn("slow");
        personRoleAction(this);
    });

    $personSummary.on("click", ".email_invitation", function () {
    	thisPersonRole = getThisPersonRole(this);

        var eventName = $("#thing_name").val();
        var firstName = thisPersonRole.find(".person_name").text().split(" ")[0];
        var email = thisPersonRole.find(".person_email").text();

        var  personalMessage = "Hi " + firstName + ", \n" +
            "Please accept my invite to "+ eventName + ".";

        thisPersonRole.find(".personal_message").val(personalMessage);
        $("#invitation_email").val(email);

    	thisPersonRole.find(".email_invitation_div").show();
    });

    // Submit button - hide the add roles dialogue
    $personSummary.on("click", ".submit", function () {

        // For pages using operations-forms.phtml, check that an entity and a role is selected
        if ($("body").is("#profiles, #groups, #events")) {
            thisPersonRole = getThisPersonRole(this);
            var entityName = thisPersonRole.find("input.target_entity_term").val();

            // Ensure that the target group or event is selected
            if (thisPersonRole.find("input.target_entity_id").val() === "0" || thisPersonRole.children("select").val() === "0") {
                $(thisPersonRole).find(".operation-control .messages")
                    .html($("<div class='error message'>No " + entityName + " selected</div>"));

                return;

            // Ensure that an available role is selected

            } else if (thisPersonRole.find("input.quota").val().length === 0) {
                // there is an issue where change events are not firing correctly, we add a check to fix here
                var roleDropdown = thisPersonRole.find('paper-dropdown-menu.roles_to_add');
                if(!roleDropdown){
                    $(thisPersonRole).find(".operation-control .messages").html($("<div class='error message'>No Available Role selected</div>"));
                    return;
                }

                var roleValue = roleDropdown[0].selectedItem.getAttribute('value');

                if(!roleValue){
                $(thisPersonRole).find(".operation-control .messages").html($("<div class='error message'>No Available Role selected</div>"));
                    return;
                }

                if(roleValue){
                    thisPersonRole.find("input.quota").val(roleDropdown[0].selectedItem.getAttribute('value'));
                }
            // Ensure that end time greater than start time
            } else if(thisPersonRole.find(".error.end_time").length > 0){
                $(thisPersonRole).find(".operation-control .messages").html($("<div class='error message'>End Time Before Start Time</div>"));
                return;
            }

                const buttonText = $(this).html().toLowerCase();
                const isAddAction = buttonText === "assign";

                if(thisPersonRole.find("input.action").val() === "list-roles-operation") {
                    if (isAddAction) {
                        thisPersonRole.find("input.action").val("add");
                    } else {
                        thisPersonRole.find("input.action").val(buttonText);
                    }
                }
                thisPersonRole.find(".loading-roles").fadeIn("slow");

                if (isAddAction) {
                    addUserToEntity(this);
                }else {
                    personRoleAction(this);
                }

        // For pages not using operations-forms, ensure a quota is selected before performing the action
        } else {
            if (thisPersonRole.find("input.quota").val().length === 0) {
                $(thisPersonRole).find(".operation-control .messages").html($("<div class='error message'>No Available Role selected</div>"));
            } else {
                addUserToEntity(this);
            }
        }
    });

    function addUserToEntity(element){
        // create a new formdata object
        var formData = new FormData();

        // get the person element
        var person = getThisPerson(element);
        // get the profile ID of the user

        var profileId = person.attr('profile-id');

        // get the wrapper of the form so we can grab the role
        var searchWrapper = $(person).closest("div.person_summary");
        // grabs the dropdown
        var roleSelect = $(searchWrapper).find('.roles_to_add');
        /// grabs the selected value of the dropdown

        var selectedQuota = !!roleSelect[0].contentElement ? roleSelect[0].contentElement.selected : roleSelect.val();

        // if were adding a quota from an event, we need to get the start time and end time and add this to the formData
        var startTime = $(searchWrapper).find('.start_time').val();
        var endTime = $(searchWrapper).find('.end_time').val();


        // add values to formData object
        formData.append('quota_ids[]', selectedQuota);
        formData.append('profile_id', profileId);
        formData.append('return_json', true);

        // if we have a start time and end time add it to the data
        if(startTime && endTime){
            formData.append('event_role_start_time', startTime);
            formData.append('event_role_end_time', endTime);
        }

        $.ajax({
            url: "/profiles/add",
            type: "POST",
            data: formData,
            processData: false,
            contentType: false,
            success: function(result) {
                const $messageBox = $(person).find(".operation-control .messages");

                $messageBox.empty();

                if (result.error) {
                    $messageBox.append($("<div class='error message'>").html("Something went wrong"));
                    console.error("There was an error adding the profile: ", result.error);
                    return;
                }

                const roleResult = result.add_role_result[0];

                // load out the spinner
                thisPersonRole.find(".loading-roles").fadeOut("fast");

                const $personRoleList = $(person).find("div.person_roles .existing_roles");
                const $blueprint = $($personRoleList).find("div.ajax-blueprint");
                const $searchWrapper = $(person).closest(".search_wrapper");

                addNewRelationshipRow($blueprint, {data: roleResult}, $personRoleList, $searchWrapper, $messageBox, thisPersonRole);

                $messageBox.append($("<div class='success message'>").html("Success. Role Added."));
            },
            error : function(e){
                var messageBox = $(person).find(".operation-control .messages");
                $messageBox.empty();

                // if this threw an error we need to show that there was a problem with this
                thisPersonRole.find(".loading-roles").fadeOut("fast");
                messageBox.append($("<div class='error message'>").html("Something went wrong"));
                console.error(e.error);
            }
        });

    }

    /**
     * Here send_email_invitation is quite similar to the above function but there are few things different.
     * 1. change action to "email-invitation"
     * 2. check personal message and email to send
     * 3. don't allow other pages
     */
    $personSummary.on("click", ".send_email_invitation", function () {
        var emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        // For pages using operations-forms.phtml, check that an entity and a role is selected
        if ($("body").is("#profiles, #groups, #events")) {
            thisPersonRole = getThisPersonRole(this);
            var entityName = thisPersonRole.find("input.target_entity_term").val();
            //1. change action to "email-invitation"
            thisPersonRole.find("input.action").val("email-invitation");

            // Ensure that the target group or event is selected
            if (thisPersonRole.find("input.target_entity_id").val() === "0" || thisPersonRole.children("select").val() === "0") {
                $(thisPersonRole).find(".operation-control .messages")
                    .html($("<div class='error message'>No " + entityName + " selected</div>"));

            // Ensure that an available role is selected
            } else if (thisPersonRole.find("input.quota").val().length === 0) {
                $(thisPersonRole).find(".operation-control .messages").html($("<div class='error message'>No Available Role selected</div>"));
            // Ensure that end time greater than start time
            } else if(thisPersonRole.find("[name=personal_message]").val() == ""){
            	$(thisPersonRole).find(".operation-control .messages").html($("<div class='error message'>Please enter personal messasge</div>"));

            }else if(thisPersonRole.find("[name=invitation_email]").val() == "" || (!thisPersonRole.find("[name=invitation_email]").val().match(emailRegex))) {
            	$(thisPersonRole).find(".operation-control .messages").html($("<div class='error message'>Please enter valid email to send</div>"));

            // Proceed with adding the profile_relationship if entity and quota are selected
            } else {
                if(thisPersonRole.find("input.action").val() === "list-roles-operation") {
                    if ($(this).html().toLowerCase() === "assign") {
                        thisPersonRole.find("input.action").val("add");
                    } else {
                        thisPersonRole.find("input.action").val($(this).html().toLowerCase());
                    }
                }

                thisPersonRole.find(".loading-roles").fadeIn("slow");
                personRoleAction(this);
            }
        }
    });

    var getThisPersonRole = function (element) {
        // div.operation is contained within the div.display in People page
        if ($(element).is(".group-op-id, .event-op-id") || ($(element).is(".submit") && $(element).closest(".operation").is(".event-op, .group-op"))) {
            return $(element).closest(".display").find("div.operation");
        // Group assignment in People and Group pages div.operation is contained within div#operations-container in People page
        } else if ($(element).is(".group_role_assign") || $(element).is(".group_role_edit")) {
            $("div#operations-container .group-op").appendTo($(element).closest(".display"));
            return $(element).closest(".display").find("div.operation");
        // Event assignment in People, Event, and Person pages
        } else if ($(element).is(".event_role_assign") || $(element).is(".event_role_edit")) {
            $("div#operations-container .event-op").appendTo($(element).closest(".display"));
            return $(element).closest(".display").find("div.operation");
        // Editing a profile_relationship to an event in Event and Person pages
        } else if($(element).is('.event_role_add')){
            $("div#operations-container .event-op").appendTo($(element).closest('.person_summary').find('.person_roles>.display'));
            return $(element).closest('.person_summary').find('.person_roles>.display').find("div.operation");
        } else if ($(element).is('.group_role_add')){
            $("div#operations-container .group-op").appendTo($(element).closest('.person_summary').find('.person_roles>.display'));
            return $(element).closest('.person_summary').find('.person_roles>.display').find("div.operation");
        } else if ($(element).is(".email_invitation") || $(element).is(".send_email_invitation")) {
        	if ($("body").is("#events")) {
        		return $(element).closest(".person_summary");
        	}else{
        		return $(element).closest(".display").find("div.operation");
        	}

        // div.operation is contained within the div.display in the legacy event.php and group.php pages
        // i.e. when searching for a new participant to add in event.php and group.php, each search
        // result contains its own hidden operation form, rather than having one shared operation form.
        } else {
            return $(element).closest(".person_summary").find("div.operation");
        }
    };

    var resetFields = function (element) {
        if (typeof thisPersonRole !== "undefined") {
            thisPersonRole.find(".loading-roles").hide();

            if ($(thisPersonRole).is(".person_summary")) {
                $(thisPersonRole).find("div.operation").hide();
                $(thisPersonRole).find(".email_invitation_div").hide();
            } else {
                thisPersonRole.hide();
            }
        }

        if ($("body").is("#profiles, #groups, #events")) {
            $(thisPersonRole).find(".operation-control .messages").empty();
            $(thisPersonRole).find("select.event-op-id").show();
            // Only move the operations form from the current position if it is
            // shared with other person summaries (i.e. only if div.operations
            // is NOT unique to the current person).
            // Links with the .role_add or .role_invite class are unique to the current person.
            var isSharedOperationForm = $(thisPersonRole).find(".role_add, .role_invite").length === 0;
            isSharedOperationForm &= $(thisPersonRole).siblings(".person_roles").find(".role_add, .role_invite").length === 0;
            if (isSharedOperationForm) {
                $(thisPersonRole).appendTo($("div#operations-container"));
            }
        }

        var properties = ["relationship_id", "quota", "start_time", "end_time"];
        thisPersonRole = getThisPersonRole(element);
        for (var i = 0; i < properties.length; i++) {
            if (thisPersonRole.find("input." + properties[i]).length > 0) {
                thisPersonRole.find("input." + properties[i]).val("");
            }
        }
        thisPersonRole.find(".roles_to_add, select.group-op-id, select.event-op-id").val(0);

        // Reset the relationship status to the default 1 ("Active")
        thisPersonRole.find(".relationship_status").val(1);

        // Reset invitation placeholder values
        thisPersonRole.find(".personal_message").html("");
        $("#invitation_email").val("");
        thisPersonRole.find(".email_invitation_div").hide();
    };

    var personRoleAction = function (element) {
        thisPersonRole = getThisPersonRole(element);

        var properties = ["action", "source_entity", "source_entity_id", "target_entity", "target_entity_id", "relationship_id", "quota", "start_time", "end_time", "relationship_status"];

        const inputTypes = ['input', 'select', 'paper-input', 'cbar-textfield'];

        var params = {};
        for (var i = 0; i < properties.length; i++) {
            params[ properties[ i ] ] = "";

            for (const inputType of inputTypes) {
                const $input = thisPersonRole.find(`${inputType}.${properties[ i ]}`);
                if ($input.length > 0) {
                    params[ properties[ i ] ] = $input.val();
                    break;
                }
            }
        }

        // Load up the blueprint object up
        var person = getThisPerson(element);
        var searchWrapper = $(person).closest(".search_wrapper");
        var personRoleList = $(person).find("div.person_roles .existing_roles");
        var personRoleListItem = $(person).find("div.person_roles .existing_roles div").not(".ajax-blueprint");
        var blueprint = $(personRoleList).find("div.ajax-blueprint");

        var messageBox;
        if ($("body").is("#people")) {
            messageBox = $(thisPersonRole).find(".operation-control .messages");
        } else {
            messageBox = $(person).find(".operation-control .messages");
        }

        var ajaxActions = ["new", "delete", "add", "remove", "decline", "email-invitation", "list-roles-operation"];

        if(params.action == "add" || params.action == "email-invitation"){
        	messageBox.html($("<div class='warning message'>").text("Adding Role. Please wait."));
        }

        // Only run the AJAX call if the action requires it.
        if ($.inArray(params.action, ajaxActions) > -1) {
            $.ajax({
                url: "/ajax/entity-roles",
                type: "POST",
                data: params,
                success: function (data) {
                    messageBox.empty();

                    if (data.error === false) {
                        // Update the UI according to the action
                        switch (params.action) {
                            case "update":
                                updateUserRole($(this), personRoleListItem, data);
                                thisPersonRole.hide();
                                break;
                            case "add":
                                addNewRelationshipRow(blueprint, data, personRoleList, searchWrapper, messageBox, thisPersonRole);

                                $.each(data.message, function (index, element) {
                                    messageBox.append($("<div class='success message'>").html(element));
                                });
                                break;
                            case "remove":
                                if (personRoleListItem.length > 0) {
                                    resetFields(this);
                                    removeUserRole($(this), personRoleListItem, data);
                                }
                                break;

                            case "decline":

                                $.each(data.message, function (index, element) {
                                    messageBox.append($("<div class='success message'>").text(element));
                                });
                                $(".invitation-declined .people-list").append(person);
                                break;

                            case "email-invitation":
                                var invitation_params = data.data;
                                invitation_params.personal_message = thisPersonRole.find("[name=personal_message]").val();
                                invitation_params.invitation_email = thisPersonRole.find("[name=invitation_email]").val();

                                messageBox.html($("<div class='warning message'>").text("Sending invitation. Please wait."));

                                $.ajax({

                                    url: "/ajax/invitation",
                                    type: "POST",
                                    data: invitation_params,
                                    success: function (invitation_return) {
                                    	if (invitation_return.error === false) {

	                                        $.each(data.message, function (index, element) {
	                                            messageBox.html($("<div class='success message'>").text(element));
	                                        });

                                    	}else{
                                    		messageBox.html($("<div class='error message'>").text(invitation_return.message));
                                    	}

                                        resetFields(this);
                                    }

                                });



                        		break;
                            case "list-roles-operation":

                                $(thisPersonRole).find(".roles_to_add option").remove();
                                $(thisPersonRole).find(".roles_to_add paper-item").remove();

                                $(thisPersonRole).find(".roles_to_add")
                                    .append($("<paper-item>", { value : 0 }).text("- Available Roles -"));


                                $.each(data.data, function (index, element) {
                                    var optionParams = {
                                        "data-start-time": reformatDatePickerTime(element.start_time),
                                        "data-end-time": reformatDatePickerTime(element.end_time),
                                        "data-quota": element.role_id,
                                        "value": element.role_id
                                    };

                                    var materialInput = $(thisPersonRole).find("paper-dropdown-menu.roles_to_add");
                                    var isMaterial = !!materialInput.length;


                                    if(isMaterial){
                                        $(thisPersonRole).find("paper-dropdown-menu.roles_to_add paper-listbox")
                                        .append($("<paper-item>", optionParams).text(element.role_name));
                                    } else {
                                        $(thisPersonRole).find("select.roles_to_add")
                                            .append($("<option>", optionParams).text(element.role_name));
                                    }
                                });
                                $(thisPersonRole).find("select.roles_to_add").val($(thisPersonRole.context).attr("data-quota"));
                                break;
                        }

                        // Reload page-specific data after performing ajax action

                        // Event page
                        if ($("body").is("#events")) {
                            // Reload roster after changing a profile_relationship to an event
                            if ($.inArray(params.action, ["update", "add", "remove", "email-invitation"]) > -1) {
                                updateRoster(data);
                            }

                        // Person page
                        } else if ($("body").is("#profiles")) {
                            // Reload the person Events tab  after changing a profile_relationship to an event
                            if ($.inArray(params.action, ["update", "add", "remove", "email-invitation"]) > -1 && data.data.target_entity === "Event") {
                                // Move the roles form back into the oeprations-container before refreshing
                                // events list in Events tab of person page
                                if ($(".person_roles .event-op").closest(".event_summary").is("li")) {

                                    resetFields(this);
                                }
                                var futureFilter = $("div.search_wrapper .filter[value='future']").siblings(".submit");
                                futureFilter.trigger("click");

                            // Append the group to Groups tab after adding a profile_relationship to a group
                            } else if (params.action === "add" && data.data.target_entity === "Group") {
                                appendGroupListItem(
                                        data.data.source_entity_id,
                                        data.data.target_entity_id,
                                        data.data.target_entity_name,
                                        data.data.relationship,
                                        data.data.relationship_to);
                            }
                        }

                        // Group page or Event page
                        if ($("body").is("#groups, #events")) {
                            // After removing a profile_relationship, if the person has no more roles to the group or event,
                            // remove that person from the list on Teams tab or Participants tab, respectively.
                            checkUserRoles(params, person, thisPersonRole)
                        }

                    } else {
                        // Do something with the error
                        $.each(data.message, function (index, element) {
                            messageBox.append($("<div class='error message'>").text(element));
                        });
                    }
                    thisPersonRole.find(".loading-roles").fadeOut("slow");
                }
            });
        }else{
        	/*
        	if(params.action == "email-invitation"){

        		console.log("email-invitation");
        		console.log(params);
        		console.log(thisPersonRole.find("textarea[name=personal_message]"));
        		console.log(thisPersonRole.find("textarea[name=personal_message]").html());
        	}
        	thisPersonRole.find(".loading-roles").fadeOut("slow");
        	*/
        }

    };

    // When the group or event dropdown changes in people.php
    $personSummary.on("change", "select.group-op-id, select.event-op-id", function () {
        thisPersonRole.find(".loading-roles").fadeIn("slow");
        thisPersonRole.find("input.action").val("list-roles-operation");
        thisPersonRole.find("input.target_entity_id").val($(this).val());
        personRoleAction(this);

        var personal_message = "";
        if ($("body").is("#profiles") && $(this).is(".event-op-id")) {
        	if(!isNaN($(this).val()) && $(this).val() > 0){
        		personal_message = "Hi,\nPlease accept my invite to "+ $(".event-op-id option:selected").text()+".";
        	}else{
        		personal_message = "Hi,\nPlease accept my invite.";
        	}

        	thisPersonRole.find(".personal_message").val(personal_message);

    	}
    });

    var editEventForm = document.querySelector('iron-form.edit_event_form');
    // check if were in the edit page for events
    if(editEventForm) {

        // paper-spinner, cbar-address-autocomplete, error div getters
        var spinner = document.querySelector('paper-spinner.edit-spinner');
        var autocomplete = document.querySelector('cbar-address-autocomplete');
        var errorDisplay = document.querySelector('.error-display');

        /**
         * we add a listener here to mutate the data were sending before the ajax fires from iron-form
         * this is because iron form does not support nested paper-inputs and none of the autocomplete inputs
         * will be picked up here.
         *
         * along with this, dropdown values that are getting sent (permissions) values do not grab selected correctly
         */
        editEventForm.addEventListener('iron-form-presubmit', function(e){
            errorDisplay.innerHTML = '';
            var parentGroups = document.querySelectorAll("cbar-places-item.group-owner");
            var parentEvents = document.querySelectorAll("cbar-places-item.event-owner");

            if(parentGroups.length === 0){
                editEventForm.hidden = false;
                spinner.removeAttribute('active');
                errorDisplay.scrollIntoView(false);

                errorDisplay.innerHTML = "This event must belong to at least one group.";
                e.preventDefault();
                return;
            }

            var group_ids = [];
            parentGroups.forEach(function(parentElement){
                group_ids.push(parentElement.id);
            });

            var event_ids = [];
            parentEvents.forEach(function(parentElement){
                event_ids.push(parentElement.id);
            });

            var permissionsDropdown = document.querySelector("paper-dropdown-menu.permissions");
            // add in missing parameters
            editEventForm.request.body = Object.assign(editEventForm.request.body, {
                address_line_1: autocomplete.streetAddress,
                state: autocomplete.state,
                post_code: autocomplete.postcode,
                suburb: autocomplete.suburb,
                country: autocomplete.country,
                permissions: permissionsDropdown.contentElement.selected,
                group_ids,
                event_ids
            });
        });

        // on response we check if the operation was a success
        editEventForm.addEventListener('iron-form-response', function(e){
            var response = e.detail.response;

            if(response.operation === "success"){
                // function worked, redirect to event url
                window.location.pathname = response.response;
            } else {
                // show the form, hide the spinner and show some errors here
                editEventForm.hidden = false;
                spinner.removeAttribute('active');

                response.response.forEach(function(error){
                    // creating error displays
                    var errorToAdd = document.createElement('p');
                    errorToAdd.classList.add('error', 'message');
                    errorToAdd.innerHTML = error;
                    errorDisplay.appendChild(errorToAdd);
                });
            }
        });

        // check valid and then submit the form
        $('.edit-event-submit').on('tap', function (e) {
            if(editEventForm.validate() && autocomplete.validate()){
                spinner.active = true;
                editEventForm.hidden = true;
                editEventForm.submit();
            }
        });
    }


    if ($('body').is('#events')) {
        var paperTabs = document.querySelector('paper-tabs');
        var pages = document.querySelector('iron-pages');
        var favouriteButton = document.querySelector('#favourite-option');
        var eventId = $("input.target_entity_id").val();
        var eventEndTime = $("#event_end_time").val();
        var $photoFab = $('.group-add-photo');

        var searchDisplay = document.querySelectorAll('.display-search');

        if (searchDisplay.length > 0) {
            searchDisplay.forEach((display) => {
                display.addEventListener('tap', () => {
                    $(display).closest('.search_wrapper').find('.control.search').slideToggle();
                })
            });
        }

        if(favouriteButton){
            favouriteButton.addEventListener('tap', (e) => {
                handleFavouriteButton(e)
            });
        }

        var scrollElement = document.querySelector('cbar-entity-summary');

        if(scrollElement){
            requestAnimationFrame(() => {
                scrollElement.fixHeaderDiv();
                window.scrollTo(0, scrollElement.titleDiv.offsetTop - 72);
            });
        }

        $photoFab.on('tap', function(){
            $('input#file_upload').click();
        });

        scrollElement.addEventListener('back-button-clicked', function(){
            history.back();
        });

        if(paperTabs) {
            paperTabs.addEventListener('selected-changed', async function (e) {
                pages.selected = e.detail.value;
                window.scrollTo(0, scrollElement.titleDiv.offsetTop - 48);
                var hash = "#" + pages.selected;
                if(location.hash !== hash){
                    location.hash = hash;
                }

                window.scrollTo(0, paperTabs.offsetTop - 8);

                if (pages.selected === 'chat') {
                    const element = document.querySelector('cbar-chat-client');

                    if(element.roomId && element.accessToken && element.userId){
                        return;
                    }

                    let roomDetails = await getEventRoomEntity(eventId);

                    setRoomDetails(roomDetails);
                }
            });

            setSelectedTabFromHash(paperTabs, pages);

            $("cbar-chat-client").on('room-toggled', function(e){
                // set this here to keep context
                var element = this;
                element.loading = true;
                element.noRoom = false;

                $.ajax({
                    url: '/events/ajaxaddeventroom',
                    type: 'POST',
                    data: {
                        event_id: eventId,
                    },
                    success: function(e){
                        if(e.error){
                            console.error(e.message);
                            element.loading = false;
                        } else {
                            element.roomId = e.id;
                        }
                    }
                })
            });
        }

        var noteSubmit = document.querySelector('#note-submit');
        if(noteSubmit){
            noteSubmit.addEventListener('tap', function() {
                document.querySelector('#AddNoteForm').submit();
            });
        }


        $("#send-sms").on("tap", function(event){
            if(!paperTabs){
                return;
            }

            var actionDropdown = document.querySelector('.action_list');

            if(!actionDropdown){
                return;
            }

            paperTabs.select("users");
            actionDropdown.contentElement.select(" send-sms");
            actionDropdown.scrollIntoView();

        })

        if(moment(eventEndTime).isBefore(moment())){
            $.ajax({
                url: '/events/ajaxaddallowedevent',
                type: 'POST',
                data: {
                    event_id: eventId,
                },
                success: function(e){
                    if(e.error){
                        console.error(e.message);
                        element.loading = false;
                    } else {


                    }
                }
            })
        }
    }

    function handleRoleChanged($selectedOption, $this){
        var $existingRole = $personSummary.closest(".display").find(".event_role_edit");

        var existingStartTime = $existingRole.attr("data-start-time") || undefined,
            existingEndTime = $existingRole.attr("data-end-time") || undefined;

        var newStartTime = existingStartTime !== undefined ? existingStartTime : $selectedOption.attr("data-start-time");
        var newEndTime = existingEndTime !== undefined ? existingEndTime : $selectedOption.attr("data-end-time");

        thisPersonRole = getThisPersonRole($this);
        thisPersonRole.find("input.quota").val($selectedOption.attr("data-quota"));
        thisPersonRole.find(".start_time").val(newStartTime);
        thisPersonRole.find(".end_time").val(newEndTime);
    }

});

var roomCache = {};

async function getEventRoomEntity(eventId) {
    if (roomCache.hasOwnProperty(eventId)) {
        return roomCache[ eventId ];
    }

    return new Promise((res, rej) => {
        $.ajax({
            url: '/events/ajaxgeteventroom',
            type: 'POST',
            data: {
                event_id: eventId,
            },
            success: function (data) {
                roomCache[ eventId ] = data;

                return res(data);
            }
        });
    });
}

function setRoomDetails(details) {
    const element = document.querySelector('cbar-chat-client');
    if (!element) {
        return console.warn("No chat client element found");
    }

    element.roomId = details.room_id ? "!" + details.room_id : '';
    element.accessToken = details.matrix_access_token;
    element.userId = details.matrix_user_id;
}

function updateUserRole(element, personRoleListItem, data){
    if("data" in data){
        data = data.data;
    }

    $(personRoleListItem).each(function (index, element) {
        if (parseInt($(element).find("a").attr("data-relationship")) === parseInt(data.RelationshipID)) {
            // Update all the important info on the new group
            $(element).find("a").attr("data-id", data.relationship);
            $(element).find("a").attr("data-start-time", data.start_time);
            $(element).find("a").attr("data-relationship-title", data.relationship_to);
            $(element).find("a").attr("data-role-name", data.role_name)
            $(element).find("a").attr("data-end-time", data.end_time);
            $(element).find("a").attr("data-quota", data.Quota);
            $(element).find("a").attr("data-quota-start-time", data.quota_start_time);
            $(element).find("a").attr("data-quota-end-time", data.quota_end_time);
            $(element).find("a").html(data.label);

            // Set or unset the .pending_invite class to style role
            // with the CSS for .pending_invite.
            $(element).find("a").removeClass("role_declined role_invited");
            if (typeof data.status === "undefined" || parseInt(data.status) === 1 || typeof data.status_title === "undefined") {
                $(element).find("a").attr("data-status", 1);
            } else {
                $(element).find("a").attr("data-status", parseInt(data.status));
                $(element).find("a").addClass("role_" + data.status_title.toLowerCase());
            }
        }
    });
}

function removeUserRole(element, personRoleListItem, data){
    if (personRoleListItem.length > 0) {
        $(personRoleListItem).each(function (index, element) {
            if (parseInt($(this).find("a").attr("data-relationship")) === parseInt(data.data.relationship)) {
                if ($(person).find("div.person_roles .existing_roles").length === 1) {
                    $(this).closest(".person_summary").appendTo(".prospective-people-list");
                }
                $(this).remove();
            }
        });
    }
}

function checkUserRoles(params, person, thisPersonRole){
    var results = params.details.data;
    var adminRoles = ['Administrator', 'Owner'];
    var existingProfileLink = $('.administrators-div').find(`a[data-id=${results.source_entity_id}]`);

    if (params.action === "remove") {
        if ($(person).find("div.person_roles .existing_roles div").not(".ajax-blueprint").length === 0) {
            if(existingProfileLink.length > 0) {
                if (existingProfileLink[0].nextSibling) {
                    if ($('body').is('#events') && existingProfileLink[0].nextSibling.nextSibling) {
                        existingProfileLink[0].nextSibling.nextSibling.remove();
                    }

                    existingProfileLink[0].nextSibling.remove();
                }

                existingProfileLink.remove();
            }
            $(person).remove();
            addPerson.updateUserCount();
        } else {
            thisPersonRole.hide();

            var keepDisplay = false;
            adminRoles.forEach(function(relationship){
                if(person.find(`.existing_roles div:not(.ajax-blueprint) a[data-role-name="${relationship}"]`).length > 0){
                    keepDisplay = true;
                }
            })

            if (!keepDisplay && existingProfileLink.length > 0) {
                if (existingProfileLink[0].nextSibling) {
                    if ($('body').is('#events') && existingProfileLink[0].nextSibling.nextSibling) {
                        existingProfileLink[0].nextSibling.nextSibling.remove();
                    }

                    existingProfileLink[0].nextSibling.remove();
                }

                existingProfileLink.remove();
            }

            addPerson.updateUserCount();
        }
    }
}

function updateRoster(data){
    clearRosterChart();
    loadRosterChart(1175);

    // Update the stats on the event page if a quota with a target set has changed
    if (typeof data.data.quota_actual_fte !== 'undefined') {
        var quantities = 0;
        if( data.data.quota_target > 0 ) {
            var hours_per_quantity = data.data.quota_target_hrs/data.data.quota_target;
            quantities = data.data.quota_actual_fte/hours_per_quantity;
        }

        var $actualFte = $("[data-role-id=" + data.data.quota + "] .actual_fte");
        $actualFte.html(quantities + " (" + data.data.quota_actual_fte + " hrs)");
        $actualFte.attr('style', data.data.quota_role_style);

        $(".event-actual").html(data.data.quota_actual_fte);
    }
    if (typeof data.data.capped_capacity_display !== 'undefined'){
        var $cappedCapacity = $("[data-role-id=" + data.data.quota + "] .capped_capacity");

        $cappedCapacity.html(data.data.capped_capacity_display + "%");
        $cappedCapacity.attr('style', data.data.quota_role_style);

        if( data.data.event_capacity ) {
            $(".event-capacity").html(data.data.event_capacity);
        }
    }
}

function setupRoleUpdatedListener(rosterUpdateRoleDialog, person, thisPersonRole){
    if(roleUpdateListener !== null){
        return;
    }

    roleUpdateListener = rosterUpdateRoleDialog.addEventListener('role-updated', (e) => {
        updateUserRole($(selectedListenerElement), selectedListenerRoleList, e.detail.result);

        var data = e.detail.result.data;

        addPerson.checkForAdministrator({
            profile_object : {
                id: data.source_entity_id,
                firstName: data.first_name,
                lastName: data.last_name,
                mobile: data.mobile
            },
            add_role_result : [{
                quota_type: data.quota_name,
                relationship_id : data.RelationshipID,
            }],
            role_elements: person.find('.person_roles a'),
            redirect_url: `/profiles/view/${data.source_entity_id}`
        });

        if ($("body").is("#events")) {
            updateRoster(e.detail.result);
        }
        rosterUpdateRoleDialog.close();
    });
}

function setupRoleRemovedListener(rosterUpdateRoleDialog, person, thisPersonRole){
    if(roleRemoveListener !== null){
        return
    }

    roleRemoveListener = rosterUpdateRoleDialog.addEventListener('role-removed', (e) => {
        removeUserRole($(selectedListenerElement), selectedListenerRoleList, e.detail.result);

        if ($("body").is("#events")) {
            updateRoster(e.detail.result);
        }

        if ($("body").is("#groups, #events")) {
            // After removing a profile_relationship, if the person has no more roles to the group or event,
            // remove that person from the list on Teams tab or Participants tab, respectively.
            checkUserRoles({action: "remove", details: e.detail.result}, person, thisPersonRole);
        }

        rosterUpdateRoleDialog.close();
    });
}

function setupRoleAddedListener(thisPersonRole){
    if(roleAddListener !== null){
        return;
    }

    roleAddListener = addPerson.directAddDialog.addEventListener('role-added', (e) => {

        var person = getThisPerson($(selectedListenerElement));
        var personRoleList = $(person).find("div.person_roles .existing_roles");
        var searchWrapper = $(person).closest("div.person_summary");
        var blueprint = $(personRoleList).find("div.ajax-blueprint");
        var messageBox = $(person).find(".operation-control .messages");
        var details = e.detail;
        var data = details.data;

        addNewRelationshipRow(blueprint, details, personRoleList, searchWrapper, messageBox, thisPersonRole);

        // we need to check whether we can add the user to the administrators section at the top of the page
        addPerson.checkForAdministrator({
            profile_object : {
                id: data.source_entity_id,
                firstName: data.source_entity_name
            },
            add_role_result : [{
                quota_type: data.quota_name,
            }],
            redirect_url: `/profiles/view/${data.source_entity_id}`
        });

        $.each(e.detail.message, function (index, element) {
            messageBox.append($("<div class='success message'>").html(element));
        });

        if ($("body").is("#events")) {
            updateRoster(e.detail);
        }

        addPerson.directAddDialog.close();
    });
}

var getThisPerson = function (element) {
    return $(element).closest(".person_summary");
};

export function addNewRelationshipRow(blueprint, relationshipData, personRoleList, searchWrapper, messageBox, thisPersonRole) {
    if (blueprint.length > 0) {
        // Clone ajax-blueprint and add to the end of the list, removing
        // the class
        var newEventRole = blueprint.clone()
        .hide()
        .removeClass("ajax-blueprint");

        // Update all the important info on the new group
        // Inline text element is a <span> if read only,
        // or <a> otherwise.
        var roleInlineTextElement = newEventRole.find("a, span.role_read_only");
        roleInlineTextElement.attr("data-relationship", relationshipData.data.relationship);
        roleInlineTextElement.attr("data-start-time", relationshipData.data.start_time);
        roleInlineTextElement.attr("data-end-time", relationshipData.data.end_time);
        roleInlineTextElement.attr("data-quota", relationshipData.data.quota);
        roleInlineTextElement.attr("data-quota-start-time", relationshipData.data.quota_start_time);
        roleInlineTextElement.attr("data-quota-end-time", relationshipData.data.quota_end_time);
        roleInlineTextElement.attr("data-role-name", relationshipData.data.quota_name);

        if (roleInlineTextElement.is(".role_read_only")) {
            roleInlineTextElement.html(relationshipData.data.label);
        }else {
            roleInlineTextElement.html(relationshipData.data.label);
        }

        // Add the cloned object to the beginning of the list
        newEventRole.hide().appendTo(personRoleList).slideDown("fast", function () {
            // Set the edit box back to its original state
            if ($(searchWrapper).hasClass("add_participant")) {
                $(this).closest(".person_summary").appendTo(".people-list");
            }
            thisPersonRole.hide();
        });
        $(searchWrapper).find(".person_summary div.operation").hide();
    }

    var participantCount = parseInt($(searchWrapper).find(".person_control .total_count").text()) + 1;
    $(searchWrapper).find(".person_control .total_count").text(participantCount.toString());
}


