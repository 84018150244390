/**
 * Created by Dejan on 29/06/2016.
 */
// We'll typehint our expected constants here.
let ccPHPVars = {
    GALLERY_ADD_URL: undefined,
    GALLERY_IMAGE_URL: undefined,
    GALLERY_CROP_URL: undefined,
    FILE_DOWNLOAD_URL: undefined,
    PROFILES_URL: undefined,
    NOTES_URL: undefined,
    AJAX_ROUTE: undefined,
    SEARCH_EXPORT_ROUTE: undefined
};

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for( var i = 0; i < ca.length; i++ ) {
        var c = ca[ i ];
        while( c.charAt(0) == ' ' ) c = c.substring(1, c.length);
        if( c.indexOf(nameEQ) == 0 ) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

try {
    ccPHPVars = getCookie("cc_php_vars");
    ccPHPVars = JSON.parse(decodeURIComponent(ccPHPVars));
}catch (e) {
    console.warn("Failed to load PHP variables.");
}

export default ccPHPVars;
