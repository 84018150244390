var RecaptchaOptions = {
    theme: 'custom',
    custom_theme_widget: 'recaptcha_widget'
};

function validateEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
}

$(function () {
    $("#register_button").click(function () {

        var msg = "";
        //validate inputs
        if ($("#first_name").val().trim() == "") {
            msg += "Please enter first name\n";
        }
        if ($("#last_name").val().trim() == "") {
            msg += "Please enter last name\n";
        }
        if (!validateEmail($("#email").val())) {
            msg += "Please enter valid email address\n";
        }
        if ($("#postcode").val().trim() == "" || $("#postcode").val().length != 4 || isNaN($("#postcode").val()) == true) {
            msg += "Please enter valid postcode\n";
        }
        if ($("#recaptcha_response_field").val().trim() == "") {
            msg += "Please enter CAPTCHA word(s)\n";
        }

        if (msg == "") {
            $("#register_button").hide();
            $("#loading_gif").show();
            $("#response_success_div").hide();
            $("#response_fail_div").hide();

            var postData = {
                'first_name': $("#first_name").val(),
                'last_name': $("#last_name").val(),
                'email': $("#email").val(),
                'mobile': $("#mobile").val(),
                'postcode': $("#postcode").val(),
                'electorate': $("#electorate").val(),
                'availability': $("#availability").val(),
                'tasks[]': [],
                'recaptcha_response_field': $("#recaptcha_response_field").val(),
                'recaptcha_challenge_field': $("#recaptcha_challenge_field").val(),
                //first_name: $("#first_name").val(),
                //first_name: $("#first_name").val(),
            };

            $(".task_checkbox:checked").each(function () {
                postData['tasks[]'].push($(this).val());
            });

            console.log(postData);

            $.ajax({
                type: "POST",
                url: 'ajax/register',
                data: postData,
                dataType: "json",
                async: false,
                success: function (data) {
                    console.log(data);

                    if (data.result == "success") {

                        $("#register_button").hide();
                        $("#loading_gif").hide();
                        $("#response_success_div").show();
                        $("#response_fail_div").hide();
                        $("#response_success_blob").html(data.response_message);

                    } else {
                        $("#register_button").show();
                        $("#loading_gif").hide();
                        $("#response_success_div").hide();
                        $("#response_fail_div").show();
                        $("#response_fail_blob").html(data.response_message);
                    }

                }
            });
        } else {
            alert(msg);
        }
    });

});
