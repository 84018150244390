$(function () {
    // Trigger drop down filter events.php by event type on page load depending on location URL
    if ($("body").is("#events")) {
        if (window.location.search.match(/^\?filter=/)) {
            var eventsFilter = window.location.search.replace(/^\?filter=/, "");
            var availableFilters = ["prepoll", "election_day"];

            if ($.inArray(eventsFilter, availableFilters) > -1) {
                $("div.search select.filter_event_type").val(eventsFilter).trigger("change");
            }
        }
    }
});
