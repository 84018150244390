export function handleFavouriteButton(e){
    var favouriteDialog = document.querySelector('#favourite-dialog');
    var entitySummary = document.querySelector('cbar-entity-summary');
    var favouriteDialogTitle = favouriteDialog.querySelector('.favourite-dialog-title');
    var favouriteDialogMessage = favouriteDialog.querySelector('.favourite-dialog-message');
    var favouriteDialogActionsDiv = favouriteDialog.querySelector('.buttons');
    var actionDescription = e.target.itemTitle ? e.target.itemTitle : e.target.innerText.trim()


    favouriteDialogTitle.innerHTML = actionDescription;
    favouriteDialogMessage.innerHTML = actionDescription === "Favourite" ?
        `Adding '${entitySummary.title}' as a favourite will allow you to easily nagivate back if needed!` :
        `Removing ${entitySummary.title} as a favourite will remove it from your easily accessable favourites list`

    favouriteDialogActionsDiv.innerHTML = '';

    var confirmSpinner = document.createElement('paper-spinner');
    var cancelButton = document.createElement('paper-button');
    var confirmButton = document.createElement('paper-button');

    cancelButton.innerText = "Cancel";
    confirmButton.innerText = "Confirm";

    confirmButton.addEventListener('tap', () => {
        confirmSpinner.active = cancelButton.disabled = confirmButton.disabled = true;

        var {entity, id} = favouriteDialog.dataset;

        $.ajax({
            url: `/profiles/ajaxfavourite`,
            type: 'POST',
            data: {
                favourite:  actionDescription === "Favourite" ? 1 : 0,
                id,
                entity
            },
            success: function (response) {
                confirmSpinner.active = cancelButton.disabled = confirmButton.disabled = false;
                confirmButton.remove();

                favouriteDialogTitle.innerHTML = "Success!!"
                favouriteDialogMessage.innerHTML = `'${entitySummary.title}' has been ${response.action}, updates should now appear in your search lists accordingly!`
                cancelButton.innerText = "Confirm";

                if( e.target.itemTitle) {
                    e.target.itemTitle = actionDescription === "Favourite" ? "Un-Favourite" : "Favourite";
                } else {
                    e.target.innerText = actionDescription === "Favourite" ? "Un-Favourite" : "Favourite"
                }

                // updateMenuList(response,  actionDescription === "Favourite")
            },
            error: function (message) {
            }
        });
    });

    cancelButton.addEventListener('tap', () => {
        favouriteDialog.close();
    });

    favouriteDialogActionsDiv.append(confirmSpinner, cancelButton, confirmButton);

    favouriteDialog.show();
}

/**
 * This is a messy function due to the nature of how the select2 component works
 *
 * TODO: this is currently un-used due to the annoying nature of the select2 component not being able to handle new/replaced items
 * TODO: replace select2 with vaadin-based alternative due to request from andrew
 * @param response
 * @param favourite
 */
function updateMenuList(response, favourite){
    let targetElement = document.querySelector('cbar-select2');
    // get the child nodes, this is what the select 2 uses to calculate its populous
    let targetChildren = targetElement.children[0].cloneNode(true);
    // we need to check this here, the logic for moving an event off the favourites is different as we need to check for its parent group
    let movingEventToOthers = !favourite && response.entity === "event";
    let parentListItems = response.entity === "event" && movingEventToOthers ? document.querySelectorAll('cbar-entity-title') : [];

    // find where we are finding the group/event from
    let targetGroup = targetChildren.querySelector(`optgroup[label=${favourite ? 'Others' : 'Favourites'}]`);

    // check whether we need to check for further parent groups
    let parentListSelector = Array.from(parentListItems).map(item => `option[value="${item.url.split("id=")[1]}"]`);

    // check for target places to put the new select2 entry
    let targetSelector = `optgroup:not([label=${favourite ? 'Others' : 'Favourites'}]) ${parentListSelector.length > 0 ? parentListSelector.join(", ") : ''}`;
    let groupToMoveTo = targetChildren.querySelectorAll(targetSelector);

    // find the target to move
    let selector = `option[value="${response.entity === "event" ? "event_id" : ""}${response.entity_id}"]`;
    let targetToMove = targetGroup.querySelector(selector);

    if(targetToMove) {
        // for each valid spot, we need to add a CLONE (remove function will remove initial checked node) to the location
        groupToMoveTo.forEach((location) => {
            location.append(targetToMove.cloneNode(true));
        })
    } else if(!movingEventToOthers) {
        // if there is no valid option currently, we append it to the select where we need to
        let newOption = document.createElement("option");
        newOption.value = `${response.entity === "event" ? "event_id" : ""}${response.entity_id}`;
        newOption.innerText = response.title;

        groupToMoveTo.forEach(location => {
            location.append(newOption)
        })
    }

    if(targetToMove) {
        // delete the old positioning
        targetToMove.remove();
    }

    // reset the value
    let targetElementVal = targetElement.value;

    targetElement.innerHTML = targetElement.value = "";
    
    targetElement.select2Ele.empty().trigger("change").trigger('change.select2');

    setTimeout(() => {
        // we need to ensure were only copying the intended select 2 list over
        Array.from(targetChildren.children).forEach(list => {
            targetElement.append(list);
        })

        targetElement.value = targetElementVal;
    }, 1000)
}
