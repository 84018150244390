$("yo-paper-cropper").on('cropper-ready', function(){
    var $spinner = $('.spinner')[0];
    $spinner.removeAttribute('active');

    var $cropButton = $('.crop_button')[0];
    $cropButton.hidden = false;
})

customElements.whenDefined('yo-paper-cropper').then(function(){
  var $cropper = $("yo-paper-cropper");

  var $cropperWidth = $("#newWidth").val();
  var $cropperHeight = $("#newHeight").val();

  // If we don't have a cropper, let's break out of this script.
  if ($cropper.length === 0) {
    return;
  }

  $cropper[0].options = {
    dragMode: 'move',
    aspectRatio: $cropperWidth / $cropperHeight,
    autoCropArea: 1.0,
    viewMode: 0
  };
});

$('.crop_button').on('click', function(){
    var $cropper = $("yo-paper-cropper");
        $cropper = $cropper[0];

    var image_file_name = $("#image_file_name").val();

    var $spinner = $('.spinner')[0];

    this.hidden = true;
    $spinner.setAttribute('active', 'true');

    var cropperData = $cropper._cropper.getData();

    var entityType = $cropper.dataset.entityType;

    var $cropForm = $('#cropForm');
    var $id = $("#group_or_event_id");
    var galleryURL = "/" + entityType + "s/view/" + $id.val() + "#content-group-photos";

    $.ajax({
      type: $cropForm.attr('method'),
      url: $cropForm.attr('action'),
      data: {
        'x': cropperData.x,
        'y': cropperData.y,
        'scale': cropperData.scaleX,
        'newWidth': cropperData.width,
        'newHeight': cropperData.height,
        'image_file_name': image_file_name
      },
      success: function(data) {
          $spinner.removeAttribute('active');
        if( data.error ) {
          alert("Error occurred while trying to crop image: " + data.error);
        }else {
          //window.opener.refreshImages();
          window.location.href = galleryURL;
        }
      }
    });
});
