$(function() {

    if (document.querySelector('body#page')) {
        // the inputs and scope elements on the page
        let pageDescriptionInput = document.querySelector('input#page_description');
        let formDescriptionInput = document.querySelector('input#form_description');
        let submissionThanksMessage = document.querySelector('input#thanks_message');
        let errorDiv = document.querySelector('#form-error');
        let submitButton = document.querySelector('#submit-btn');
        let spinner = document.querySelector('paper-spinner');

        let ironForm = document.querySelector('iron-form');

        let scope = document.querySelector('dom-bind');

        let logoInput = document.querySelector('#logo_url');
        let bannerInput = document.querySelector('#background');

        let button = document.querySelector('#submit-btn');

        if (ironForm) {
            ironForm.addEventListener('iron-form-presubmit',  function(e) {
                if(submitButton){
                    submitButton.hidden = true;
                }

                if(spinner){
                    spinner.hidden = false;
                }

                if(errorDiv){
                    errorDiv.innerHTML = "";
                }

                if(!checkForm()){
                    e.preventDefault();

                    if(submitButton){
                        submitButton.hidden = false;
                    }

                    if(spinner){
                        spinner.hidden = true;
                    }

                    return;
                }

                this.request.body = Object.assign(ironForm.request.body, {
                    unconfirmed_relationship_name: JSON.stringify(ironForm.request.body.unconfirmed_relationship_name),
                    return_json: true
                });

                quills.forEach(quill => {
                    this.request.body[quill.container.dataset.property] = quill.container.firstChild.innerHTML
                });
            });

            ironForm.addEventListener('iron-form-response', function(e){
                var response = e.detail.response;

                if(response.operation === 'success'){
                    // function worked, redirect to event url
                    window.location.pathname = response.url;
                } else {
                    if(response.error){
                        if(errorDiv){
                            errorDiv.innerHTML = response.error;
                            errorDiv.scrollIntoView();
                            window.scrollBy(0, -100);
                        }
                    }
                }

                if(submitButton){
                    submitButton.hidden = false;
                }

                if(spinner){
                    spinner.hidden = true;
                }
            })
        }

        // quill element names
        const quillSelectors = [
            '#leftquill-element',
            '#rightquill-element',
            '#thanksquill-element'
        ];

        // input elements
        const inputElements = [
            pageDescriptionInput,
            formDescriptionInput,
            submissionThanksMessage
        ];

        let quills = [];
        quillSelectors.forEach((selector, i) => {
            if (document.querySelector(selector)) {
                quills[i] = new Quill(selector, {
                    // theme that has top bar
                    theme: 'snow',
                    placeholder: "Type Here.."
                });

                quills[i].container.dataset.property = inputElements[i].name;

                if (inputElements[i].value) {
                    quills[i].container.firstChild.innerHTML = inputElements[i].value;
                }
            }
        });

        if (quills.length === 0 && !scope) {
            // No quills to process on page.
            return;
        }

        /**
         * this event fires when the form is submitted, checks if the form is valid and if so, submits the form
         */
        function checkForm() {
            // valid by default
            var isValid = true;
            // gets all textboxes on the page
            var textBoxes = document.querySelectorAll('input.padded');

            // check if the quill inputs have text in them
            if (quillsValid() === false) {
                isValid = false;
            }

            // for each of the textboxes and check if they have a value, if not, set the border to red and the form becomes invalid
            // if none of the inputs are invalid then the form is valid and will submit
            textBoxes.forEach(input => {
                setValidInput(input, input.value !== "", "Required");

                if(input.value === ""){
                    isValid = false;
                }
            });

            [logoInput, bannerInput].forEach((element) => {
                setValidInput(element, isValidHttpUrl(element.value), 'Invalid Url');

                if(!isValidHttpUrl(element.value)){
                    isValid = false;
                }
            })

            // we prevent the form from submitting if the form is invalid
            if (isValid === false) {
                var inputToScroll = document.querySelector('.error-div');
                window.scrollTo({top : inputToScroll.getBoundingClientRect().top + window.scrollY - 132});
                window.event.preventDefault();
            }

            return isValid;
        }

        /**
         * checking if the quill elements have text in them, if not then we return false, if so they are valid and
         * we return true
         * @return {boolean}
         */
        function quillsValid() {
            var quillsValid = true;

            quills.forEach((element, i) => {
                var html = element.container.firstChild.innerHTML;

                if (html === "<p><br></p>") {
                    element.container.style.border = "1px solid red";
                    quillsValid = false;
                } else {
                    element.container.style.border = "";
                    // set the hidden inputs value to the quills html
                    inputElements[i].value = html;
                }

            });

            return quillsValid;
        }

        if (scope) {
            customElements.whenDefined('cbar-public-signup').then(() => {
                var signup = document.querySelector('cbar-public-signup');
                if (!signup) {
                    return;
                }
                signup.addEventListener('form-response', (e) => {
                    handleFormResponse(e);
                });


                /**
                 * when we get a response from the iron form element, fire this in order to show a thank you dialog if the form went through
                 * @param e
                 */
                function handleFormResponse(e) {
                    var dialog = document.querySelector('md-dialog');
                    var formElement = document.querySelector('cbar-public-signup');
                    var response = e.detail.event.detail.response;
                    if (dialog && response.result === "success") {
                        dialog.show();
                        formElement.clear();
                    }
                }

                /**
                 * when dialog button pressed, open this
                 */
                scope.dismissDialog = function () {
                    var dialog = document.querySelector('md-dialog');
                    dialog.close();
                };

            });
        }
    }
});

function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

function setValidInput(element, valid, error){
    if(valid){
        element.style.border = "";
        element.setCustomValidity('');
        if(element.parentElement.children.length > 1){
            element.parentElement.removeChild(element.parentElement.querySelector('.error-div'));
        }
    } else {
        var errorDiv = document.createElement('div');
        errorDiv.innerText = error;
        errorDiv.classList.add('error-div')
        errorDiv.style.color = "red";

        element.style.border = "1px solid red";

        if(element.parentElement.children.length === 1) {
            element.parentElement.appendChild(errorDiv);
        }
    }
}


