$(function () {
    'use strict';

    // Only proceed on volunteer page.
    if (!$('body').is('#volunteer')) {
        return;
    }

    // Close Dialog.
    var closeDialog = function () {
        dialog.dialog('close');
    };

    // Reset Dialog form values.
    var resetDialog = function () {
        dialog.find('.profile_id').val('');
        dialog.find('.event_id').val('');
        dialog.find('.quota_id').val('');
        dialog.find('.start_time').val('');
        dialog.find('.end_time').val('');
        dialog.dialog('option', 'title', '');
    };

    // AJAX call to add logged in user as Volunteer to selected event.
    var volunteerToEvent = function() {
        var msgBox = $('div.info.message');
        var loadingSpinner = $('div.loading');
        var params = {
            action: 'add',
            source_entity: 'Profile',
            source_entity_id: dialog.find('.profile_id').val(),
            target_entity: 'Event',
            target_entity_id: dialog.find('.event_id').val(),
            quota: dialog.find('.quota_id').val(),
            start_time: dialog.find('.start_time').val(),
            end_time: dialog.find('.end_time').val()
        };

        loadingSpinner.fadeIn('slow');
        msgBox.html('').hide();
        dialog.dialog('close');
        window.scrollTo(0, 0);

        $.ajax({
            url: '/ajax/entity-roles',
            type: 'POST',
            data: params,
            success: function (data) {
                msgBox.html(data.message).show();
                loadingSpinner.fadeOut('slow');
            }
        });
    };

    // Initialise the volunter-dialog HTML as a jQuery UI Dialog object.
    var dialog = $('#volunteer-dialog').dialog({
        autoOpen: false,
        modal: true,
        width: 325,
        buttons: {
            'Sign me up!': volunteerToEvent,
            'Cancel': closeDialog
        },
        close: resetDialog
    });

    // Listener for "Volunteer" button clicks.
    // Opens the Dialog window and fills in the form values.
    $('.events-list').on('click', '.event_summary .actions button', function() {
        var form = {
            profile_id: $(this).attr('data-profile-id'),
            event_id: $(this).attr('data-event-id'),
            event_name: $(this).parent().siblings('.event_name').text(),
            quota_id: $(this).attr('data-volunteer-quota-id'),
            start_time: $(this).attr('data-start-time'),
            end_time: $(this).attr('data-end-time')
        };

        dialog.find('.profile_id').val(form.profile_id);
        dialog.find('.event_id').val(form.event_id);
        dialog.find('.quota_id').val(form.quota_id);
        dialog.find('.start_time').val(form.start_time);
        dialog.find('.end_time').val(form.end_time);

        dialog.dialog('option', 'title', form.event_name).dialog('open');
    });
});
