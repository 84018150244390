$(function () {
    'use strict';

    var identityTable = $('.identity-table');
    var sortableTable = $('.sortable-table');
    var sortableTableQueue = $('.sortable-table-queue');

    //Make the tables sortable
    if (identityTable.length > 0) {
        identityTable.dataTable({
            'jQueryUI': true,
            'bPaginate': false,
            'bFilter': false,
            'bInfo': false,
            'bSort': false
        });
    }

    if (sortableTable.length > 0) {
        sortableTable.dataTable({
            'jQueryUI': true,
            'aaSorting': [[0, 'desc']],
            'bPaginate': false,
            'bFilter': false,
            'bInfo': false
        });
    }

    if (sortableTableQueue.length > 0) {
        sortableTableQueue.dataTable({
            'jQueryUI': true,
            'aaSorting': [[0, 'desc']],
            'bPaginate': false,
            'bFilter': false,
            'bInfo': false
        });
    }


});
