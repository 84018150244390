import ccPHPVars from './phpconstants';
import {getUrlForRoute} from "./route";

(() => {
    var resultActionSelector = ".results .result_actions, .compose_email .submit";
    var entitySelector = ".person_summary, .group_summary, .event_summary, .advanced_search_group";
    var selectedArray = [];
    var searchExportURL = getUrlForRoute(ccPHPVars.SEARCH_EXPORT_ROUTE);

    $(entitySelector).find(".selectbox input[type=checkbox]:checked").each(function (index, element) {
        //addToSearchResult(this);
    });

    $(entitySelector).on("click", ".selectbox input[type=checkbox]", function (event) {
        var selectedRadio = $(resultActionSelector).find(".options input[type=radio]").filter("[value=selected]");
        if ($(selectedRadio).is(":checked") === false) {
            $(selectedRadio).prop("checked", true);
        }
        addToSearchResult(this);
    });

    function addToSearchResult(element) {
        var entityID = $(element).closest(entitySelector).attr("data-id");
        //console.log(entityID);

        if ($(element).is(":checked") === true && $.inArray(entityID, selectedArray) === -1) {
            selectedArray.push(entityID);
        } else if ($(element).is(":checked") === false) {
            for (var i = selectedArray.length - 1; i >= 0; i--) {
                if (selectedArray[i] === entityID) {
                    selectedArray.splice(i, 1);
                }
            }
        }

        var selectedArrayJSON = JSON.stringify(selectedArray);
        $(resultActionSelector)
                .find(".selected").val(selectedArrayJSON)
                .end()
                .find("label.options_selected span").text(selectedArray.length);
    }

    $(resultActionSelector).on("change", ".options input[type=radio]", function (event) {
        // If the "all" option is selected, simply update the selector text with the number of rows of all results.
        if ($(this).val() === "all") {

            $(entitySelector).find(".selectbox input[type=checkbox]").each(function (index, element) {
                $(element).prop("checked", true);
            });

            var count_all = $('input[name="result_count"]').val();
            $(resultActionSelector)
                .find(".selected").val("all")
                .end()
                .find("label.options_selected span").text(count_all);

        // Otherwise, update the selected entity IDs that will be POSTed.
        } else if ($(this).val() === "all_on_page") {
            $(entitySelector).find(".selectbox input[type=checkbox]").each(function (index, element) {
                $(element).prop("checked", true);
                addToSearchResult(this);
            });
        } else if ($(this).val() === "none") {
            $(entitySelector).find(".selectbox input[type=checkbox]").each(function (index, element) {
                $(element).prop("checked", false);
                addToSearchResult(this);
            });
        }
    });

    if ($('.site_urls').length) {
        let select = document.querySelector("#selected_group");
        let oldName = select.options[select.selectedIndex].dataset.group;
        $('.site_urls').on('change', function () {
            const newName = select.options[select.selectedIndex].dataset.group;
            //now in message search for old group name and replace with newly selected one
            var message = document.querySelector("#invitation_message").value;
            var replace = oldName;
            var re = new RegExp(replace, "g");
            document.querySelector("#invitation_message").value = message.replace(re, newName);
            oldName = newName;
        });
    }

    $('.action_list').on("selected-item-changed", function (event) {
        if(!event.detail.value){
            return;
        }

        var selectedElement = event.detail.value;
        var thisValue = selectedElement.getAttribute('value').trim();

        var formAction = "";
        var messageMode = "";
        var submitButton = "";

        switch (thisValue) {
            case 'send-email':
                formAction = "/messages/send";
                messageMode = "email";
                submitButton = "Next";
                break;
            case 'send-sms':
                formAction = "/messages/send";
                messageMode = "sms";
                submitButton = "Next";
                break;
            case 'export-csv':
                formAction = searchExportURL;
                messageMode = "";
                submitButton = "Export";
                break;
            case 'send-invitation':
                formAction = "/messages/send";
                messageMode = "invitation";
                submitButton = "Next";
        }

        if(!thisValue){
            $(resultActionSelector).find(".more_options").hide();
            $(resultActionSelector).find(".submit").hide();
        } else {
            $(resultActionSelector).find(".more_options").show()
            .find("> div").hide()
            .end()
            .find("." + thisValue.trim()).show();
            $(resultActionSelector)
            .find(".submit").val(submitButton).show().end()
            .find("#message_mode").val(messageMode).end()
            .find("form#result_actions_form").attr("action", formAction).end();
        }
    });

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    $(resultActionSelector).on("click", ".submit paper-button#result-actions-submit", function (event) {

        var thisValue = $(resultActionSelector).find(".action_list")[0].selectedItem.getAttribute('value');

        if (selectedArray.length === 0 && $(".results input[name='profiles']").val() !== "all") {

            /*
                There is a little but hack here, the accept data for 'profiles' input are
                    - string "all"
                    - string that looks like array, e.g. [132, 123456] but this is actually array in JSON format. So we can use JSON format check for this
             */
            if ( IsJsonString($(".results input[name='profiles']").val()) === false){
                event.preventDefault();
                $(resultActionSelector)
                    .find(".feedback").show()
                    .find("p").text("Please select one or more results.");
                return;
            }
        }

        const messageBody = document.querySelector("#message_body");
        const quill = messageBody.__quill;

        if (thisValue === 'send-email') {
            if ($("#message_subject").val().length === 0) {
                event.preventDefault();
                $(resultActionSelector)
                    .find(".feedback").show()
                    .find("p").text("Email subject is empty.");

                return;
            }

            if (quill.getLength() === 1) {
                // Quill length is 1 when it's empty.
                event.preventDefault();
                $(resultActionSelector)
                    .find(".feedback").show()
                    .find("p").text("Email body is empty.");

                return;
            }
        }else if (thisValue === "send-sms" && $("#sms_message").val().length === 0) {
            event.preventDefault();
            $(resultActionSelector)
                .find(".feedback").show()
                .find("p").text("SMS message is empty.");

            return;
        } else if (thisValue === "send-invitation" && $("#invitation_message").val().length === 0) {
            event.preventDefault();
            $(resultActionSelector)
                .find(".feedback").show()
                .find("p").text("Invitation message is empty.");

            return;
        }

        // Set the hidden message body field to the quill value.
        const hiddenMessageBody = document.querySelector('#message_body_email');
        if (!hiddenMessageBody) {
            event.preventDefault();

            return console.error("Hidden message body input not found!");
        }
        hiddenMessageBody.value = quill.root.innerHTML;

        $(resultActionSelector)
            .find(".feedback").hide()
            .find("p").empty();

        document.querySelector('iron-form#result_actions_form').submit();
    });


    // Default selections on the form
    $(resultActionSelector).find("label.options_selected span").text(selectedArray.length);

    // Select all results by default
    //$(resultActionSelector).find("#option_all_on_page").prop("checked", true).trigger("change");
    // Use PHP to set the checkboxes to checked instead of JS
    $(resultActionSelector).find("#option_all_on_page").trigger("change");

    // On page load of compose email, load profile ids into hidden array
    if ($("body").is("#messages")) {
        $(entitySelector).find(".selectbox input[type=checkbox]").each(function (index, element) {
            $(element).prop("checked", true);
            addToSearchResult(this);
        });
    }

    // Update the page number of search results when pressing a pagination button.
    $('.search_advanced_page').on('click', '.page_button', function(event) {
        $(this).closest('.search_advanced_page')
            .find('input[name="page"]')
            .val($(this).val());
    });
})();
