$(async function(){
    if($('body').is('#help')){
        var chatClientElement = document.querySelector('cbar-chat-client');
        var {accessToken, userId, roomAdmins, roomName} = chatClientElement.dataset;
        var initialSyncComplete = false;
        
        var matrixClient = await matrixcs.createClient({
            baseUrl: chatClientElement.matrixUrl,
            accessToken,
            userId,
        });


        // THIS IS REQUIRED, the external initalization of the matrix client allows us to manage the new room creation
        // if its required for a new user much quicker and smoother, we also use this to find the existing room

        chatClientElement.existingClient = matrixClient;

        await matrixClient.startClient({initialSyncLimit: 30});

        matrixClient.on('sync', () => {
            if(initialSyncComplete){
                return;
            }

            initialSyncComplete = true
            var rooms = matrixClient.getRooms();
            var discoveredRooms = rooms.filter(room => room.name === roomName);

            chatClientElement.accessToken = accessToken;
            chatClientElement.userId = userId;

            if(discoveredRooms.length > 0){
                requestAnimationFrame(() => {
                    chatClientElement.roomId = discoveredRooms[0].roomId;
                });
            }
        });

        chatClientElement.addEventListener('room-toggled', async (e) => {
            var usersToInvite = JSON.parse(roomAdmins);
            chatClientElement.loading = true;

            let newRoom = await matrixClient.createRoom({
                name: roomName,
                topic: `Organizer support chat for ${userId}`,
                invite: usersToInvite.filter(user => user !== userId),
            });
            chatClientElement.roomId = newRoom.room_id
        });
    }
})