import LocalStorageAjax from './localstorage-caching.module';
import {openDialog} from './cbar-add-person.module';
import {header} from './header.module';
import './profile.module';
import './search_advanced.module';
import {openAddGroupDialog} from "./group";

window.LocalStorageAjax = LocalStorageAjax;
window.openAddPersonDialog = openDialog;

window.openAddGroupDialog = openAddGroupDialog;

// Setup listeners for our header
header.setupListeners();
