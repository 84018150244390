import LocalStorageAjax from './localstorage-caching.module';
import {reloadParticipants} from "./roster";
import "jquery-ui/ui/widgets/tooltip";
import {startMakingRequests} from "../../typescript/chunked-ajax-cache";

let contextEntityIDMap = {};
let confirmedNew = false;
let updatingExisting = false;

class AddPerson {
    static get DEFAULT_IMAGE_URI() {
        return "https://login.authentickated.com/public/image/default.png";
    }

    static get ENTITY_TYPE_EVENT() {
        return 'Event';
    }

    static get ENTITY_TYPE_GROUP() {
        return 'Group';
    }

    static get RELATIONSHIP_ADMINISTRATOR(){
        return 'Administrator'
    }

    /**
     * Set up the add person dialog by retrieving the event and group dropdowns
     * and loading + setting all profiles that the logged in user has access to
     * as the autocomplete source on the dialog
     */
    constructor() {
        // Mark as loading until roles are loaded
        if (!this.dialog || !this.profilesDialog) {
            return;
        }

        this.dialog.loading = true;

        const errors = [];

        this._groupsPromise = LocalStorageAjax.get('groupDropdowns', {
            url: "/groups/getgroupdropdowns",
            type: "GET",
            dataType: 'json'
        }).then(groupsResult => {
            return this._formatResults(groupsResult, this.constructor.ENTITY_TYPE_GROUP)
        }).catch(e => errors.push(`Error trying to retrieve group dropdowns: ${e}`));

        this._eventsPromise = LocalStorageAjax.get('eventDropdowns', {
            url: "/events/geteventdropdowns",
            type: "GET",
            dataType: 'json',
        }).then(eventsResult => {
            return this._formatResults(eventsResult, this.constructor.ENTITY_TYPE_EVENT);
        }).catch(e => errors.push(`Error trying to retrieve event dropdowns: ${e}`));

        this.loadProfiles();

        customElements.whenDefined('cbar-add-person').then(() => {
            Promise.all([this._groupsPromise, this._eventsPromise]).then(([groups, events]) => {
                if (errors.length > 0) {
                    return errors.map(e => console.error(e));
                }

                this.dialog.entities = {
                    [this.constructor.ENTITY_TYPE_GROUP]: groups,
                    [this.constructor.ENTITY_TYPE_EVENT]: events,
                };

                this.dialog.loading = false;

                // Build up a map of entityID's so we can
                // quickly set the context if needed.
                this._buildContextEntityIDMap();
            });

            // Setup our dialog listeners
            this._setupListeners();
        });
    }

    loadProfiles() {
        startMakingRequests({
            url: '/profiles/getprofileaccess',
            cacheKey: 'ccProfiles',
            limit: 5000,
            expirySeconds: 10 * 60,
            onResponse: response => {
                const profiles = response.data;

                profiles.map(profile => profile.displayName = `${profile.firstName} ${profile.lastName}`);

                this.dialog.autocompleteSource = [...(this.dialog.autocompleteSource ?? []), ...profiles];
            }
        });
    }

    get dialog() {
        if (!this.__dialog) {
            this.__dialog = document.querySelector("#dialog-add-person");
        }

        return this.__dialog;
    }

    get profilesDialog(){
        if(!this.__profilesDialog){
            this.__profilesDialog = document.querySelector('#duplicate-profile-dialog')
        }
        return this.__profilesDialog;
    }

    get administratorsDiv(){
        if(!this.__administratorsDiv){
            this.__administratorsDiv = document.querySelector('.administrators-div');
        }
        return this.__administratorsDiv;
    }

    get contextEntityType() {
        return this.__contextEntityType;
    }

    set contextEntityType(entityType) {
        this.__contextEntityType = entityType;
    }

    /**
     * Retrieves the direct add dialog
     *
     * @returns {Element | *}
     */
    get directAddDialog() {
        if (!this.__directAddDialog) {
            // Dialog has not previously been retrieved.
            this.__directAddDialog = document.querySelector('#cc-add-to-entity');
        }

        return this.__directAddDialog;
    }

    get updateRoleDialog(){
        if (!this._updateRoleDialog) {
            // Dialog has not previously been retrieved.
            this._updateRoleDialog = document.querySelector('cc-update-role');
        }

        return this._updateRoleDialog;
    }

    addDirectDialogAddListener(){
        if(!this.__directAddDialog){
            this.__directAddDialog = this.directAddDialog;
        }

        this.__directAddDialog.addEventListener('role-added', (e) => {
            // Close dialog when a role is assigned and refresh page.
            if(!e.defaultPrevented) {
                this.__directAddDialog.close();
                window.location.reload();
            }
        });
    }

    /**
     * Returns an array of Campaign Central entities for the given entity type
     * @param entityType
     * @returns {*}
     */
    getEntitiesForType(entityType) {
        if (entityType === this.constructor.ENTITY_TYPE_EVENT) {
            return this._eventsPromise;
        }

        if (entityType === this.constructor.ENTITY_TYPE_GROUP) {
            return this._groupsPromise;
        }
    }

    /**
     * Opens the direct add dialog for directly adding an existing user
     * to a Group/Event.
     *
     * @param {{String}} entityType The entity type to add the user to (Group
     *     or Event)
     * @param {{Number}} profileID
     * @param profileTitle
     * @param addListener
     * @param entityId
     */
    openDirectAddDialog(entityType, profileID , profileTitle = null, addListener = false, entityId = null) {
        this.getEntitiesForType(entityType).then(entities => {
            customElements.whenDefined('paper-dialog').then(async () => {

                let missingEvent = false;

                if (entityId && entityType === "Event") {
                    missingEvent = !entities.find(entity => entity.id === entityId);

                    if (missingEvent) {
                        this.directAddDialog.open();
                        this.directAddDialog.loading = true;
                        let result = await this._grabPastEvent(entityId);

                        if(result.event) {
                            this.handlePastEvent(result);
                            this.directAddDialog.entities = entities;
                        }

                        this.directAddDialog.loading = false;
                    }
                }

                if(!missingEvent){
                    this.directAddDialog.entities = entities;
                }

                this.directAddDialog.entityType = entityType;
                this.directAddDialog.profileId = profileID;
                this.directAddDialog.profileTitle = profileTitle;

                this.directAddDialog.open();

                if (addListener) {
                    this.addDirectDialogAddListener();
                }
            });
        });
    }

    /**
     * Set the context of the dialog to either the passed event ID or group ID
     * @param {Number} eventId
     * @param {Number} groupId
     * @returns {boolean}
     */
    async setContext(eventId, groupId) {
        let dialog = this.dialog;

        if (!dialog) {
            return;
        }

        dialog.context = null;

        this.contextEntityType = undefined;

        eventId = parseInt(eventId);
        groupId = parseInt(groupId);

        if (!eventId && !groupId) {
            // Not a valid context.
            return false;
        }

        let contextId = eventId || groupId;
        this.contextEntityType = eventId > 0 ? 'Event' : 'Group';

        let context = contextEntityIDMap[this._getMapKeyForEntity(this.contextEntityType, contextId)];

        if (context) {
            dialog.context = context;
            return;
        }

        if (eventId) {
            this.dialog.loading = true;
            let newEventResult = await this.grabPastEvent(eventId);

            if(newEventResult.error){
                console.error(newEventResult.error);
                this.endLoading();
                return;
            }

            if(newEventResult.event) {
                // format the event here
                this.handlePastEvent(newEventResult, true);
                this.endLoading();
            } else {
                this.endLoading()
                this.dialog.context = null;
            }
        }
    }

    /**
     * ensures the fetched event gets formatted correctly and then added to the context list
     * @param {{event}} result
     * @param setContext
     */
    handlePastEvent(result, setContext = false){
        let formattedEvents = this._formatResults({error: false, results: [result.event]}, this.constructor.ENTITY_TYPE_EVENT);
        let event = this.__pastEventCached = formattedEvents.length > 0 ? formattedEvents[0] : null

        // add the event for further use
        this.addNewEntityToMap('Event', event);

        if(setContext){
            this.setContext(event.id, null);
        }
    }

    /**
     * used for past events, adds them to the page dialog and then re-structures the entity map
     * @param {string|number} type
     * @param {*} object
     */
    addNewEntityToMap(type, object){
        if(!this.dialog){
            return;
        }

        this.dialog.entities[type].push(object);
        this._buildContextEntityIDMap();
    }

    endLoading(){
        this.dialog.loading = false;
        this.directAddDialog.loading = false
    }

    /**
     * Open the dialog.
     *
     * @param {Object} options Used for setting the context. Accepts either
     *     eventId or groupId.
     */
    openDialog(options) {
        options = options || {};

        if (this.dialog) {
            this.setContext(options.eventId, options.groupId);
            this.dialog.open();
        }
    }

    /**
     * Set up the ƒorm submission listener on the dialog
     * @private
     */
    _setupListeners() {
        this.dialog.addEventListener('submit', (e) => {
            if(!this.dialog.validate()){
                return;
            }

            // Submit handler for add person dialog
            let {detail} = e;

            let quotaIds = [];
            let eventRoleStartTime, eventRoleEndTime;

            // Add quotaIds to our array and get the event start/end time if it
            // exists
            detail.entityRelationships.forEach(entityRelationship => {
                quotaIds.push(entityRelationship.selectedRelationship.id);

                if (entityRelationship.entityType === this.constructor.ENTITY_TYPE_EVENT || this.contextEntityType === this.constructor.ENTITY_TYPE_EVENT) {
                    // Set start/end time for events.
                    eventRoleStartTime = moment(entityRelationship.selectedStartDate).format('YYYY-MM-DD HH:mm');
                    eventRoleEndTime = moment(entityRelationship.selectedEndDate).format('YYYY-MM-DD HH:mm');
                }
            });

            // Prepare data for AJAX POST
            let data = {
                first_name: detail.firstName,
                last_name: detail.lastName,
                email: detail.email,
                mobile: detail.mobile,
                event_role_start_time: eventRoleStartTime,
                event_role_end_time: eventRoleEndTime,
                quota_ids: quotaIds,
                submit: true,
                return_json: true,
                profile_id: this.dialog.selectedId,
                confirmed_new : confirmedNew,
                updating_existing: updatingExisting
            };

            this.dialog.loading = true;

            $.ajax({
                url: '/profiles/add',
                method: "POST",
                data,
                success: result => {
                    this._userAddedHandler(result);
                },
                error: () => {
                    this.dialog.loading = false;
                }
            });
        });

        this.profilesDialog.addEventListener('new-clicked', (e) => {
            confirmedNew = true;
            this.dialog.submit();
        });

        this.profilesDialog.addEventListener('existing-clicked', (e) => {
            this.dialog.selectedId = e.detail.item.id;
            updatingExisting = e.detail.updating;
            this.dialog.submit();
        })
    }

    async _userAddedHandler(result) {
        // TODO: Add user to storage
        if(result.profiles && Object.values(result.profiles).length > 0){
            // we have found some already existing profiles, we need to open the duplicate list
            this.profilesDialog.profiles = Object.values(result.profiles);

            this.dialog.loading = false;
            this.dialog.$.dialog.close();

            this.profilesDialog.toggle();
            return;
        }

        let personChanges = await this.handleAddPerson(result);

        if (this.dialog.context) {
            // We have a context.
            // Close the dialog...
            this.dialog.close();


            if(!!this.profilesDialog.opened){
                this.profilesDialog.toggle();
                confirmedNew = false;
                updatingExisting = false;
                this.dialog.selectedId = undefined;
                this.profilesDialog.reset();
            }

            this.dialog.loading = this.profilesDialog.loading = false;

            // Reload group members
            let $page_size = $(".page_size");
            $page_size.val(Number($page_size.val()) + 1);

            if (this.contextEntityType === this.constructor.ENTITY_TYPE_EVENT) {
                // Reload event participants
                reloadParticipants();
            }

            $("#group_people_search_button").click();

            this.checkForAdministrator(result);
        }
        else if (result.redirect_url) {
            // No context, redirect to Event or Group
            window.location.href = result.redirect_url;
        } else{
            this.profilesDialog.handledMessage = "It Looks Like Your Session Has Expired, Refreshing";
            window.location.reload();
        }
    }

    updateUserCount(){
        let summaryElement = document.querySelector('cbar-entity-summary');

        if(!summaryElement){
            return;
        }

        let userList = document.querySelectorAll('.person_summary:not(.ajax-blueprint)');
        let totalCountDiv = document.querySelector('.control .total_count');
        let volunteerDiv = document.querySelector('.volunteer-div');

        summaryElement.entityMemberCount = totalCountDiv.innerHTML = userList.length;

        if(volunteerDiv){
            volunteerDiv.innerHTML = `Volunteers : ${userList.length}`;
        }
    }

    checkForAdministrator(result){
        let addedProfile = result.profile_object;
        // check for any existing linking in the admin section
        let existingLink = document.querySelector(`.administrators-div a[data-id="${addedProfile.id}"]`);

        // check whether we dont have a link before continuing with a valid dataset
        if(!existingLink && result.add_role_result && addedProfile) {
            result.add_role_result.forEach((addedRole) => {
                // if the role added is an admin, we need to add the user to the list
                if(addedRole.quota_type && addedRole.quota_type === this.constructor.RELATIONSHIP_ADMINISTRATOR){
                    // different pages have different layouts
                    // TODO: should we make this consistent?
                    if($('body').is('#groups')) {
                        this.administratorsDiv.append(', ');
                    }

                    let newDiv = document.createElement('a');
                    newDiv.innerText = `${addedProfile.firstName}${addedProfile.lastName ? ` ${addedProfile.lastName}` : ""}`;
                    newDiv.dataset.id = addedProfile.id;
                    newDiv.href = result.redirect_url;

                    this.administratorsDiv.append(newDiv);

                    if($('body').is('#events')){
                        let spacerDiv = document.createElement('br');
                        if(addedProfile.mobile) {
                            this.administratorsDiv.append(" " + addedProfile.mobile);
                        }
                        this.administratorsDiv.append(spacerDiv);
                    }
                }
            });
        } else if(existingLink && result.add_role_result && addedProfile){
            let roles = result.role_elements.toArray();
            let stillHasRole = false;

            roles.forEach((roleDisplay) => {
                if(roleDisplay.dataset.roleName === this.constructor.RELATIONSHIP_ADMINISTRATOR){
                    stillHasRole = true
                }
            })

            if(!stillHasRole){
                if($('body').is('#events')){
                    existingLink.nextElementSibling.remove();
                }
                existingLink.nextSibling.remove();
                existingLink.remove();
            }
        }
    }

    async handleAddPerson(result) {
        return new Promise((res, rej) => {
            let indexDB = window.indexedDB.open('CC-STORAGE');
            indexDB.onsuccess = (event) => {

                let datababase = event.target.result;
                let userToAdd = result;

                if (!userToAdd.profile_object) {
                    // some situations in controller we dont need to worry about this
                    res();
                }

                let transaction = datababase.transaction(["keyvaluepairs"], "readwrite");
                let ccProfileRequest = transaction.objectStore("keyvaluepairs").get('ccProfiles');

                transaction.onerror = function(event) {
                    rej('Issue Here : ', event);
                };

                ccProfileRequest.onsuccess = (event) => {
                    let profiles = event.target.result;
                    profiles.push(userToAdd.profile_object);

                    transaction.objectStore('keyvaluepairs').delete('ccProfiles');
                    let addTransation = transaction.objectStore('keyvaluepairs').add(profiles, 'ccProfiles');
                    addTransation.onsuccess = (event) => {
                        res(event);
                    }
                };
            };
        })
    }

    /**
     * Formats the passed results into a format suitable for the 'entities'
     * property in the cbar-add-person dialog
     *
     * @param {Object} result The result from a geteventdropdowns or
     *     getgroupdropdowns AJAX call
     * @param {String} entityType The entity type for this result, either
     *     ENTITY_TYPE_EVENT or ENTITY_TYPE_GROUP
     * @returns {Array} The formatted entities
     */
    _formatResults(result, entityType) {
        let ret = [];

        if (!result.error && result.results) {
            for (let entity of result.results) {
                if (entity.hasOwnProperty('value') && entity.hasOwnProperty('label')) {
                    let intVal = parseInt(entity.value);
                    if (intVal === 0 || isNaN(intVal)) {
                        // Skip if ID is not a valid int
                        continue;
                    }

                    let formattedEntity = {
                        id: entity.value,
                        title: entity.label,
                        availableRelationships: this._formatQuotas(entity.quotas),

                        // The dialog doesn't require the entity to
                        // have a start/end time, but we'll include it
                        // so that we can use them as fallbacks in our
                        // _formatDatesForEntityRoles method.
                        start_time: entity.start_time,
                        end_time: entity.end_time,
                    };

                    if (entityType === this.constructor.ENTITY_TYPE_EVENT) {
                        // Convert d-m-y dates in the quotas to Date objects
                        // (Note: Currently only Events roles allow dates in CC)
                        this._formatDatesForEntityRoles(formattedEntity);
                    }

                    ret.push(formattedEntity);
                }
            }
        }

        return ret;
    }

    _formatQuotas(quotas) {
        quotas = quotas || [];

        for (let quota of quotas) {
            // our @cbar elements use 'title' instead of 'label'
            quota.title = quota.label;
        }

        return quotas;
    }

    /**
     * Ensures that the dates for our roles are defaulted to the
     * best guess.
     * We try the following dates in order:
     *
     * - The role's defined start/end date.
     * -- If not found, then we try the role's start date (in this case, the
     * start/end date will be the same)
     * - The entity's defined start/end date.
     * -- As above with the role, if not found, then we try the entity's start
     * date.
     * - Today's date
     * @param entity
     */
    _formatDatesForEntityRoles(entity) {
        const momentToday = moment();

        const dateProps = {
            'start_time': 'startTime',
            'end_time': 'endTime'
        };

        for (let relationship of entity.availableRelationships) {
            for (let [origProp, newProp] of Object.entries(dateProps)) {

                // Attempt to grab date a date from the relationship or entity
                let momentDate = this._firstValidDate([
                    // The start/end time from the relationship
                    [relationship[origProp], 'DD/MM/YYYY HH:mm A'],
                    // Fallback to the start/end time of the entity
                    entity[origProp],
                    // Fallback to the start time of the relationship
                    relationship.startTime,
                    // Fallback to the start time of the entity
                    entity.start_time,
                ]);

                if (!momentDate) {
                    // No valid dates found.
                    // For events, use today's date
                    momentDate = momentToday;
                }

                delete relationship[origProp];

                if (momentDate) {
                    relationship[newProp] = momentDate.toDate();
                }
            }
        }
    }

    /**
     * Gets the first valid date from that array of passed
     * dates.
     *
     * @param {Array} dates An array of dates. The values can be date strings
     *     or an array where the 0 index is a date and the 1 index is the
     *     format the date is in
     */
    _firstValidDate(dates) {
        for (let date of dates) {
            let format;

            if (!date) {
                continue;
            }

            if (date.constructor === Array) {
                // This should be a date and a format.
                [date, format] = date;
            }

            let momentDate = moment(date, format);

            if (momentDate.isValid()) {
                return momentDate;
            }
        }
    }

    /**
     * Builds a map of entities so that it is easy to search
     * for an entity based on ID (saves to the contextEntityIDMap variable)
     */
    _buildContextEntityIDMap() {
        let dialog = this.dialog;

        let {entities} = dialog;

        Object.keys(entities).forEach(entityType => {
            entities[entityType].forEach(entity => {
                contextEntityIDMap[this._getMapKeyForEntity(entityType, entity.id)] = entity;
            });
        });
    }

    /**
     * Retrieves a key to use for the contextEntityIDMap (used for looking up
     * entities by type and ID)
     * @param {String} entityType The entity type (ENTITY_TYPE_EVENT,
     *     ENTITY_TYPE_GROUP)
     * @param {Number} entityID The entity ID
     * @returns {String}
     */
    _getMapKeyForEntity(entityType, entityID) {
        return entityType + entityID;
    }

    /**
     * Past events arent grabbed in the initial loading of the dropdown here
     * To save load on the initial loading of the dropdown, we should only be grabbing these as required to prevent
     * overloading
     * @param id
     */
    grabPastEvent(id){
        return $.ajax({
            url : '/events/ajaxloadpreviousevent',
            method: 'GET',
            data: {
                id
            },
        });
    }
}

const addPerson = new AddPerson();
const openDialog = addPerson.openDialog.bind(addPerson);

export default addPerson;
export {openDialog};
