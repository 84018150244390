import localforage from 'localforage';

export default class LocalStorageAjax {
    static get promiseCache() {
        if( !this.__promiseCache ) {
            this.__promiseCache = {};
        }

        return this.__promiseCache;
    }

    static get database() {
        if (!this.__database) {
            this.__database = localforage.createInstance({
                name: "CC-STORAGE"
            });
        }

        return this.__database;
    }

    static get(key, options, expirySeconds = 120) {
        const SAVED_AT_KEY = key + '-saved-at';

        if( LocalStorageAjax.promiseCache.hasOwnProperty(key) ) {
            // There's an existing unresolved promise, return it.
            return LocalStorageAjax.promiseCache[ key ];
        }

        return LocalStorageAjax.promiseCache[ key ] = new Promise(async (res, rej) => {
            // Attempt to get the cached data from LocalStorage
            let cached = await this.database.getItem(key);

            if (cached) {
                cached = JSON.parse(cached);

                // Check if it has expired.
                let savedAt = parseInt(await this.database.getItem(SAVED_AT_KEY));

                if (savedAt + expirySeconds * 1000 > Date.now()) {
                    // Still valid
                    return res(cached);
                }
            }

            options.success = (data) => {
                this.database.setItem(key, JSON.stringify(data));
                this.database.setItem(SAVED_AT_KEY, Date.now().toString());
                res(data);
            };

            options.error = (xhr, status, error) => {
                rej(error);
            };

            // Load the data via AJAX and store in LocalStorage
            $.ajax(options);

            if (cached) {
                // Cache is stale, but we want to return something anyway.
                res(cached);
            }
        }).then((result) => {
            // Remove this promise from the unresolved promise cache
            delete LocalStorageAjax.promiseCache[ key ];

            return result;
        });
    }

    static set(key, value) {
        this.database.setItem(key, JSON.stringify(value));
    }

    static remove(key) {
        // Remove from this.database
        this.database.removeItem(key);

        // Remove from promiseCache
        delete LocalStorageAjax.promiseCache[ key ];
    }
}
