$(function () {
    $('.search .inline-filter').keyup(function () {
        var searchWrapper = $(this).closest(".search_wrapper");
        if (searchWrapper.length === 0) {
            searchWrapper = $("body");
        }

        var valThis = $(this).val().toLowerCase();
        var noresult = 0;
        if (valThis === "") {
            searchWrapper.find('.person_summary, .group_summary, .event_summary').not(".control").show();
            noresult = 1;
            searchWrapper.find('.no-results-found').remove();
        } else {
            searchWrapper.find('.person_summary, .group_summary, .event_summary').not(".control").each(function () {
                var text = $(this).find(".person_name a, .group_name a, .event_name a").text().toLowerCase();
                var regex = new RegExp(valThis, "g");
                if (regex.test(text)) {
                    $(this).show();
                    noresult = 1;
                    searchWrapper.find('.no-results-found').remove();
                } else {
                    $(this).hide();
                }
            });
        }

        if (noresult === 0) {
            searchWrapper.find(".navList").append('<li class="no-results-found">No results found.</li>');
        }
    });
});