import addPerson from "./cbar-add-person.module";
import ccPHPVars from './phpconstants';
import {getUrlForRoute} from "./route";

(() => {
    $("div.loading").hide();

    // Use jQuery-UI tooltip for primary profile indicator
    $(".pp-indicator").tooltip();

    // Toggle display of people widget in addperson.php when possible matches found
    if ($("body").is("#profiles, #addperson")) {
        $("#widget-search-people").hide();
    }
    $(".main-content").on("click", "a.toggle-widget", function () {
        if ($("#widget-search-people").is(":visible"))  {
            $("#widget-search-people").hide();
            $("a.toggle-widget").first().html("Click here to show");
            $('#submit').val('Submit');
        } else {
            $("#widget-search-people").show();
            $("a.toggle-widget").first().html("Click here to hide");
            $('#submit').val('Submit anyway');

        }
    });

    $('.masquerade_as').click(function () {
    	if(confirm("Do you want to masquerade as "+$(this).attr("person_name"))){
    	    localStorage.clear();

    		window.location = "/masquerade?person="+$(this).attr("person");
    	}
    });

    $('.task_checkbox').click(function () {
        var checked = $(this).is(":checked") ? "add" : "remove";

        var profileId = $(this).attr('data-profile');

        var profileTasksURL = getUrlForRoute(ccPHPVars.PROFILES_URL, {
            action: "tasks",
            id: profileId // unnecessary but doesn't hurt to have it.
        });

        $.ajax({
            url: profileTasksURL,
            type: 'POST',
            data: {
                task_id: $(this).val(),
                id: profileId,
                action: checked
            },
            success: function (data) {
//                console.log(data);
            }
        });
    });

    let $actionIcons = $('#action_icons');
    let $personSummary = $('.person_summary[profile-id]');

    let profileId = $personSummary.attr('profile-id');
    let profileName = $personSummary.attr('profile-name');

    $actionIcons.find('.add_to_group').on('click', () => {
        addPerson.openDirectAddDialog(addPerson.constructor.ENTITY_TYPE_GROUP, profileId, profileName, true);
    });

    $actionIcons.find('.add_to_event').on('click', () => {
        addPerson.openDirectAddDialog(addPerson.constructor.ENTITY_TYPE_EVENT, profileId, profileName, true);
    });
})();
