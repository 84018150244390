$(function () {
    "use strict";

    function renderLineChart(data, container) {
        // TODO: use a dynamic height + width instead of hardcoding
        var margin = {top: 40, right: 20, bottom: 20, left: 20};
        var width = 340 - margin.left - margin.right;
        var height = 260 - margin.top - margin.bottom;

        var x = d3.scale.linear()
            .range([0, width]);

        var y = d3.scale.linear()
            .range([height, 0]);

        var line = d3.svg.line()
            .x(function(d) { return x(d.week); })
            .y(function(d) { return y(d.count); });

        var svg = d3.select(container).append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


        x.domain(d3.extent(data, function(d) { return d.week; }));
        y.domain(d3.extent(data, function(d) { return d.count; }));

        svg.append("path")
            .datum(data)
            .attr("class", "trend-line")
            .attr("d", line);

        // Add circle points + label
        svg.selectAll(".point")
            .data(data)
            .enter()
            .append("circle")
                .attr("class", "trend-dot")
                .attr("r", 6)
                .attr("cx", function (d) { return x(d.week); })
                .attr("cy", function (d) { return y(d.count); });

        svg.selectAll(".count")
            .data(data)
            .enter()
            .append("text")
                .attr("class", ".trend-count")
                .attr("x", function (d) { return x(d.week) - 5; })
                .attr("y", function (d) { return y(d.count) - 10; })
                .text(function (d) { return d.count; });
    }


    if ($("body").is("#dashboard") && $("#group-pr-trend").length) {
        // Get the number of new profile_relationships to groups
        // created/added in the past 5 weeks
        var numWeeks = 5;
        d3.json("/ajax/registrants-count?past_weeks=" + numWeeks + "&entity=group", function (error, data) {
            if (!error) {
                if (data.length > 0) {
                    $.each(data, function(index, obj) {
                        if (obj.week === numWeeks) {
                            d3.select(".this-week-group-count").text(obj.count);
                            return false;
                        }
                    });
                }

                if (data.length > 1) {
                    $.each(data, function(index, obj) {
                        if (obj.week === numWeeks - 1) {
                            d3.select(".last-week-group-count").text(obj.count);
                            return false;
                        }
                    });
                }

                renderLineChart(data, "#group-pr-trend");
            }
        });
    }

    //#18197 - add event registrations chart
    if ($("body").is("#dashboard") && $("#event-pr-trend").length) {
        // Get the number of new profile_relationships to events
        // created/added in the past 5 weeks
        var numWeeks = 5;
        d3.json("/ajax/registrants-count?past_weeks=" + numWeeks + "&entity=event", function (error, data) {
            if (!error) {
                if (data.length > 0) {
                    $.each(data, function(index, obj) {
                        if (obj.week === numWeeks) {
                            d3.select(".this-week-event-count").text(obj.count);
                            return false;
                        }
                    });
                }

                if (data.length > 1) {
                    $.each(data, function(index, obj) {
                        if (obj.week === numWeeks - 1) {
                            d3.select(".last-week-event-count").text(obj.count);
                            return false;
                        }
                    });
                }

                renderLineChart(data, "#event-pr-trend");
            }
        });
    }

    //#18197 - add new event registrations list
    if ($("body").is("#dashboard") && $("#event_registrations_list").length) {
        let past_weeks = 5;
        $.ajax({
            type: "POST",
            dataType: "json",
            url: "/ajax/registrants-list",
            data: {
                'past_weeks': past_weeks,
                'entity': 'event'
            },
            success: function (response) {
                $("#event_results tr").remove();
                if (response.length > 0) {
                    let $class = 'odd';
                    $.each(response, (index, item) => {
                        $("#event_results").append("<tr class='"+$class+"'><td>" + item.ProfileID + "</td><td> " + item.RelationshipTo + "</td><td> " + item.EntityID + "</td><td> " + item.DateCreated + "</td></tr>");
                        if ($class === 'odd') {
                            $class = 'even';
                        } else if ($class === 'even') {
                            $class = 'odd';
                        }
                    });
                    $("#event_total").empty();
                    $("#event_total").html(response.length + " total registrations.");
                } else {
                    $("#event_results").append("<tr><td colspan='4'>No Records found</td></tr>");
                }
            }
        });
    }

    //#18197 - add new group members list
    if ($("body").is("#dashboard") && $("#group_registrations_list").length) {
        let past_weeks = 5;
        $.ajax({
            type: "POST",
            dataType: "json",
            url: "/ajax/registrants-list",
            data: {
                'past_weeks': past_weeks,
                'entity': 'group'
            },
            success: function (response) {
                $("#group_results tr").remove();
                if (response.length > 0) {
                    let $class = 'odd';
                    $.each(response, (index, item) => {
                        $("#group_results").append("<tr class='"+$class+"'><td>" + item.ProfileID + "</td><td> " + item.RelationshipTo + "</td><td> " + item.EntityID + "</td><td> " + item.DateCreated + "</td></tr>");
                        if ($class === 'odd') {
                            $class = 'even';
                        } else if ($class === 'even') {
                            $class = 'odd';
                        }
                    });
                    $("#group_total").empty();
                    $("#group_total").html(response.length + " total registrations.");
                } else {
                    $("#group_results").append("<tr><td colspan='4'>No Records found</td></tr>");
                }
            }
        });
    }
});

