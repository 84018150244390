import {reformatDatePickerTime} from "./general";

$(function () {
    if ($("body").is("#import")) {
        $('.action_button').on('click', function () {
            var $parentElement;
            var $message = $('.message').removeClass('error success').hide();
            var $loading = $('#loading');

            var params = {
                event_import_id: $(this).siblings('.event_import_id').val(),
                event_id: $(this).siblings('.event_id').val(),
                group_id: $(this).siblings('.group_id').val()
            };

            var action_url;

            if ($(this).is('.create_new_button')) {
                action_url = "/import/ajaxcreatenewevent";
                $parentElement = $(this).closest('table');
            } else if ($(this).is('.set_match_button')) {
                action_url = "/import/ajaxsetmatch";
                $parentElement = $(this).closest('table');
            } else if ($(this).is('.delete_existing_button')) {
                action_url = "/import/ajaxdeleteexistingevent";
                $parentElement = $(this).closest('tr');
            } else if ($(this).is('.add_group_owner')) {
                action_url = "/import/ajaxaddgroupowner";
                $parentElement = $(this).closest('tr');
            } else if ($(this).is('.move_with_participants_button')) {
                action_url = "/import/ajaxmoveeventwithparticipants";
                $parentElement = $(this).closest('table');
            } else if ($(this).is('.move_without_participants_button')) {
                action_url = "/import/ajaxmoveeventwithoutparticipants";
                $parentElement = $(this).closest('table');
            } else if ($(this).is('.add_event_owner')) {
                action_url = "/import/ajaxaddgrouptoevent";
                $parentElement = $(this).closest('table');
            }else if ($(this).is('.update_event_owner')) {
                action_url = "/import/ajaxupdateeventowner";
                $parentElement = $(this).closest('table');
                params.old_group_id = $(this).siblings('.old_group_id').val();
            }  else if ($(this).is('.update_event_owner_without_participants')) {
                action_url = "/import/ajaxupdateeventownerwithoutparticipants";
                $parentElement = $(this).closest('table');
                params.old_group_id = $(this).siblings('.old_group_id').val();
            }

            $parentElement.before($loading.show()).hide();

            $.ajax({
                url: action_url,
                type: "POST",
                data: params,
                dataType: "json",
                success: function (data) {
                    $loading.hide();
                    if (data.error !== undefined && data.error.length > 0) {
                        $message.addClass('error').text(data.error).show();
                        $parentElement.before($message.show()).show();
                    } else if (data.result !== undefined && data.result.length > 0) {
                        $parentElement.before($message);
                        $message.addClass('success').text(data.result).show();
                        $parentElement.remove();
                    } else {
                        $message.addClass('error').text('data.result not returned from ajax call').show();
                        $parentElement.before($message.show()).show();
                    }
                },
                error: function () {
                    $loading.hide();
                    $message.addClass('error').text('Error response from ajax call').show();
                    $parentElement.before($message).show();
                    $parentElement.show();
                }
            });
        });

        //get events of selected group
        if ($("#import_events_from_csv").length !== false) {
            $("[name='groups']").on('change', function () {
                let selectedGroupID = $("[name='groups']").find(":selected").val();
                if (selectedGroupID != 0) {
                    //Get event and place in dropdown
                    $.ajax({
                        url: '/groups/ajaxgetgroupevents',
                        type: 'GET',
                        data: {
                            group_ids: [selectedGroupID],
                        },
                        success: function (data) {
                            if (data.operation === 'success') {
                                console.log("Length:", data.events.length);
                                //first clear whatever is set in dropdown
                                $("#parent_event").html("");
                                if (data.events.length > 0) {
                                    //now add options
                                    $("#parent_event").append("<option value='0' selected>- Select Parent Event -</option>");
                                    $.each(data.events, function (index, element) {
                                        $("#parent_event").append("<option value='" + element['id'] + "'>" + element['name'] + "</option>");
                                    });
                                } else {
                                    $("#parent_event").append("<option value='0' selected>- No Events Found -</option>");
                                }
                                $("#events").show();
                            }
                        }
                    })
                } else {
                    $("#events").hide();
                }
            });
        }

        //show groups dropdown if file is selected
        $("#file").on('change', function () {
            let fileName = $("#file").val();
            if (fileName) {
                $("#group_select").show();
            } else {
                $("#group_select").hide();
            }
        });



    }

});
