var map;

var mapMarkers = mapMarkers || [];
var markers = [];

var resizeTimeout;

var bounds;

var infobox;

function initializeGoogleMap() {
    "use strict";
    var mapOptions = {
        //disableDefaultUI: true,
        zoom: 18,
        panControl: false,
        zoomControl: true,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        overviewMapControl: true
                //draggable: false,
    };

    var markersExist = false;

    bounds = new google.maps.LatLngBounds();

    var mapCanvas = document.getElementById("map-canvas");

    if (mapCanvas !== null) {
        var infoboxWidth = parseInt(mapCanvas.offsetWidth) - 100;

        infobox = new google.maps.InfoWindow({
            maxWidth: infoboxWidth
        });

        map = new google.maps.Map(mapCanvas, mapOptions);

        google.maps.event.addListener(infobox, "closeclick", function () {
//            me_interactive_map.marker_status.active_id = null;
        });

        if ($("body").is("#events")) {
            var eventId = $(".current-event-id").html();
            var eventParams = {
                entity: "event",
                entity_id: eventId
            };
            loadAJAXMapMarkers(eventParams);
        } else {
            loadAJAXMapMarkers({ entity: "subscriber" });
        }
    }
}

function loadMarkers() {
    "use strict";
    // Unset any existing markers on the map
    if (markers.length > 0) {
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
    }
    markers = [];
    var idCounter = 0;
    if (typeof (mapMarkers) !== "undefined" && typeof (mapMarkers.length) !== "undefined") {
        for (var j = 0; j < mapMarkers.length; j++) {
            var marker_raw = mapMarkers[j];

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(marker_raw.lat, marker_raw.lon),
                title: marker_raw.title,
                infoboxContent: marker_raw.infobox,
                map: map,
                markerId: idCounter
            });

            //extend the bounds to include each marker's position
            bounds.extend(marker.position);
            google.maps.event.addListener(marker, "click", infowindowOpen(marker));
            markers.push(marker);
            idCounter++;
        }
    } else {
        // If there are no map markers, provide some feedback
        console.log("No map markers to display.");
    }
}

function fitBounds() {
    "use strict";
    bounds = new google.maps.LatLngBounds();
    var countMarkers = 0;

    var safetyCount = 0;

    if (typeof (markers) !== "undefined" && parseInt(markers.length) > 0) {
        // loop through all loaded markers
        for (var j = 0; j < markers.length; j++) {
            bounds.extend(markers[j].position);
            countMarkers++;
        }
    }

    // If there's only one marker, centre the map around it
    if (markers.length > 1) {
        map.fitBounds(bounds);
    } else if (markers.length === 1) {
        map.setCenter(markers[0].position);
    }
}

function infowindowOpen(marker) {
    "use strict";
    return function () {
        infobox.setContent(marker.infoboxContent);
        infobox.open(map, marker);
    };
}

function mapReload() {
    "use strict";
    if (resizeTimeout) {
        clearTimeout(resizeTimeout);
    }
    if (typeof (map) !== "undefined" && map !== null) {
        resizeTimeout = setTimeout(function() {
            google.maps.event.trigger(map, "resize");
            fitBounds();
        }, 10);
    }
}

function loadAJAXMapMarkers(params) {
    "use strict";
    // Take entity and entity ID and ship off to AJAX handler
    $.ajax({
        url: "/ajax/map-markers",
        type: "POST",
        data: params,
        success: function (data) {
//                    console.log(data);
            // AJAX handler returns map markers
            mapMarkers = data.results;

            if ($("body").is("#events") && typeof (mapMarkers) !== "undefined" && typeof (mapMarkers.length) === 0) {
                // Remove map area if event location not found
                $("#map-canvas").hide();
            }
            loadMarkers();
            fitBounds();
        }
    });
}

$(function () {
    "use strict";

    // Initialise location autocompletion for new events created in group.php
    if ($(".form input[name=location]").length) {
        var locInput = $(".form input[name=location]").get(0);
        var locOptions = {
            types: ["address"],
            componentRestrictions: {country: "au"}
        };
        var locAutocomplete = new google.maps.places.Autocomplete(locInput, locOptions);

        // Set address components on change
        google.maps.event.addListener(locAutocomplete, "place_changed", function() {
            var formAddress = $(".form input[name=address_line_1]");
            var formSuburb = $(".form input[name=suburb]");
            var formPostcode = $(".form input[name=post_code]");
            var formState = $(".form select[name=state]");

            // Reset form values
            formAddress.val("");
            formSuburb.val("");
            formPostcode.val("");
            formState.val("");

            // Get new address components everytime it changes
            var locObject = locAutocomplete.getPlace();

            // Invalid address
            if (!locObject.geometry) {
                return;
            }

            // Autofill address fields
            var locTypes = {
                subpremise: {name: "long_name", value: ""},
                street_number: {name: "long_name", value: ""},
                route: {name: "long_name", value: ""},
                locality: {name: "long_name", value: ""},
                administrative_area_level_1: {name: "short_name", value: ""},
                postal_code: {name: "short_name", value: ""},
                country: {name: "long_name", value: ""}
            };

            $.each(locObject.address_components, function(index, component) {
                var type = component.types[0];
                if (locTypes[type] && locTypes[type].name) {
                    locTypes[type].value = component[locTypes[type].name];
                }
            });

            if (locTypes.subpremise.value) {
                locTypes.street_number.value = locTypes.subpremise.value + "/" + locTypes.street_number.value;
            }

            var street_address_1 = (locTypes.street_number.value + " " + locTypes.route.value).trim();
            var suburb = locTypes.locality.value;
            var postcode = locTypes.postal_code.value;
            var state = locTypes.administrative_area_level_1.value;
            // #11026 - here we should check if this address is in our database or not,
            // if yes then we need to set location_title if set.
            if ($(".form input[name=location_title]").length > 0) {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: "/Zendajax/searchlocation",
                    data: {
                        address_line_1: street_address_1,
                        suburb: suburb,
                        post_code: postcode,
                        state: state
                    },
                    success: function(data) {
                        if (data.result === 'failed') {
                            $(".form input[name=location_id]").val('');
                            $(".form input[name=location_title]").val('');
                        } else {
                            $(".form input[name=location_id]").val(data.location_id);
                            $(".form input[name=location_title]").val(data.location_title);
                        }
                    }
                });
            }

            formAddress.val(street_address_1);
            formSuburb.val(suburb);
            formPostcode.val(postcode);
            formState.val(state);
        });
    }


    // Check for browser SVG support, and only bring button marker forward if supported
    if (document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1")) {
        $(".block-me-interactive-map-display .button-marker")
                .css("z-index", "10")
                .find("svg").hide();
    }
    if (typeof google === 'object' && typeof google.maps === 'object') {
        initializeGoogleMap();
    }

    // What do we need to recalculate when the window resizes?
    $(window).bind("resize", function () {
        // Bueller?
        mapReload();
    });

    $("body").on("click", ".tabs > ul li a[href^='#'], [role=tab] a", function (event) {
        mapReload();
    });

    $(".map-groups").on("change", function (event) {
        var selectedOption = $(".map-groups").find("option:selected");
        var params = {
            entity: selectedOption.attr("data-entity"),
            entity_id: selectedOption.val()
        };
        loadAJAXMapMarkers(params);
    });

    if ($("select.map-groups").length > 0) {
        var selectedOption = $("select.map-groups").find("option:selected");
        var params = {
            entity: selectedOption.attr("data-entity"),
            entity_id: selectedOption.val()
        };
        loadAJAXMapMarkers(params);
    }
});
