/**
 * This file is used to control the multiple time/date picker elements that will be located within campaign-central.
 * Controlled by the setupPicker() function, this allows for all of the inputs marked with the class "start_time" or "end_time"
 * with an event listener that triggers the dialog on any page.
 */
window.customElements.whenDefined('cbar-date-time-picker').then(() => {

    //Inital variables
    let context = "";
    let target = "";
    let nextFocus;

    let picker;

    setupPicker();

    /**
     * Creates a dialog when this is loaded for the page to use.
     */
    function setupPicker() {
        picker = document.createElement('cbar-date-time-picker');
        picker.withBackdrop = true;
        document.body.appendChild(picker);


            picker.addEventListener('date-changed', e => {
                let date = e.detail.value;
                if(!date){
                    return;
                }

                if (picker.dateOpened || picker.timeOpened) {
                    // still going
                    return;
                }

                if (nextFocus) {
                    nextFocus.focus();
                }
                confirmInfo(date);
            });
    }

    const datePickerOnFocus = (selector, autoOpenTimeDialog) => {
        $(document).on('focus', selector, function (e) {
            $(this).one('click', e => {
                // Don't let the click that immediately follows the focus event
                // trigger a 'cancel' on the dialog
                e.stopImmediatePropagation();
            });

            createFocusInput(this);
            picker.autoOpenTimeDialog = autoOpenTimeDialog;
            openDialog(e);
        });
    };

    // Add event listeners on every date input
    // Only other noted date inputs are the filter event date which has no context
    datePickerOnFocus('.start_time, .end_time', true);
    datePickerOnFocus('.unallocated_date, .filter_event_date', false);

    /**
     * function that allows for the dialog to be opened by any of the above mentioned buttons
     * checks the context and sets the minimum allowed date accordingly, once completed it opens the dialog.
     * @param e
     */
    function openDialog(e) {
        picker.openDateDialog();

        target = e.target;
        /**role
         * if the target that we are trying to grab has a value attribute set, we want to set it to that
         * then we set the picker values to those.
         */
        if (target.value && target.value !== "") {
            picker.date = formatDateForPicker(target.value);
        } else {
            let date = moment();

            date.set({
                hour: '09',
                minute: '00'
            });
            // lets set the time to something a little more specific
            picker.date = date.toDate();
        }

        /**
         * set the context based on the classlist containment of start time, if its not present we can assume its an end_time input
         * @type {string}
         */
        context = !!target.classList.contains('end_time') ? "end" : "start";

        /**
         * if the context is an end_date input we want to make sure that the minDate attribute on the picker is set.
         * This should be true
         */
        if (context === "end") {
            // If it's an end date input we want to make sure its larger than the matching start date input.
            // Find the corresponding start date
            let startElementSelector = target.dataset.startElementSelector;
            if (startElementSelector) {
                let startDateElement = document.querySelector(startElementSelector);

                if (startDateElement.value !== "") {
                    let minDate = getMomentDate(startDateElement.value);
                    picker.date = minDate.add(1, 'hour').toDate();
                }
            }
        } else {
            // if not, we dont care what the date is
            picker.minDate = false;
        }
    }

    /**
     * format the date function for CC
     * @param date
     */
    function confirmInfo(date) {
        let format = !!picker.autoOpenTimeDialog ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY';
        let formattedDate = moment(date).format(format);

        target.setAttribute('value', formattedDate);
        target.value = formattedDate;

        if(!picker.validate()){
            target.classList.add("error");
        } else {
            target.classList.remove("error");
        }

        var event = new Event('change', {
            bubbles: true
        });

        target.dispatchEvent(event);
    }

    /**
     * formats the CC formated date into a date object for the picker to read
     * @param date
     */
    function formatDateForPicker(date) {
        let momentDate = getMomentDate(date);
        return momentDate.toDate();
    }

    function getMomentDate(date){
        return moment(date, ['DD-MM-YYYY HH:mm']);
    }

    function createFocusInput(input) {
        if (!input.__fakeInput) {
            input.__fakeInput = document.createElement('input');
            input.__fakeInput.style.position = 'absolute';
            input.__fakeInput.style.opacity = 0;
            input.__fakeInput.style.left = '-9000px';
            input.insertAdjacentElement('afterend', input.__fakeInput);
            nextFocus = input.__fakeInput;
        }
    }
});