$(function () {
    "use strict";

    if ($('body').is('#profiles')) {
        var noteSubmit = document.querySelector('#note-submit');
        var scrollElement = document.querySelector('cbar-entity-summary');

        if (noteSubmit) {
            noteSubmit.addEventListener('click', function () {
                document.querySelector('#AddNoteForm').submit();
            });
        }

        if(scrollElement) {
            requestAnimationFrame(() => {
                scrollElement.fixHeaderDiv();
            });
            scrollElement.addEventListener('back-button-clicked', function () {
                history.back();
            });
        }

        var paperTabs = document.querySelector('paper-tabs');
        if (paperTabs) {
            paperTabs.addEventListener('selected-changed', function (e) {
                var pages = document.querySelector('iron-pages');
                pages.selected = e.detail.value;

                window.scrollTo(0, paperTabs.offsetTop - 8);
            });
        }

        let addressElement = document.querySelector('cbar-address-autocomplete');
        if (addressElement) {
            addressElement.optionalFields = ['address1', 'address2', 'address3', 'city', 'state', 'zipCode', 'country', 'title'];
        }

        let countryElement = document.querySelector('.hidden-country');
        let mobileElement = document.querySelector('.hidden-mobile');
        let countryPhone = document.querySelector('cbar-country-phone-select');

        let countryObject = false;

        if (countryElement) {
            countryObject = JSON.parse(countryElement.value);
        }

        requestAnimationFrame(() => {
            if (countryPhone) {
                // Wait for rendering
                countryPhone.country = countryObject;
            }

            if (mobileElement) {
                countryPhone.number = mobileElement.value
            }
        });
    }
})