$(function () {
    if ($("body").is("#p")) {
        let loading = false;
        let pageElement = document.querySelector('cbar-projects-page');
        let editDialog = document.querySelector('#editConfirmDialog');
        let titleInput = document.querySelector('input#title');
        let editHeader = document.querySelector('.edit-header');
        let editContent = document.querySelector('.edit-content');
        let editActions = document.querySelector('.edit-actions');

        let contactDialog = document.querySelector('#contactDialog');
        let contactSpinner = contactDialog.querySelector('paper-spinner');
        let contactConfirm = contactDialog.querySelector('.confirm-button');
        let contactCancel = contactDialog.querySelector('.cancel-button');
        let contactConfirmDialog = document.querySelector('#contactConfirmDialog');

        let closeButtons = document.querySelectorAll('.close-button');
        let warningDialog = document.querySelector('.warning-dialog');

        let entityId = document.querySelector('#entity_id').value;
        let entity = document.querySelector('#entity').value;

        if (pageElement.address) {
            let restampValue = pageElement.address;

            pageElement.address = null;
            // this forces a restamp due to a bug inside the component handling json strings
            requestAnimationFrame(function () {
                pageElement.address = restampValue;
            })
        }

        contactCancel.addEventListener('tap', (e) => {
            contactDialog.close();
        });

        [contactDialog, editDialog].forEach((dialog) => {
            dialog.addEventListener('close', (e) => {
                if (loading) {
                    e.preventDefault();
                }
            })
        })

        closeButtons.forEach((button) => {
            button.addEventListener('tap', (e) => {
                e.target.closest('md-dialog').close();
            })
        })

        pageElement.addEventListener('edit-confirmed', function (e) {
            showLoading();
            editDialog.show();

            let details = e.detail;

            details.id = entityId;
            details.entity = entity;

            $.ajax({
                url: '/entitypage/ajaxedit',
                type: 'POST',
                data: details,
                success: function (data) {
                    showSuccess();
                },
                error: function (data) {
                    showError(data.error);
                }
            })
        });

        pageElement.addEventListener('join-button-clicked', function () {
            contactSpinner.removeAttribute('active');
            contactCancel.removeAttribute('disabled');
            contactConfirm.removeAttribute('disabled');
            contactDialog.show();
        })

        pageElement.addEventListener('back-button-clicked', function () {
            if (pageElement.editing) {
                warningDialog.show();
                return;
            }

            history.back();
        })

        contactConfirm.addEventListener('tap', function () {
            contactSpinner.active = true;
            contactConfirm.disabled = contactCancel.disabled = true;

            $.ajax({
                url: '/entitypage/ajaxcontact',
                type: 'POST',
                data: {
                    id: entityId,
                    entity
                },
                success: function (data) {
                    if (data.operation === "failed") {
                        showError(data.error);
                        return;
                    }

                    contactConfirm.removeAttribute('disabled');
                    contactCancel.removeAttribute('disabled');
                    contactSpinner.removeAttribute('active');

                    contactDialog.close();
                    contactConfirmDialog.show();
                },
                error: function (data) {
                    showError(data.error);
                }
            })
        })

        function showLoading() {
            updateTitle('loading');
            updateContent('loading');
            updateActions('loading');
            loading = true;
        }

        function showError(message) {
            loading = false;
            updateTitle('error');
            updateContent('error', message)
            updateActions('error')
        }

        function showSuccess() {
            loading = false;
            updateTitle('success');
            updateContent('success');
            updateActions('success');
        }

        function updateTitle(state) {
            let map = {
                "loading": "Please Wait",
                "error": "Error",
                "success": "Success"
            }

            if (!map[state]) {
                return null;
            }

            editHeader.innerText = map[state];
        }

        function updateContent(state, message = null) {
            let map = {
                "loading": `<div>Submitting details for:</div><div style='padding-top: 8px'><b>${titleInput.value}</b></div>`,
                "error": `<div>An Issue Has Occured :</div><div class="error-div">${message}</div>`,
                "success": `<div>Successful submission for:</div><div style="padding-top: 8px"><b>${titleInput.value}</b></div>`
            }

            if (!map[state]) {
                return null;
            }

            editContent.innerHTML = map[state];
        }

        function updateActions(state) {
            editActions.innerHTML = "";

            let map = {
                "loading": `paper-spinner`,
                "error": `paper-button`,
                "success": `paper-button`
            }

            if (!map[state]) {
                return null;
            }

            let newElement = document.createElement(map[state]);

            if (state !== "loading") {
                newElement.innerText = "Close";
                newElement.addEventListener('tap', (e) => {
                    e.target.closest('md-dialog').close()
                });
            } else {
                newElement.active = true;
            }

            editActions.append(newElement)
        }

    }
});