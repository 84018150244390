import {reformatDatePickerTime} from "./general";

$(function () {
    var getAvailableRoles = function (entity, entityID) {
        $(".loading-roles").fadeIn("fast");
        var params = { 
            "action": "list-roles-operation",
            "source_entity": "Profile",
            "source_entity_id": 0,
            "target_entity": entity,
            "target_entity_id": entityID
        };

        $.ajax({
            url: "/ajax/entity-roles",
            type: "POST",
            data: params,
            success: function (data) {
                if (data.error === false) {
                    $(".roles_to_add option").remove();
                    $(".roles_to_add")
                        .append($("<option>", { value : 0 })
                        .text("- Available Roles -"));

                    $.each(data.data, function (index, element) {
                        var optionParams = {
                            "data-start-time": reformatDatePickerTime(element.start_time),
                            "data-end-time": reformatDatePickerTime(element.end_time),
                            "data-quota": element.role_id,
                            "value": element.role_id
                        };
                        $(".roles_to_add")
                            .append($("<option>", optionParams)
                            .text(element.role_name));
                    });

                    $(".start_time").val("");
                    $(".end_time").val("");
                }
                $(".loading-roles").hide();
            }
        });
    };

    if ($("body").is("#import_people") || $("#import_people") !== 0) {
        selectEntity();

        $("#select_entity").change(function() {
            selectEntity();
        });

        $("#form").on("change", ".groups", function() {
            $(".event").val("0");
            $(".past_event").val("0");

            if (parseInt($(".groups").val()) > 0) {
                $(".import_role_fields").appendTo($(".group_roles_to_add"));
                $(".import_role_fields").show();
                getAvailableRoles("Group", $(".groups").val());
            } else {
                $(".import_role_fields").hide();
            }
        });

        $("#form").on("change", ".event", function() {
            $(".groups").val("0");
            $(".past_event").val("0");

            if (parseInt($(".event").val()) > 0) {
                $(".import_role_fields").appendTo($(".event_roles_to_add"));
                $(".import_role_fields").show();
                getAvailableRoles("Event", $(".event").val());
            } else {
                $(".import_role_fields").hide();
            }
        });

        $("#form").on("change", ".past_event", function() {
            $(".groups").val("0");
            $(".event").val("0");

            if (parseInt($(".past_event").val()) > 0) {
                $(".import_role_fields").appendTo($(".event_roles_to_add"));
                $(".import_role_fields").show();
                getAvailableRoles("Event", $(".past_event").val());
            } else {
                $(".import_role_fields").hide();
            }
        });

        $("#form").on("change", ".roles_to_add", function() {
            if (parseInt($(".roles_to_add").val()) > 0) {
                var selectedRole = $(".roles_to_add").find(":selected");
                $(".start_time").val(selectedRole.attr("data-start-time"));
                $(".end_time").val(selectedRole.attr("data-end-time"));
            } else {
                $(".start_time").val("");
                $(".end_time").val("");
            }
        });

        if (parseInt($(".groups").val()) > 0) {
            $(".groups").trigger("change");
        } else if (parseInt($(".event").val()) > 0) {
            $(".event").trigger("change");
        } else if (parseInt($(".past_event").val()) > 0) {
            $(".past_event").trigger("change");
        } else {
            $(".import_role_fields").hide();
        }

        $(".roles_to_add").trigger("change");
    }

    function selectEntity() {
        if ($("#select_entity option:selected").val() === 'group') {
            $("#add_to_group").show();
            $("#add_to_event").hide();
            $("#add_to_past_event").hide();
            $(".event_roles_to_add").hide();
            $(".group_roles_to_add").show();
            $(".groups").trigger("change");
        } else if ($("#select_entity option:selected").val() === 'event') {
            $("#add_to_group").hide();
            $("#add_to_event").show();
            $("#add_to_past_event").hide();
            $(".group_roles_to_add").hide();
            $(".event_roles_to_add").show();
            $(".event").trigger("change");
        } else if ($("#select_entity option:selected").val() === 'past_event') {
            $("#add_to_group").hide();
            $("#add_to_event").hide();
            $("#add_to_past_event").show();
            $(".group_roles_to_add").hide();
            $(".event_roles_to_add").show();
            $(".past_event").trigger("change");
        } else {
            $("#add_to_group").hide();
            $("#add_to_event").hide();
            $("#add_to_past_event").hide();
            $(".group_roles_to_add").hide();
            $(".event_roles_to_add").hide();
        }
    }

});
