/**
 * Gets a URL for the passed route and parameters.
 *
 * @param {string} route A Zend-style route with parameter placeholders in the form "{{PLACEHOLDER}}" (e.g. /gallery/...TODO
 * @param {Object.<string>} params
 *
 * @author Dejan Lukič
 */
export function getUrlForRoute(route, params) {
    params = params || {};

    for( var paramName in params ) {
        if( params.hasOwnProperty(paramName) ) {
            // Replace the placeholder for this param in our url.
            route = route.replace("{{" + paramName + "}}", params[ paramName ]);
        }
    }

    // Now that we've replaced all of our placeholders with the
    // parameters, we should remove any other placeholders that we
    // haven't yet replaced.
    route = route.replace(/\/{{.*?}}/g, "");

    return route;
}
