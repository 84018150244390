import ccPHPVars from './phpconstants';
import {getUrlForRoute} from "./route";

(() => {
    var ajaxSpinnerURL = '/images/gallery/ajaxspinner.gif';

    var addImageRoute = ccPHPVars.GALLERY_ADD_URL;
    var getImageRoute = ccPHPVars.GALLERY_IMAGE_URL;
    var removeImageURL = "/file/remove";
    var fileDownloadRoute = ccPHPVars.FILE_DOWNLOAD_URL;
    var cropActionRoute = ccPHPVars.GALLERY_CROP_URL;

    var $formFileUpload = $("#form_upload_file");
    var $hiddenInputs = $formFileUpload.find("input[type=hidden]");
    var $typeEle = $hiddenInputs.filter("[name=type]");
    var $idEle = $hiddenInputs.filter("[name=id]");
    var $imageContainer = $("#content-group-photos");
    var $fileContainer = $(".div_uploaded_files");
    var $fileCategory = $formFileUpload.find("#file_category");
    var $iframeFileUpload = $("#iframe_file_upload");
    var $imageType = $formFileUpload.find("#image_type");
    var $fileUploadEle = $("#file_upload");
    var $imageUploadEle = $("#image_upload");

    var isLongPress = false;

    jQuery.fn.centerElementFixed = function () {
        return this.each(function() {
            var top = ($(window).height() - $(this).outerHeight()) / 2;
            var left = ($(window).width() - $(this).outerWidth()) / 2;
            $(this).css({position:'fixed', margin:0, top: (top > 0 ? top : 0)+'px', left: (left > 0 ? left : 0)+'px'});
        });
    };

    jQuery.fn.centerElementRelative = function () {
        return this.each(function() {
            var top = ($(this).parent().height() - $(this).outerHeight()) / 2;
            var left = ($(this).parent().width() - $(this).outerWidth()) / 2;
            $(this).css({position:'relative', margin:0, top: (top > 0 ? top : 0)+'px', left: (left > 0 ? left : 0)+'px'});
        });
    };

    jQuery.fn.longPress = function (cb, delay) {
        delay = delay || 500;
        var timer;
        var mouseLeaveEvent;

        var mouseLeaveFunc = function() {
            clearTimeout(timer);
        };

        timer = setTimeout(function() {
            isLongPress = true;
            cb();
        }, delay);

        $(this).on('mouseup mouseleave dragstart', function(e) {
            mouseLeaveEvent = e;
            $(this).off(e);
            mouseLeaveFunc();
        });
    };

    function addThumbnailToDOM(thumb_id, thumb_filename, image_id, image_filename, parent_div_className) {
        $(".no-gallery-photos").hide(); // Hide our "Currently no photos" text.

        var thumb_div = $('<div draggable="true" name="' + image_filename + '" class="org_thumbnail"></div>');
        // Create a temporary image element just for loading the image.
        var tempImg = $('<img src="' +  getUrlForRoute(getImageRoute, {image_file_name: thumb_filename}) + '" />')
            .on('load', function() {
                $(this).hide();
                var img_parent = $(this).parent('.org_thumbnail');
                $(img_parent).children('.ajax_spinner').remove();
                $(img_parent).css('background-image', 'url("' + $(this).attr('src') + '")');
                $(this).remove();
            });

        var profileCropURL = getUrlForRoute(cropActionRoute, {
            image_type: "profile",
            image_file_name: image_filename,
            entity_type: $typeEle.val(),
            entity_id: $idEle.val()
        });

        var bannerCropURL = getUrlForRoute(cropActionRoute, {
            image_type: "banner",
            image_file_name: image_filename,
            entity_type: $typeEle.val(),
            entity_id: $idEle.val()
        });

        var delete_button = '<i class="delete_image_button icon-remove"></i>';
        var crop_button_1 = '<a class="crop_image_button profile" href="' + profileCropURL + '">Make profile</a>';
        var crop_button_2 = '<a class="crop_image_button banner" href="' + bannerCropURL + '">Make banner</a>';
        var mass_handle_checkbox = $('<input type="checkbox" class="checkbox_thumbnail" name="checkbox_thumbnail" style="display:none;" />');
        var hidden_thumb_id = $('<input type="hidden" name="thumb_image_id" value="' + thumb_id + '" />');
        var hidden_image_id = $('<input type="hidden" name="original_image_id" value="' + image_id + '" />');

        $(thumb_div).append(delete_button);
        $(thumb_div).append(crop_button_1);
        $(thumb_div).append(crop_button_2);
        $(thumb_div).append(mass_handle_checkbox);
        $(thumb_div).append(hidden_thumb_id);
        $(thumb_div).append(hidden_image_id);
        $(thumb_div).append(tempImg);

        $("." + parent_div_className).append(thumb_div);

        createAjaxSpinner(thumb_div);
        $(window).scrollTop($(thumb_div).offset().top);
    }

    function createAjaxSpinner(parentDiv) {
        var ajax_spinner = $('<img class="ajax_spinner" src="' + ajaxSpinnerURL + '">');
        $(parentDiv).append(ajax_spinner);
    }

    /**
     * Deletes images based on their thumbnail div
     * @param {Array} thumbnailDiv An array of thumbnail DIV's
     * @returns {void}
     */
    function deleteImage(thumbnailDiv) {
        var fileIDArray = [];
        for( var i = 0; i < thumbnailDiv.length; i++ ) {
            var imageIDElements = thumbnailDiv[i].children('input[type=hidden]');
            fileIDArray.push($(imageIDElements[0]).val()); // add thumbnail ID to array
            fileIDArray.push($(imageIDElements[1]).val()); // add original image ID to array
            // Now delete the image's DOM element.
            if( thumbnailDiv[i].hasClass('banner') ) {
                thumbnailDiv[i].prop('outerHTML', '<div class="new_banner">No banner.</div>');
            }else if( thumbnailDiv[i].hasClass('profile') ) {
                thumbnailDiv[i].prop('outerHTML', '<div class="new_profile">No profile picture.</div>');
            }else {
                thumbnailDiv[i].remove();
            }
        }

        if( $(".div_general_images > .org_thumbnail").length === 0 ) {
            // Show our "No photos" text if we don't have any more photos.
            $(".no-gallery-photos").show();
        }

        removeImageAjax(fileIDArray);
    }

    /**
     * Calls the server removeImage script
     * @param {Array} image_file_ids
     * @returns {void}
     */
    function removeImageAjax(image_file_ids) {
        $.ajax({
            url: removeImageURL,
            type: "POST",
            data: {
                file_ids: image_file_ids
            },
            success: function(){
            },
            error: function() {
                alert("Failed to delete photo. Please refresh the page.");
            }
        });
    }

    function centerImageViewer() {
        $('.div_image_viewer').centerElementFixed();
        $('#img_placeholder').centerElementRelative();
    }

    function setImageViewerMaxWidthHeight() {
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();

        // Reset placeholder maxwidth and maxheight
        $('#img_placeholder').css('maxWidth', '').css('maxHeight', '');
        // Ensure that the image is no bigger than the window
        // width and height - 100
        if( windowHeight-100 < 500 ) {
            $('#img_placeholder').css('maxHeight', windowHeight-100);
        }

        if( windowWidth-100 < 1024 ) {
            $('#img_placeholder').css('maxWidth', windowWidth-100);
        }

        $('.div_image_viewer').css({width: $('#img_placeholder').width()+100, height: $('#img_placeholder').height()});
    }

    // ON IMAGE LOAD
    $('#img_placeholder').on('load', function() {
        // Adjust the div size to match the image size, then center it.
        setImageViewerMaxWidthHeight();
        centerImageViewer();
        // The image is centered, now show the prev/next arrows
        // and the # of # text
        $('.div_next_image').show();
        $('.image_viewer_counter').show();
    });

    // Make sure that the div covers the entire screen, even when the window is resized.
    // This also resizes the image viewer.
    $(window).on('resize', function() {
        $('.div_modal_overlay').css({width: $(document).width(), height: $(document).height()});
        setImageViewerMaxWidthHeight();
        centerImageViewer();
    });

    $('.link_add_file:not("paper-fab")').on('click', function() {
        var $this = $(this);
        $imageType.val('general');

        // Change the file category so that our server knows
        // if we're uploading an image or a file.
        // Also, change the "accept" attribute on the file upload
        // element so that we can properly filter only images when
        // the user is uploading an image.
        if( $this.hasClass("image") ) {
            $fileUploadEle.attr('accept', "image/*");
            $fileCategory.val("image");
        }else if( $this.hasClass("file") ) {
            $fileUploadEle.removeAttr('accept');
            $fileCategory.val("file");
        }
    });

    $('paper-fab.link_add_file').on('click', function() {
        var $this = $(this);
        $imageType.val('general');

        // Change the file category so that our server knows
        // if we're uploading an image or a file.
        // Also, change the "accept" attribute on the file upload
        // element so that we can properly filter only images when
        // the user is uploading an image.
        if( $this.hasClass("image") ) {
            $fileUploadEle.attr('accept', "image/*");
            $fileCategory.val("image");
        }else if( $this.hasClass("file") ) {
            $fileUploadEle.removeAttr('accept');
            $fileCategory.val("file");
        }

        $this.closest('.upload-div').find('input#file_upload').click();
    });

    if( 'draggable' in document.createElement('span') ) {
        $("#link_add_image").on("dragenter", function(e) {
            e.preventDefault();
        });
        $("#link_add_image").on("dragover", function(e) {
            e.preventDefault();
        });
        $("#link_add_image").on('drop', function(e) {
            e.preventDefault();

            $fileCategory.val("image");
            $imageType.val('general');

            var files = e.originalEvent.dataTransfer.files;

            uploadFiles(files);
        });
    }

    function uploadFiles(files) {
        var formData = new FormData();

        // Add all our inputs from the form except the file
        // input to our FormData element.
        $formFileUpload.find("input:not([type=file])").each(function() {
            formData.append(this.name, this.value);
        });

        // Append all of our files to our FormData element.
        for( var i = 0; i < files.length; i++ ) {
            formData.append('file' + i, files[i]);
        }

        $.ajax({
            url: addImageRoute,
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function(uploadResult) {
                if( !handleUploadErrors(uploadResult) ) {
                    return false;
                }

                // Convert the uploadResult to an object
                // instead of an array, as the jQuery .trigger()
                // function
                uploadResult = {
                    files: uploadResult
                };

                // Check if the upload was an image or a file and trigger
                // the corresponding event.
                if( $fileCategory.val() === "image" ) {
                    $iframeFileUpload.trigger('imageUploaded', uploadResult);
                }else if( $fileCategory.val() === "file" ) {
                    $iframeFileUpload.trigger('fileUploaded', uploadResult);
                }
            }
        });
    }

    $fileUploadEle.on('change', function() {
        //$("#form_upload_file").submit().trigger('reset');
        uploadFiles(this.files);
    });

    $imageUploadEle.on('change', function() {
        //$("#form_upload_file").submit().trigger('reset');
        uploadFiles(this.files);
    });

    function handleUploadErrors(uploadResult) {
        return true;
        var dimension_error = iframe_body.children('.dimension_error');
        if( dimension_error.length !== 0 ) {
            alert('One or more of the images you uploaded failed. Ensure they are at least 1024x100 if they are banner images.');
            return false;
        }

        var banner_error = iframe_body.children('.banner_error');

        if( banner_error.length !== 0 ) {
            alert("You already have a banner image uploaded. Please first delete it to upload a new one.");
            return false;
        }

        return true;
    }

    $iframeFileUpload.on('load', function() {
        var $this = $(this);

        // Do not proceed if file contents are empty.
        // Assume that page has been loaded without POSTing a file upload.
        if (!$this.contents().text().length) {
            return;
        }

        try {
            var uploadResult = JSON.parse($this.contents().text());

            if( !handleUploadErrors(uploadResult) ) {
                return false;
            }

            // Convert the uploadResult to an object
            // instead of an array, as the jQuery .trigger()
            // function
            uploadResult = {
                files: uploadResult
            };

            // Check if the upload was an image or a file and trigger
            // the corresponding event.
            if( $fileCategory.val() === "image" ) {
                $this.trigger('imageUploaded', uploadResult);
            }else if( $fileCategory.val() === "file" ) {
                $this.trigger('fileUploaded', uploadResult);
            }

        }catch( e ) {
            alert("Error uploading file: " + e);
        }
    });

    $iframeFileUpload.on('imageUploaded', function(e, result) {
        // Loop through all the image files in our result object.
        for( var i = 0; i < result.files.length; i++ ) {
            var imageObj = result.files[ i ];

            if( !imageObj.hasOwnProperty("thumbnail") || undefined === imageObj.thumbnail.id ) {
                continue;
            }

            var parent_div_className = $imageType.val() === 'general' ? 'div_general_images' : 'div_banner_images';

            addThumbnailToDOM(imageObj.thumbnail.id, imageObj.thumbnail.filename, imageObj.id, imageObj.filename, parent_div_className);
        }
    });

    $iframeFileUpload.on('fileUploaded', function(e, result) {
        var $table = $("#table_uploaded_files");

        $("#alert_no_files_found").remove();
        // Add a new row for each file.
        for( var i = 0; i < result.files.length; i++ ) {
            var rowString = "<tr data-file-id='" + result.files[ i ].id + "'>";
            rowString +=        "<td>" + result.files[ i ].display_name + "</td>";
            rowString +=        "<td>" + result.files[ i ].uploaded_by + "</td>";
            rowString +=        "<td>" + result.files[ i ].upload_date + "</td>";

            rowString +=        "<td>";
            rowString +=            "<a title='Download' href='javascript:void(null);' data-file-id='" + result.files[ i ].id + "' class='download_file' ><i class='icon-download-alt'></i></a><br/>";
            rowString +=            "<a title='Remove' href='javascript:void(null);' data-file-id='" + result.files[ i ].id + "' class='remove_file' ><i class='icon-remove-sign'></i></a>";
            rowString +=        "</td>";
            rowString +=    "</tr>";

            $table.append(rowString);
        }
    });

    // Delete Image Button OnClick()
    $imageContainer.delegate('.delete_image_button', 'click', function(e) {
        e.stopPropagation(); // Prevent the event bubbling down to make sure it doesn't click the thumbnail.
        e.cancelBubble = true;
        var areusure = confirm("Are you sure you want to delete this image?");
        if( areusure ) {
            deleteImage([$(this).parent()]);
        }
    });

    // Crop Image Link OnClick()
    $imageContainer.delegate('.crop_image_button', 'click', function(e) {
        // e.preventDefault();
        e.stopPropagation(); // Prevent the event bubbling down to make sure it doesn't click the thumbnail.
        e.cancelBubble = true;
        /* window.open($(this).attr('href'));*/
    });

    function createModalOverlay() {
        if( $('.div_modal_overlay').length ) {
            return;
        }
        $(document.body).prepend('<div class="div_modal_overlay"></div>');
        $('.div_modal_overlay').css({width: $(document).width(), height: $(document).height()});
    }

    function getTotalThumbnailCount(parentDiv) {
        return parentDiv.children('.org_thumbnail').length;
    }

    function getCurrentImageViewerThumbnailDiv() {
        var thumbDivName = $('#img_placeholder').attr('name');
        return $('.org_thumbnail[name="'+thumbDivName+'"]');
    }

    function updateImageViewerCounter() {
        var thumbnailDiv = getCurrentImageViewerThumbnailDiv();
        var totalThumbs = getTotalThumbnailCount(thumbnailDiv.parent());
        var currentThumb = thumbnailDiv.prevAll('.org_thumbnail').length+1;

        $('.image_viewer_counter_text_current').html(currentThumb);
        $('.image_viewer_counter_text_total').html(totalThumbs);
    }

    function closeImageViewer() {
        $('.div_modal_overlay').remove();
        $('.div_image_viewer').hide();
        $('.div_next_image').hide();
        $('.image_viewer_counter').hide();
        $('#img_placeholder').attr('src', '');
    }

    function startCheckboxSelector() {
        createModalOverlay();
        $('.div_modal_overlay').bind('click.mass_handle', function(e) {
            stopCheckboxSelector();
        });

        // Disable thumbnail onHover() event
        $imageContainer.off('.cropdelete');

        $('.crop_image_button').add('.delete_image_button').hide();

        $('.org_thumbnail').add('.div_mass_handle_images').css('zIndex', 9999);
        $('.div_mass_handle_images').fadeIn(200);
        $('.checkbox_thumbnail').fadeIn(400);
        $('.org_thumbnail')
            .bind('click.select', function() {
                var childCheckbox = $(this).children('input[type="checkbox"]');
                var checked = $(childCheckbox).prop("checked");

                $(childCheckbox).prop("checked", !checked);
                return false;
            });
    }

    function stopCheckboxSelector() {
        $('.div_modal_overlay').remove();
        $('.org_thumbnail').css('zIndex', '');
        $('.div_modal_overlay').unbind('click.mass_handle');
        $('.org_thumbnail').unbind('click.select');
        $('.div_mass_handle_images').hide();
        $('.checkbox_thumbnail').hide().prop('checked', '');
        $imageContainer.on('mouseenter.cropdelete mouseleave.cropdelete','.org_thumbnail', showCropDeleteOnThumbnail);
    }

    // MASS IMAGE HANDLER FUNCTIONS
    $('.div_mass_handle_images a.cancel').on('click', function() {
        stopCheckboxSelector();
    });

    $('.div_mass_handle_images a.delete').on('click', function() {
        var deleteCount = $('.checkbox_thumbnail:checked').length;
        var areusure = confirm("Are you sure you want to delete " + deleteCount + " images?");
        if( areusure ) {
            var thumbnailArray = [];
            $('.checkbox_thumbnail:checked').each(function() {
                thumbnailArray.push($(this).parent());
            });
            deleteImage(thumbnailArray);
        }
    });

    // Thumbnail OnHover()
    // Show "delete" and "crop" button on images on hover.
    // Show image border on hover.
    $imageContainer.on('mouseenter mouseleave', '.org_thumbnail', function(event) {
        var currentLeftMargin = parseInt($(this).css('marginLeft'));
        var currentRightMargin = parseInt($(this).css('marginRight'));
        var currentTopMargin = parseInt($(this).css('marginTop'));
        var currentBottomMargin = parseInt($(this).css('marginBottom'));

        if( event.type === 'mouseenter' ) { // mouseenter
            $(this).css({
                //border: '2px solid blue',
                marginTop: currentTopMargin-2 + 'px',
                marginBottom: currentBottomMargin-2 + 'px',
                marginLeft: currentLeftMargin-2 + 'px',
                marginRight: currentRightMargin-2 + 'px',
                opacity: '0.7'
            });
        }else { // mouseleave
            $(this).css({border: 'none', margin: '', opacity: '1'});
        }
    });

    // Seperate OnHover() handler for hiding mouse
    $imageContainer.on('mouseenter.cropdelete mouseleave.cropdelete','.org_thumbnail', showCropDeleteOnThumbnail);

    function showCropDeleteOnThumbnail(event) {
        // alert('regular handler');
        if( event.type === 'mouseenter' ) { // mouseenter
            $(this).children('.delete_image_button').fadeIn(200);
            $(this).children('.crop_image_button').fadeIn(200);
        }else { // mouseleave
            $(this).children('.delete_image_button').fadeOut(200);
            $(this).children('.crop_image_button').fadeOut(200);
        }
    }

    // Thumbnail OnClick()
    $imageContainer.delegate('.org_thumbnail','click', function() {
        if( isLongPress) {
            isLongPress = false;
            return;
        }

        // TODO: Firstly, set the thumbnail as the image source until the actual image is loaded.
        $('#img_placeholder').attr('src', getUrlForRoute(getImageRoute, {image_file_name: $(this).attr('name')}));
        // set the name of the image to the name of the thumbnail div so we can
        // traverse later.
        $('#img_placeholder').attr('name', $(this).attr('name'));

        var modal = document.querySelector('cbar-photo-preview');

        if(!!modal){
            modal.image = $('#img_placeholder').attr('src');
            requestAnimationFrame(function(){
                if(!modal.open) {
                    modal.open = true;
                }
            });
        }
    });

    $imageContainer.delegate('.org_thumbnail','mousedown', function(e) {
        //TODO: Enable this later.
        if( e.button === 2 ) return;
        $(this).longPress(function() {
            startCheckboxSelector();
            isLongPress = false;
        });
    });

    $fileContainer.delegate('.download_file', 'click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        window.location = getUrlForRoute(fileDownloadRoute, {id: $(this).attr('data-file-id')});
    });

    $fileContainer.delegate('.remove_file', 'click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if( confirm("Do you want to remove this file?") ) {
            // Hide the table row, then delete it on success.
            var $rowToDelete = $(this).closest("tr");
            $rowToDelete.hide();

            $.ajax({
                type: "POST",
                url: removeImageURL,
                dataType: "json",
                data: {
                    file_ids: [$(this).attr("data-file-id")]
                },
                success: function(result) {
                    var errorMsg = "There was an error removing the file.";
                    var errorReason;

                    try {
                        if( result.success !== true ) {
                            errorReason = result.message;
                        }
                    }catch( e ) {
                        // Failed to parse JSON string.
                        errorReason = "Server response was invalid.";
                    }

                    if( errorReason !== undefined ) {
                        $rowToDelete.show();
                        alert(errorMsg + " Reason: " + errorReason);
                    }else {
                        // Successfully deleted the file. Remove the row.
                        $rowToDelete.remove();
                    }
                },
                error: function() {
                    alert("Failed to remove file. Please refresh the page and try again.");
                }
            });
        }
    });

    // DRAGGING GENERAL IMAGES TO BANNER
    /*$('#content-group-photos').on('dragstart', '.org_thumbnail', function(event) {
     event.originalEvent.dataTransfer.effectAllowed = 'move';
     event.originalEvent.dataTransfer.setData('text', $(this).children('.crop_image_button').attr('href'));
     });

     $('#content-group-photos').on('dragend', '.org_thumbnail', function(event) {
     event.preventDefault();
     event.stopPropagation();
     });

     $('#content-group-photos').on('dragover', '.new_banner, .new_profile, .org_thumbnail.banner', function(event) {
     event.preventDefault();
     event.stopPropagation();

     $(this).css({ border: '2px dotted black', backgroundColor: 'blue' });
     });

     $('#content-group-photos').on('dragleave', '.new_banner, .new_profile, .org_thumbnail.banner', function(event) {
     event.preventDefault();
     event.stopPropagation();

     $(this).css({border: '', backgroundColor: ''});
     });

     $('#content-group-photos').on('drop', '.new_banner, .new_profile, .org_thumbnail.banner', function(event) {
     event.preventDefault();
     event.stopPropagation();
     window.location.href = event.originalEvent.dataTransfer.getData('text');
     });
     */
    //END DRAGGING GENERAL IMAGES TO BANNER

    // Modal overlay OnClick()
    $(document.body).delegate('.div_modal_overlay', 'click', function() {
        closeImageViewer();
    });

    // Close Image Banner OnClick()
    $('.div_close_image_banner').on('click', function() {
        closeImageViewer();
    });

    $('cbar-photo-preview').on('navigate-forward', function(){
        var thumbnailDiv = getCurrentImageViewerThumbnailDiv();
        thumbnailDiv.next('.org_thumbnail').click();
    });

    $('cbar-photo-preview').on('navigate-back', function(){
        var thumbnailDiv = getCurrentImageViewerThumbnailDiv();
        thumbnailDiv.prev('.org_thumbnail').click();
    });

    /*function refreshImages() {
        $.ajax({
            type: 'POST',
            url: window.location.href,
            success: function(data) {
                var imagesThumbs = JSON.parse(data);
                var images = imagesThumbs.images;
                var thumbs = imagesThumbs.thumbs;
                var thumbnailElementString = '';


                // GENERAL IMAGES.
                for( var i in images.general ) {
                    var org_general_image = images.general[i];

                    var thumb_name = org_general_image.filename.replace('_general', '_thumb');

                    // Create another loop to get the file id for the thumbnail image for use in deleting an image.
                    // This isn't the most elegant solution.
                    var general_thumb_id;

                    for( var x in thumbs ) {
                        var org_thumbnail = thumbs[x];

                        if( org_thumbnail.filename === thumb_name ) {
                            general_thumb_id = org_thumbnail.id;
                            break;
                        }
                    }

                    thumbnailElementString += '<div draggable="true" name="'+ org_general_image.filename +'" class="org_thumbnail" style=\'background-image: url("' + getImageURL + thumb_name + '")\'>';
                    thumbnailElementString += '<img class="delete_image_button" src="' + deleteButtonURL + '"/>';
                    thumbnailElementString += '<a class="crop_image_button" href="' + cropActionURL + org_general_image.filename + '"><img src="' + cropButtonImageURL + '" /></a>';
                    thumbnailElementString += '<input type="checkbox" class="checkbox_thumbnail" name="checkbox_thumbnail" style="display:none;" />';
                    thumbnailElementString += '<input type="hidden" name="thumb_image_id" value="' + general_thumb_id + '" />';
                    thumbnailElementString += '<input type="hidden" name="original_image_id" value="' + org_general_image.id + '" />';
                    thumbnailElementString += '</div>';

                }

                $('.div_general_images').children('.org_thumbnail').remove();
                $('.div_general_images').append(thumbnailElementString);

                thumbnailElementString = ''; // reset the string.

                // BANNER IMAGES
                for( var i in images.banner ) {
                    var org_banner_image = images.banner[i];

                    var thumb_name = org_banner_image.filename.replace('_banner', '_thumb');

                    // Create another loop to get the file id for the thumbnail image for use in deleting an image.
                    // This isn't the most elegant solution.
                    var banner_thumb_id;

                    for( var x in thumbs ) {
                        var org_thumbnail = thumbs[x];

                        if( org_thumbnail.filename === thumb_name ) {
                            banner_thumb_id = org_thumbnail.id;
                            break;
                        }
                    }

                    thumbnailElementString += '<div name="'+ org_banner_image.filename +'" class="org_thumbnail" style=\'background-image: url("' + getImageURL + thumb_name + '")\'>';
                    thumbnailElementString += '<img class="delete_image_button" src="' + deleteButtonURL + '"/>';
                    thumbnailElementString += '<a class="crop_image_button" href="' + cropActionURL + org_banner_image.filename + '"><img src="' + cropButtonImageURL + '" /></a>';
                    thumbnailElementString += '<input type="checkbox" class="checkbox_thumbnail" name="checkbox_thumbnail" style="display:none;" />';
                    thumbnailElementString += '<input type="hidden" name="thumb_image_id" value="' + banner_thumb_id + '" />';
                    thumbnailElementString += '<input type="hidden" name="original_image_id" value="' + org_banner_image.id + '" />';
                    thumbnailElementString += '</div>';

                }

                $('.div_banner_images').children('.org_thumbnail').remove();
                $('.div_banner_images').append(thumbnailElementString);
            }

        });
    }*/
})();
