// Set up any quills on the page.
const quillElements = document.querySelectorAll('.quill');
quillElements.forEach(quillElement => {
    new Quill(quillElement, {
        modules: {
            toolbar: [
                [{header: [1, 2, false]}],
                ['bold', 'italic', 'underline'],
                ['code-block'],
            ]
        },
        placeholder: 'Message',
        theme: 'snow'
    });
});
