import {regenerateID} from "./general";
import addPerson from "./cbar-add-person.module";

$(function () {
    // Trigger when input button is checked
    $(".search_wrapper .search").on("click", ".submit", function (event) {
        inlineEntitySearch(this);
    });
    // Trigger when enter button is pressed from a search text field
    $(".search_wrapper .search").on("keyup", "input[type=textfield]", function (event) {
        if (event.keyCode === 13) {
            inlineEntitySearch(this);
        }
    });
    // Trigger when the load more button is checked
    $(".search_wrapper .meta").on("click", "a.load-more", function (event) {
        event.preventDefault();
        inlineEntitySearch(this);
    });
    // Trigger when the groups dropdown changes
    $('.profile-groups').on('change selected-item-changed', function (event) {
        event.preventDefault();
        if ($(this).val() !== "0" && (!event.detail || event.detail.value !== null)) {
            inlineEntitySearch(this);
        }
    });

    // Trigger when go to button is clicked
    // Redirect to entity page
    $(".search_wrapper .search").on("click", ".goto-button", function (event) {
        var selectedGroupID = parseInt($(".search_wrapper .search .profile-groups").val());
        if (!isNaN(selectedGroupID) && parseInt(selectedGroupID) > 0) {
            window.location.href = window.location.origin + "/groups/view/" + selectedGroupID;
        }
    });

    //this to fix the goto-button
    $(".goto-button").on("click", function (event) {
        var selectedGroupID = parseInt($(".profile-groups").val());
        if (!isNaN(selectedGroupID) && parseInt(selectedGroupID) > 0) {
            window.location.href = window.location.origin + "/groups/view/" + selectedGroupID;
        }
    });

    const $searchDiv = $("div.search");
    const filterByRole = document.querySelector('.filter_by_role');
    const filterByRoleType = document.querySelector('.filter_by_role_type');

    if (filterByRole && filterByRoleType) {
        const properties = [ "action", "source_entity", "source_entity_id", "target_entity", "target_entity_id", "relationship_id", "quota", "start_time", "end_time" ];

        let params = {};
        for( let property of properties ) {
            const $input = $(".search input." + property);

            if( $input.length > 0 ) {
                params[ property ] = $input.val();
            }else {
                params[ property ] = "";
            }
        }

        let animationFrame;

        // Handler for the selected-items-changed event.
        const selectedRolesChanged = e => {
            // Perform the filter/search.
            cancelAnimationFrame(animationFrame);
            animationFrame = requestAnimationFrame(() => {
                const filterArgs = {
                    roles: filterByRole.value.map(role => role.value),
                    role_types: filterByRoleType.value.map(role => role[filterByRoleType.valueField]),
                };

                $searchDiv.find(".filter_by_role").siblings("input.filter_args").val(JSON.stringify(filterArgs));
            });
        };

        const $participantsDiv = $('.participants.search_wrapper');

        $.ajax({
            url: "/ajax/entity-roles",
            type: "POST",
            data: params,
            success: function (data) {
                if( data.error !== false ) {
                    return;
                }

                let roleOptions = [];

                $.each(data.data, function (index, element) {
                    // Replace space with underscore
                    const value = element.display_name.replace(" ", "_").toLowerCase();

                    roleOptions.push({
                        value,
                        label: element.display_name
                    });
                });

                // Also grab all the non-quota roles
                const $allRolesInput = $participantsDiv.find('[name=all_available_roles]');

                if($allRolesInput.length > 0){
                    const allRoles = JSON.parse($allRolesInput.val());
                    addNonQuotaRoles(allRoles, roleOptions)
                }

                customElements.whenDefined('multi-select-combo-box').then(() => {
                    filterByRole.items = roleOptions;

                    // Set up the handler for items changing.
                    filterByRole.addEventListener('value-changed', selectedRolesChanged);
                    filterByRoleType.addEventListener('value-changed', selectedRolesChanged)
                });
            }
        });
    }

    // Triggered when selecting to filter by group_owner
    $searchDiv.on("change", "select.filter_group_owner", function (event) {
        event.preventDefault();
        var filterArgsJSON = $(this).parent().siblings("input.filter_args").val();
        var filterArgs;
        if (filterArgsJSON.length > 0) {
            filterArgs = JSON.parse(filterArgsJSON);
            filterArgs.group_id = $(this).val();
        } else {
            filterArgs = {"group_id": $(this).val()};
        }
        $(this).parent().siblings("input.filter_args").val(JSON.stringify(filterArgs));
        inlineEntitySearch(this);
    });

    $('paper-dropdown-menu.filter_group_owner').on('selected-item-changed', function(event){
        event.preventDefault();
        var filterArgsJSON = $(this).parent().siblings("input.filter_args").val();
        var filterArgs;
        if (filterArgsJSON.length > 0) {
            filterArgs = JSON.parse(filterArgsJSON);
            filterArgs.group_id = $(this)[0].contentElement.selected;
        } else {
            filterArgs = {"group_id": $(this)[0].contentElement.selected};
        }
        $(this).parent().siblings("input.filter_args").val(JSON.stringify(filterArgs));
    });

    // Triggered when selecting to filter by future or past
    $searchDiv.on("change", "select.filter_future_past", function (event) {
        event.preventDefault();
        $(this).parent().siblings("input.filter").val($(this).val());
    });

    $('paper-dropdown-menu.filter_future_past').on('selected-item-changed', function(event){
        event.preventDefault();

        var value = $(this)[0].contentElement.selected;

        // iron-selector calls twice, we need to check if an actual change has happened here
        if(value !== $(this).parent().siblings("input.filter").val()) {
            $(this).parent().siblings("input.filter").val(value);
        }
    });

    // Triggered on filtering group events by a date
    $searchDiv.on("change", ".filter_event_date", function (event) {
        event.preventDefault();
        var filterArgsJSON = $(this).parent().siblings("input.filter_args").val();
        var filterArgs;
        if (filterArgsJSON.length > 0) {
            filterArgs = JSON.parse(filterArgsJSON);
            filterArgs.date = $(this).val();
        } else {
            filterArgs = {"date": $(this).val()};
        }
        $(this).parent().siblings("input.filter_args").val(JSON.stringify(filterArgs));
    });


    // Triggered on filtering group events by type
    $searchDiv.on("change", "select.filter_event_type", function (event) {
        event.preventDefault();
        var filterArgsJSON = $(this).parent().siblings("input.filter_args").val();
        var filterArgs;
        if (filterArgsJSON.length > 0) {
            filterArgs = JSON.parse(filterArgsJSON);
            filterArgs.type = $(this).val();
        } else {
            filterArgs = {"type": $(this).val()};
        }
        $(this).parent().siblings("input.filter_args").val(JSON.stringify(filterArgs));
    });

    $('paper-dropdown-menu.filter_event_type').on('selected-item-changed', function(e){
        event.preventDefault();
        var filterArgsJSON = $(this).parent().siblings("input.filter_args").val();
        var filterArgs;
        if (filterArgsJSON.length > 0) {
            filterArgs = JSON.parse(filterArgsJSON);
            filterArgs.type = $(this)[0].contentElement.selected;
        } else {
            filterArgs = {"type": $(this)[0].contentElement.selected};
        }

        if(JSON.stringify(filterArgs) !== $(this).parent().siblings("input.filter_args").val()) {
            $(this).parent().siblings("input.filter_args").val(JSON.stringify(filterArgs));
        }
    });

    // Triggered on filtering by allocated/unallocated on Election Day
    $searchDiv.on("change", "input.filter_by_unallocated", function (event) {
        event.preventDefault();
        var filterVal;
        var filterArgsJSON = $(this).parent().siblings("input.filter_args").val();
        var filterArgs;
        var filterStartTime;
        var filterEndTime;

        // Set filter to "unallocated" for election day times
        if (this.checked) {
        // Default start time and end time allocation filters are election day
            filterVal = "unallocated";
            filterStartTime = "2015-03-28 08:00:00";
            filterEndTime = "2015-03-28 21:00:00";
        } else {
            filterVal = null;
            filterStartTime = null;
            filterEndTime = null;
        }
        $(this).parent().siblings("input.filter").val(filterVal);

        // Add start time and end time to filter arguments
        if (filterArgsJSON.length > 0) {
            filterArgs = JSON.parse(filterArgsJSON);
        } else {
            filterArgs = {};
        }
        filterArgs.start_time = filterStartTime;
        filterArgs.end_time = filterEndTime;
        $(this).parent().siblings("input.filter_args").val(JSON.stringify(filterArgs));

        inlineEntitySearch(this);
    });

    function getCurrentlyDisplayedProfileIds(entityList) {
        const $summaries = entityList.find('.person_summary');

        const profileIds = [];
        $summaries.each((i, summary) => {
            profileIds.push(summary.getAttribute('profile-id'));
        });

        return profileIds;
    }

    var inlineEntitySearch = function (triggeredElement) {
        var searchWrapper = $(triggeredElement).closest(".search_wrapper");
        var profile_id = typeof (searchWrapper.find(".profile_id").val()) !== 'undefined' ? searchWrapper.find(".profile_id").val() : false;
        var event_id = typeof (searchWrapper.find(".event_id").val()) !== 'undefined' ? searchWrapper.find(".event_id").val() : false;
        var group_id = typeof (searchWrapper.find(".group_id").val()) !== 'undefined' ? searchWrapper.find(".group_id").val() : false;
        var filter = typeof (searchWrapper.find(".filter").val()) !== 'undefined' ? searchWrapper.find(".filter").val() : false;
        var filter_args = typeof (searchWrapper.find(".filter_args").val()) !== 'undefined' ? searchWrapper.find(".filter_args").val() : false;
        var entity = searchWrapper.find(".entity").val();
        var entityName;
        var entityNamePlural;
        var search_value;
        var page;
        var page_size;
        var mode;


        // lets add a check here for paper-dropdown-menu
        if(isNaN(group_id)){
            var groupElement = searchWrapper.find("paper-dropdown-menu.group_id")[0];
            if(groupElement){
                group_id = groupElement.contentElement.selected;
            }
        }

        if ($(triggeredElement).hasClass("load-more")) {
            page = parseInt($(triggeredElement).attr("data-page")) + 1;
            page_size = parseInt($(triggeredElement).attr("data-page-size"));
            search_value = $(triggeredElement).attr("data-search-term");
            filter = $(triggeredElement).attr("data-filter");
            mode = "loadmore";
        } else {
            page = 0;
            page_size = searchWrapper.find(".page_size").val();

            if (typeof (page_size) === "undefined") {
                // Set default page sizes to 10 in dashboard, 250 everywhere else
                //page_size = $("body").is("#dashboard") ? 10 : 250;
                page_size = $("body").is("#dashboard") ? 10 : 10;
            }

            search_value = typeof (searchWrapper.find(".search_value, .inline-search").val()) !== 'undefined' ? searchWrapper.find(".search_value, .inline-search").val() : '';
            mode = "search";
        }

        switch (entity) {
            case 'profile':
                entityName = 'person';
                entityNamePlural = 'people';
                break;
            case 'group':
                entityName = 'group';
                entityNamePlural = 'groups';
                break;
            case 'event':
                entityName = 'event';
                entityNamePlural = 'events';
                break;
            case 'event-with-roles':
                entityName = 'event-with-roles';
                entityNamePlural = 'events';
                break;
            case 'event-summary':
                entityName = 'event-summary';
                entityNamePlural = 'events';
                break;
            case 'event-summary-tabulated':
                entityName = 'event-summary-tabulated';
                entityNamePlural = 'events';
                break;
            default:
                entityName = 'person';
                entityNamePlural = 'people';
        }

        //FIXME: nothing found on events list
        var entityList = searchWrapper.find("." + entityNamePlural + "-list, ." + entityNamePlural + "-table tbody");

        // Clear the previous search results if we're starting from page 0.
        // NOTE: Make sure we do this before we retrieve the "blueprint" DOM element, as this
        // will ensure that we are not traversing the entire entityList (with the previous search
        // results still populated), but rather the blank entityList, making appending our AJAX results
        // MUCH faster.
        if (page === 0) {
            if (entityName === "event-summary" || entityName === "event-with-roles") {
                entityList.find(".event_summary").not(".control").remove();
            } else if (entityName === "event-summary-tabulated") {
                entityList.find(".event").not(".control, .event_headings").remove();
            } else {
                entityList.find("." + entityName + "_summary").not(".control").remove();
            }
        }

        // Load up the blueprint object now that we've removed possible previous search results.
        var blueprint = entityList.find("div.ajax-blueprint, li.ajax-blueprint, tr.ajax-blueprint, paper-card.ajax-blueprint");
        var loadingSpinner = searchWrapper.find(".loading");
        $(".icon-refresh").css('display', 'block');
        loadingSpinner.fadeIn("slow");

        var metaControl = searchWrapper.find(".control.meta");
        metaControl.hide();


        // Construct the post array that we are sending
        var postData = {
            search_value: search_value,
            entity: entity,
            page: page,
            page_size: page_size,
            filter: filter,
            filter_args: filter_args
        };

        //at this point we update all load more link to have the same data type
        $("a.load-more").attr("data-filter", filter);
        $("a.load-more").attr("data-page-size", page_size);

        // Add the event ID if it exists (important for
        if (profile_id) {
            postData.profile_id = profile_id;
        }

        if (event_id) {
            postData.event = event_id;
        }

        if (group_id) {
            postData.group = group_id;
        }

        var entitiesWithImages = {
            "profile": true,
            "profileless": true,
            "event": true,
            "event-with-roles": true
        };

        $.ajax({
            url: '/ajax/entity-search',
            type: 'POST',
            data: postData,
            success: function (data) {
                if (typeof (data.results) !== 'undefined') {
                    // =========================================================
                    // BEGIN : Populate DOM by looping through resulting data.
                    // =========================================================

                    var newEntity;

                    const $actionsForm = document.querySelector('#result_actions_form');
                    let actionsProfilesInput;

                    if ($actionsForm) {
                        actionsProfilesInput = $actionsForm.querySelector('input[name=profiles]');
                    }

                    if (entityName === 'person') {
                        // Reset the profiles that are actioned by the actions
                        // form (i.e. who we're sending emails to)
                        if (actionsProfilesInput) {
                            actionsProfilesInput.value = '';
                        }
                    }

                    var totalTarget = 0;
                    var totalActual = 0;

                    $.each(data.results, function (index, thisResultItem) {
                        // Clone ajax-blueprint DOM object and add to the end of the list, removing the class
                        newEntity = blueprint.clone(true, true)
                                .removeClass("ajax-blueprint")
                                .removeClass("control");


                        // We need to regenerate the ID of the DOM element to make the DateTimePicker work
                        // #6047 - this is now obsolete since we only use one operations form
                        // per page, instead of individual forms per profile.

                        var $startTime = newEntity.find("input.start_time");
                        var $endTime = newEntity.find("input.end_time");

                        regenerateID($startTime);
                        regenerateID($endTime);

                        if($endTime[0]) {
                            $endTime[0].dataset.startElementSelector = "#" + $startTime.attr('id');
                        }

                        // Find the roles blueprint and have it ready for adding later
                        var profileBlueprint = newEntity.find(
                            ".person_roles .existing_roles .ajax-blueprint," +
                            ".person_groups .existing_roles .ajax-blueprint"
                        );

                        var $entityNameDiv = newEntity.find("." + entityName + "_name");
                        var $entityNameLink = $entityNameDiv.find("a");

                        // Find the image div if our entity is an entity that has an image.
                        if( entitiesWithImages[ entity ] === true ) {
                            var $entityImageDiv = newEntity.find("." + entityName + "_image");
                        }

                        // Update all the important info on the new group
                        if (entity === "profile" || entity === "profileless") {
                            newEntity.attr("profile-id", thisResultItem.ID);

                            $entityNameLink.text(thisResultItem.FirstName + ' ' + thisResultItem.LastName);
                            $entityNameLink.attr('href', $entityNameLink.attr('href') + thisResultItem.ID);

                            var personal_message = "Hi "+thisResultItem.FirstName+", &#13;&#10;Please accept my invite to "+$('#thing_name').val()+".";
                            newEntity.find("textarea[name=personal_message]").html(personal_message);

                            // Only display the profile fields that exist
                            if (thisResultItem.Image !== null && thisResultItem.Image.length > 0) {
                                 $entityImageDiv.find("img").attr("src", thisResultItem.Image);
                            } else {
                                $entityImageDiv.remove();
                            }

                            if (thisResultItem.Email !== null && thisResultItem.Email.length > 0) {
                                newEntity.find(".person_email").text(thisResultItem.Email);
                                newEntity.find("input[name=invitation_email]").val(thisResultItem.Email);
                            }
                            if (thisResultItem.Mobile !== null && thisResultItem.Mobile.length > 0) {
                                newEntity.find(".person_phone").text(thisResultItem.Mobile);
                            }

                            // If roles exist, update those on the new profile
                            if (typeof (thisResultItem.roles) !== 'undefined') {
                                $.each(thisResultItem.roles, function (index, item) {
                                    if (entity !== "profileless") {
                                        var newRole = profileBlueprint.clone(true, true)
                                            .removeClass("ajax-blueprint")
                                            .removeClass("control");

                                        // If this role is read only, the inline element is a <span>.
                                        // Otherwise, it is an <a> element.
                                        var newRoleInlineElement = newRole.find("a, span.role_read_only");

                                        newRoleInlineElement.html(item.label);
                                        newRoleInlineElement.attr("data-relationship", index);
                                        newRoleInlineElement.attr("data-start-time", item.start_time_datetimepicker);
                                        newRoleInlineElement.attr("data-end-time", item.end_time_datetimepicker);
                                        newRoleInlineElement.attr("data-quota", item.quota);
                                        newRoleInlineElement.attr("data-quota-start-time", item.quota_start_time_datetimepicker);
                                        newRoleInlineElement.attr("data-quota-end-time", item.quota_end_time_datetimepicker);

                                        newRoleInlineElement.attr("data-first-name", thisResultItem.FirstName);
                                        newRoleInlineElement.attr("data-last-name", thisResultItem.LastName);

                                        // Set or unset styling for role if it is not active
                                        // e.g. if ".role_invited" or ".role_declined".
                                        newRoleInlineElement.removeClass("role_declined role_invited");
                                        if (typeof item.status_id === "undefined" || parseInt(item.status_id) === 1 || typeof item.status_title === "undefined") {
                                            newRoleInlineElement.attr('data-status', 1);
                                        } else {
                                            newRoleInlineElement.attr('data-status', parseInt(item.status_id));
                                            newRoleInlineElement.addClass("role_" + item.status_title.toLowerCase());
                                        }

                                        newRole.appendTo(newEntity.find(".person_roles").find(".existing_roles"));
                                    }
                                });
                            }

                            // If upcoming events exist, append them to the people page
                            if (typeof (thisResultItem.upcoming_events) !== 'undefined' && entity === "profileless") {
                                var upcomingEventsDiv = newEntity.find(".upcoming_events");
                                var thisEventDiv;
                                var thisEventLink;
                                $.each(thisResultItem.upcoming_events, function (index, item) {
                                    thisEventDiv = $("<div>", {
                                        class: "upcoming_event",
                                        style: "margin: 0.25em 0;"
                                    });
                                    thisEventLink = $("<a>", {
                                        href: "/events/view/" + item.EventID,
                                        style: "font-weight: bold;",
                                        text: item.EventName + " - " + item.RelationshipTo
                                    });
                                    thisEventLink.appendTo(thisEventDiv);
                                    thisEventDiv.appendTo(upcomingEventsDiv);
                                });
                            }
                        } else if (entityName === "group") {
                            $entityNameLink.text(thisResultItem.GroupName);
                            $entityNameLink.attr('href', $entityNameLink.attr('href') + thisResultItem.ID);
                            newEntity.find("." + entityName + "_type").text(thisResultItem.Type);

                        } else if (entityName === "event") {
                            var $imageIframe;

                            $entityNameLink.text(thisResultItem.EventName);
                            $entityNameLink.attr('href', $entityNameLink.attr('href') + thisResultItem.ID);
                            $entityNameLink.attr('event_type', thisResultItem.type);
                            if (typeof (thisResultItem.Image) !== 'undefined' && thisResultItem.Image.length > 0) {
                                $entityImageDiv.find("img").attr("src", thisResultItem.Image);
                            } else if (typeof (thisResultItem.Location) !== 'undefined' && thisResultItem.Location.length > 0 && ($imageIframe = newEntity.find("." + entityName + "_image").find("iframe")).length > 0) {
                                var newEventIframeSource = $imageIframe.attr("data-src") + thisResultItem.Location;
                                $imageIframe.attr("src", newEventIframeSource);
                            } else {
                                $entityImageDiv.remove();
                            }

                            newEntity.find("." + entityName + "_location span").text(thisResultItem.Description);
                            newEntity.find("." + entityName + "_date span").text(thisResultItem.StartTime_display);
                            if (thisResultItem.has_target) {
                                newEntity.find("." + entityName + "_traffic").addClass(thisResultItem.traffic_colour + "-traffic-light");
                            }
                            newEntity.find(".actions button").attr({
                                'data-event-id': thisResultItem.ID,
                                'data-start-time': thisResultItem.StartTime,
                                'data-end-time': thisResultItem.EndTime
                            });

                        } else if (entityName === "event-with-roles") {
                            // Temp rename entity name for appending individual elements to the DOM
                            var entityNameBak = entityName;
                            var $imageIframe;

                            entityName = "event";

                            newEntity.attr("event-id", thisResultItem.ID);

                            $entityNameLink.text(thisResultItem.EventName);
                            $entityNameLink.attr('href', $entityNameLink.attr('href') + thisResultItem.ID);
                            $entityNameLink.attr('event_type', thisResultItem.type);
                            if (typeof (thisResultItem.Image) !== 'undefined' && thisResultItem.Image.length > 0) {
                                $entityImageDiv.find("img").attr("src", thisResultItem.Image);
                            } else if (typeof (thisResultItem.Location) !== 'undefined' && thisResultItem.Location.length > 0 && ($imageIframe = newEntity.find("." + entityName + "_image").find("iframe")).length > 0) {
                                var newEventIframeSource = $imageIframe.attr("data-src") + thisResultItem.Location;
                                $imageIframe.attr("src", newEventIframeSource);
                            } else {
                                $entityImageDiv.remove();
                            }

                            newEntity.find("." + entityName + "_location").find("span").text(thisResultItem.Description);
                            newEntity.find("." + entityName + "_date").find("span").text(thisResultItem.StartTime_display);
                            newEntity.find(".actions").find("button").attr({
                                'data-event-id': thisResultItem.ID,
                                'data-start-time': thisResultItem.StartTime,
                                'data-end-time': thisResultItem.EndTime
                            });

                            var newEntityRoleList = newEntity.find(".person_roles");
                            var newEntityRole = newEntityRoleList.find(".display.gap-top");
                            newEntityRole.detach();

                            $.each(thisResultItem.roles, function (profileRelationshipId, thisEventRole) {
                                var $newEntityRoleLink = newEntityRole.find("a");
                                $newEntityRoleLink.attr('data-relationship', profileRelationshipId);
                                $newEntityRoleLink.attr('data-start-time', thisEventRole.start_time_datetimepicker);
                                $newEntityRoleLink.attr('data-end-time', thisEventRole.end_time_datetimepicker);
                                $newEntityRoleLink.attr('data-quota', thisEventRole.quota);
                                $newEntityRoleLink.attr('data-quota-start-time', thisEventRole.quota_start_time_datetimepicker);
                                $newEntityRoleLink.attr('data-quota-end-time', thisEventRole.quota_end_time_datetimepicker);
                                $newEntityRoleLink.html(thisEventRole.label);

                                // Set or unset styling for role if it is not active
                                // e.g. if ".role_invited" or ".role_declined".
                                $newEntityRoleLink.removeClass("role_declined role_invited");
                                if (typeof thisEventRole.status_id === "undefined" || parseInt(thisEventRole.status_id) === 1 || typeof thisEventRole.status_title === "undefined") {
                                    $newEntityRoleLink.attr('data-status', 1);
                                } else {
                                    $newEntityRoleLink.attr('data-status', parseInt(thisEventRole.status_id));
                                    $newEntityRoleLink.addClass("role_" + thisEventRole.status_title.toLowerCase());
                                }

                                newEntityRole.clone().appendTo(newEntityRoleList);
                            });

                            // Reset entity name for the next iteration of thisResultItem
                            entityName = entityNameBak;
                        } else if (entityName === "event-summary") {
                            // Temp rename entity name for appending individual elements to the DOM
                            var entityNameBak = entityName;
                            entityName = "event";
                            var $eventLink = newEntity.find(".event-link");

                            newEntity.find(".current-event-id").text(thisResultItem.ID);
                            $eventLink.attr("href", "/events/view/" + thisResultItem.ID);
                            $eventLink.text(thisResultItem.event_name);
                            $eventLink.closest('span').after("<br/><strong style='padding-left: 2px'>" + thisResultItem.description + "</strong><br>");
                            newEntity.find(".compose-email-link").attr("href", "/messages/compose/" + thisResultItem.ID);
                            newEntity.find(".print-roster-link").attr("href", "/print_roster?id=" + thisResultItem.ID);
                            newEntity.find(".event-date").text(thisResultItem.formatted_date_range);
                            newEntity.find(".event-calendar").attr("href", "/event_ics?id=" + thisResultItem.ID);
                            var locationHtml = thisResultItem.location;
                            newEntity.find(".event-location").html(locationHtml);
                            var groupOwners = [];

                            $.each(thisResultItem.group_owners, function (index, group) {
                                groupOwners.push(`<cbar-entity-title title="${group.title}" image="${group.image ?  group.image : ''}" url="/groups/view?id=${group.ID}" size="medium"></cbar-entity-title>`);
                            });

                            var groupOwnersHtml = groupOwners.join(", ");

                            newEntity.find(".event-groups").html(groupOwnersHtml);

                            var adminsHtml = "";
                            $.each(thisResultItem.admins, function (index, admin) {
                                adminsHtml += "<tr>";
                                adminsHtml += "<td class='admin-role'>" + admin.admin_role_label + ":</td>";
                                adminsHtml += "<td class='admin-person'><a href='/profiles/view/" + admin.admin_profile_id + "'>" + admin.admin_name + "</a>";
                                if (admin.admin_mobile && admin.admin_mobile.length > 0) {
                                    adminsHtml += " (" + admin.admin_mobile + ")";
                                }
                                adminsHtml += "</td>";
                                adminsHtml += "</tr>";
                            });
                            newEntity.find(".admins").html(adminsHtml);

                            // Only show est attendance if it is set.
                            if (thisResultItem.stats.est_attendance > 0) {
                                newEntity.find(".event-est-attendance").html(thisResultItem.stats.est_attendance);
                            } else {
                                newEntity.find(".event-est-attendance").parent().hide();
                            }

                            newEntity.find(".event-volunteer-count").html(thisResultItem.stats.participant_count);
                            newEntity.find(".event-target").html(thisResultItem.stats.actual_target + " (" +thisResultItem.stats.target + "hrs)");
                            newEntity.find(".event-actual").html(thisResultItem.stats.actual_people + " (" +thisResultItem.stats.actual_display + " hrs)");
                            newEntity.find(".capacity-label").addClass(thisResultItem.stats.traffic_colour + "-traffic");

                            var capacityTd = newEntity.find(".event-capacity");
                            var capacityTdSiblings = capacityTd.siblings();

                            capacityTd.addClass(thisResultItem.stats.traffic_colour + "-traffic")
                                .html(thisResultItem.stats.capacity_display);

                            capacityTdSiblings.addClass(thisResultItem.stats.traffic_colour + "-traffic");

                            capacityTdSiblings.find("a")
                                .addClass(thisResultItem.stats.traffic_colour + "-traffic");

                            // Reset entity name for the next iteration of thisResultItem
                            entityName = entityNameBak;
                        } else if (entityName === "event-summary-tabulated") {
                            // Temp rename entity name for appending individual elements to the DOM

                            var entityNameBak = entityName;
                            entityName = "event";

                            // Fill the table row blueprint e.g. in Group Event Administration tab
                            newEntity.find(".event_name").find("a")
                                .attr("href", "/events/view/" + thisResultItem.ID)
                                .text(thisResultItem.event_name);
                            newEntity.find(".event_start_time")
                                .text(thisResultItem.start_time);
                            newEntity.find(".event_target")
                                .text(thisResultItem.stats.actual_target + " (" +thisResultItem.stats.target + "hrs)");
                            newEntity.find(".event_actual_fte")
                                .text(thisResultItem.stats.actual_people + " (" +thisResultItem.stats.actual_display + " hrs)");
                            newEntity.find(".event_capped_capacity")
                                .text(thisResultItem.stats.capacity_display + "%");
                                newEntity.find(".event_roster").find("a")
                                .attr("href", "print_roster?id=" + thisResultItem.ID)
                                .attr("target", "_blank")
                                .text("Print Roster");

                            totalActual += parseInt(thisResultItem.stats.actual_people);
                            totalTarget += parseInt(thisResultItem.stats.actual_target);

                            // Reset entity name for the next iteration of thisResultItem
                            entityName = entityNameBak;
                        }

                        newEntity.find(".operation-form input.source_entity_id").val(thisResultItem.ID);
                        // Add the cloned object to the end of the list
                        newEntity.appendTo(entityList);

                        // Update the value of the input that controls the profiles that will
                        // be actioned when an action is picked from the actions form
                        if (entityName === 'person') {
                            if (actionsProfilesInput) {
                                const profileIds = getCurrentlyDisplayedProfileIds(entityList);

                                actionsProfilesInput.value = JSON.stringify(profileIds);
                            }
                        }

                        if(newEntity[0]) {
                            var newEntityButton = $(newEntity[0]).find('paper-menu-button paper-listbox');
                            newEntityButton.attr("data-first-name", thisResultItem.FirstName);
                            newEntityButton.attr("data-last-name", thisResultItem.LastName);
                        }
                    });

                    // =========================================================
                    // END : Populate DOM by looping through resulting data.
                    // =========================================================

                    // When displaying tabulated event summary data, after looping through results,
                    // add aggregated stats table row and the print all rosters link.

                    if (entityName === "event-summary-tabulated" && typeof data.totals !== "undefined") {


                        // Clone ajax-blueprint DOM object and fill it with aggregated statistics.
                        newEntity = blueprint.clone(true, true)
                            .attr("class", "event event_totals")
                            .css("font-weight", "bold");
                        newEntity.find(".event_name").text(data.control.count + " Events");
                        newEntity.find(".event_target").text(totalTarget + " (" + data.totals.target + " Hrs)");
                        newEntity.find(".event_actual_fte").text(totalActual + " (" + data.totals.actual_fte_display + " Hrs)");
                        newEntity.find(".event_capped_capacity").text(data.totals.capped_capacity_display + "%");

                        // Add the aggregated stats row to the end of the table.
                        newEntity.appendTo(entityList);

                        // Update the print all rosters link based on current
                        // group event search filters.
                        var printAllRostersHref = "/events/printrosters?group_id=" + group_id +
                            "&search_string=" + search_value +
                            "&filter=" + filter +
                            "&filter_args=" + filter_args +
                            "&page=" + page +
                            "&page_size=" + page_size;
                        $(".print_all_rosters").attr("href", printAllRostersHref);
                    }

                    // Hide and reset the meta control box
                    metaControl.find("span").show();
                    // Populate and show the meta control box, updating existing where relevant
                    metaControl.find("span.total_count").text(data.control.total_count);
                    metaControl.find("span.entity").text(data.control.entity_plural);
                    if (mode !== "loadmore") {
                        // Don't update this in "loadmore" mode, as this process is additive
                        metaControl.find("span.start_item").text(data.control.start_item);
                    }
                    metaControl.find("span.end_item").text(data.control.end_item);
                    metaControl.find("a.load-more").attr('data-page', data.control.page);
                    metaControl.find("a.load-more").attr('data-search-term', data.control.search_value);

                    // Some logic to dictate what happens to the meta controls
                    if (data.control.end_item === data.control.total_count) {
                        metaControl.find("span.more").hide();
                    }
                    if (data.control.count === data.control.total_count) {
                        metaControl.find("span.pagination").hide();
                    }
                    metaControl.show();

                    $(searchWrapper).find("paper-input.search_value, paper-button.submit").show();
                }

                loadingSpinner.fadeOut("slow");
                addPerson.updateUserCount();
            }
        });
    };

    // Trigger on page load or page refresh
    if ($(".profile-groups").length > 0 && $(".profile-groups").first().val() !== "0") {
        inlineEntitySearch($(".profile-groups").first());
    }

    function addNonQuotaRoles(allRoles, roleOptions){
        for (const role of allRoles) {
            const existingRole = roleOptions.find(r => r.label === role);
            if (!existingRole) {
                // This is a non-quota role, add it to our list
                roleOptions.push({
                    value: role,
                    label: role.toLowerCase(),
                });
            }
        }
    }


});
