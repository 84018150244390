// Only do this if we're on the dashboard.
if($("body").is('#dashboard')){
    // Load our RSS feed.
    var $rssFeed = $("#main-content").find(".rss_feed");
    var smsForm = document.querySelector('#send_sms_form');
    var emailForm = document.querySelector('#send_email_form');

    [smsForm, emailForm].forEach(function(form){
        if(!form){
            return;
        }

        var dropdown = form.querySelector('cbar-select2');
        var submitButton = form.querySelector('paper-button[name="submit"]');
        var invalidSection = dropdown.nextElementSibling;

        dropdown.addEventListener('change', function (e) {
            validate(dropdown, invalidSection);
        });

        submitButton.addEventListener('click', function(e){
            submitWidgetForm(form, dropdown, invalidSection)
        })
    })

    if( $rssFeed.length > 0 ) {
        var rssTopColours = ["rss_navy", "rss_cyan", "rss_magenta", "rss_green", "rss_yellow"];

        $.ajax({
            url: "/dashboard/ajaxgetrssfeed",
            dataType: "json",
            success: function(data) {
                if( data.results !== undefined && data.results.length > 0 ) {
                    var rssFeedHTML = "";

                    for( var i = 0; i < data.results.length; i++ ) {
                        rssFeedHTML += getRSSFeedHTML(data.results[ i ].title, data.results[ i ].description, rssTopColours[ i % 5 ]);
                    }

                    $rssFeed.html(rssFeedHTML);
                    $rssFeed.removeClass("widget-loading");
                }else {
                    $rssFeed.remove();
                }
            },
            error: function() {
                $rssFeed.remove();
            }
        });
    }

    // Load the pre-poll event stats widget.
    var $prepollStatsDiv = $("#prepoll-stats");
    if( $prepollStatsDiv.length > 0 ) {
        var $prepollStatsSpinner = $prepollStatsDiv.find(".icon-spinner");
        var $prepollStatsImg = $prepollStatsDiv.find(".widget-image");

        // JQuery tooltip the loading spinner and the widget image.
        $prepollStatsSpinner.tooltip();
        $prepollStatsImg.tooltip();

        $.ajax({
            url: "/dashboard/ajaxgetprepollstats",
            dataType: "json",
            success: function(data) {
                // Set the title.
                $prepollStatsDiv.find(".widget-title").text(data.title);

                // Set the "filled" field.
                $prepollStatsDiv.find(".widget-filled").text(data.filled_string);
                // Set the "remaining" field.
                $prepollStatsDiv.find(".widget-remaining").text(data.remaining_string);

                // Set the image src.
                $prepollStatsImg.attr("src", data.image_url);
                $prepollStatsImg.attr("title", "Real-time data");
            },
            complete: function() {
                // Remove the loading spinner.
                $prepollStatsSpinner.remove();

                // Remove the loading class.
                $prepollStatsDiv.find(".widget-loading").removeClass("widget-loading");
            },
            error: function() {
                $prepollStatsDiv.remove();
            }
        });
    }

    // Load the election day stats widget.
    var $electionDayStatsDiv = $("#election-day-stats");
    if( $electionDayStatsDiv.length > 0 ) {
        var $electionDayStatsSpinner = $electionDayStatsDiv.find(".icon-spinner");
        var $electionDayStatsImg = $electionDayStatsDiv.find(".widget-image");

        // JQuery tooltip the loading spinner and the widget image.
        $electionDayStatsSpinner.tooltip();
        $electionDayStatsImg.tooltip();

        $.ajax({
            url: "/dashboard/ajaxgetelectiondaystats",
            dataType: "json",
            success: function(data) {
                // Set the title.
                $electionDayStatsDiv.find(".widget-title").text(data.title);

                // Set the "filled" field.
                $electionDayStatsDiv.find(".widget-filled").text(data.filled_string);
                // Set the "remaining" field.
                $electionDayStatsDiv.find(".widget-remaining").text(data.remaining_string);

                // Set the image src.
                $electionDayStatsImg.attr("src", data.image_url);
                $electionDayStatsImg.attr("title", "Real-time data");
            },
            complete: function() {
                // Remove the loading spinner.
                $electionDayStatsSpinner.remove();

                // Remove the loading class.
                $electionDayStatsDiv.find(".widget-loading").removeClass("widget-loading");
            },
            error: function() {
                $electionDayStatsDiv.remove();
            }
        });
    }
}

function getRSSFeedHTML(title, description, colourClass) {
    var retStr = '<div class="one fifth">';
    retStr +=        '<div class="rss_item gap-left gap-right padded ' + colourClass + '">';
    retStr +=            '<div class="rss_title">' + title + '</div>';
    retStr +=            '<div class="rss_description small">' + description + '</div>';
    retStr +=        '</div>';
    retStr +=    '</div>';

    return retStr;
}

function submitWidgetForm(form, dropdown, invalidSection){
    if(!validate(dropdown, invalidSection)){
        return;
    }

    form.submit();
}

function validate(dropdown, invalidSection){
    var isValid = true;

    if(!dropdown.value || dropdown.value === "" || dropdown.value === "0"){
        dropdown.classList.add('invalid-dropdown');
        invalidSection.removeAttribute('hidden');
        isValid = false;
    } else {
        dropdown.classList.remove('invalid-dropdown');
        invalidSection.setAttribute('hidden', 'true');
    }

    return isValid;
}
