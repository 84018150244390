import {postToURL} from "./general";

class Header {
    /**
     * Retrieve the cbar-header element
     *
     * @returns {Element | *}
     */
    get element() {
        if( !this.__header ) {
            this.__header = document.querySelector('#cbar-header');
        }

        return this.__header;
    }

    /**
     * Set up search and logout listeners for the header
     *
     * @returns {boolean}
     */
    setupListeners() {
        if( !this.element ) {
            return false;
        }

        this.element.addEventListener('search-submitted', e => {
            // Listen for the search-submitted event on our header's search bar.
            let searchString = e.detail;

            postToURL('/search', {
                search: searchString,
            });
        });

        this.element.addEventListener('logout', () => {
            // Clear LocalStorage on logout
            localStorage.clear();
        });

        return true;
    }

    /**
     * Returns a promise that resolves when the cbar-header
     * is defined in the custom element registry
     *
     * @returns {PromiseLike<void>}
     */
    whenDefined() {
        return customElements.whenDefined('cbar-header');
    }
}

export let header = new Header();
