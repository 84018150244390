import {postToURL} from "./general";

$(function () {
    $("#website_applicants_shortcut").on('click', function() {
        var group_participation = [{
            id: "AllGroups",
            label: "All Groups",
            role_id: "",
            role_name: "Website Applicant",
            start_time: "",
            end_time: ""
        }];

        postToURL("/search/advanced", {
            entity_selection: "group_participation",
            group_participation: JSON.stringify(group_participation),
            submit: "Submit Query"
        });
    });
});
