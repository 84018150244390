$(function () {
	
	var addButtonURL = '/images/gallery/add.png';
	
    $('.link_add_file').on('click', function() {
    	$("#form_upload_file2").find("#hidden_file_category").val('file');
    	$("#form_upload_file2").find("#hidden_file_attribute").val('general');
    });

    $("#file_upload #image_upload").on('change', function() {
        // Change add button to ajax loading bar
        var link_add_file_name = $('#hidden_file_attribute').val();
        var link_selector = '.link_add_file[name=' + link_add_file_name + ']';
        //$(link_selector).children('img').attr('src', ajaxBarURL);

        $("#form_upload_file2").submit().trigger('reset');
    });

    $("#iframe_file_upload2").on('load', function() {
        // revert add file button to a +
        var link_add_file_name = $('#hidden_file_attribute').val();
        var link_selector = '.link_add_file[name=' + link_add_file_name + ']';
        $(link_selector).children('img').attr('src', addButtonURL);

        var iframe_body = $(window.iframe_file_upload.document.body);

        var dimension_error = iframe_body.children('.dimension_error');
        if( dimension_error.length !== 0 ) {
            alert('One or more of the images you uploaded failed. Ensure they are at least 1024x100 if they are banner images.');
        }

        var banner_error = iframe_body.children('.banner_error');

        if( banner_error.length !== 0 ) {
            alert("You already have a banner image uploaded. Please first delete it to upload a new one.");
            return;
        }

        var new_images = iframe_body.children('.new_image_wrapper');

        console.log(new_images);
        
        /*
        for( i=0; i<new_images.length; i++ ) {
            var new_image = $(new_images[i]);

            var thumb_id = new_image.children('.thumb_id').html();
            if( undefined === thumb_id ) {
                continue;
            }

            var thumb_file_name = new_image.children('.thumb_file_name').html();
            var image_id = new_image.children('.image_id').html();
            var original_image_name = new_image.children('.original_file_name').html();

            var parent_div_className = $("#hidden_file_attribute").val() === 'general' ? 'div_general_images' : 'div_banner_images';

            addThumbnailToDOM(thumb_id, thumb_file_name, image_id, original_image_name, parent_div_className);
        }*/
    });
});