import * as localforage from "localforage";

const database = localforage.createInstance({
  name: "CC-STORAGE"
});

type RequestOptions = {
  cacheKey: string;
  url: string;
  limit: number;
  expirySeconds: number;
  onResponse: (response: Result) => any;
}

type Result = {
  data: any;
  metadata: Metadata;
}

type Metadata = {
  previousOffset: number;
  expiresAt: number;
  isComplete: boolean;
}

const getMetadataKey = (cacheKey: string) =>
    `${cacheKey}-metadata`;

const getMetadata = (cacheKey: string): Promise<Metadata | null> => {
  const metadataKey = getMetadataKey(cacheKey);

  return database.getItem<Metadata>(metadataKey);
};

const getCachedData = (cacheKey: string) => {
  return database.getItem<any>(cacheKey)
};

const isCacheExpired = (metadata: Metadata) =>
    Date.now() >= metadata.expiresAt;

const deleteCache = async (cacheKey: string) => {
  await database.removeItem(cacheKey);

  // Also remove metadata
  const metadataKey = getMetadataKey(cacheKey);
  await database.removeItem(metadataKey);
};

const storeMetadata = async (cacheKey: string, offset: number, expirySeconds: number, isComplete): Promise<Metadata> => {
  const metadata = {
    previousOffset: offset,
    expiresAt: Date.now() + (expirySeconds * 1000),
    isComplete,
  };

  const metadataKey = getMetadataKey(cacheKey);
  await database.setItem(metadataKey, metadata);

  return metadata;
};

export const startMakingRequests = async ({url, cacheKey, limit, expirySeconds, onResponse}: RequestOptions) => {
  let offset = 0;

  const existingMetadata = await getMetadata(cacheKey);
  if (existingMetadata) {
    if (isCacheExpired(existingMetadata)) {
      await deleteCache(cacheKey);
    }else {
      // We have a valid cache
      if (existingMetadata.isComplete) {
        // Our data is complete, simply return that.
        onResponse({
          data: await getCachedData(cacheKey),
          metadata: existingMetadata
        });

        return;
      }

      // Continue from where we left off.
      offset = existingMetadata.previousOffset + limit;
    }
  }else {
    // No metadata, clear any zombie data
    await deleteCache(cacheKey);
  }

  const _makeRequest = async (offset) => {
    const response = await fetch(`${url}?offset=${offset}&limit=${limit}`);

    if (response.status === 204) {
      // No more results, mark as complete in metadata
      await storeMetadata(cacheKey, offset, expirySeconds, true);

      return;
    }

    let result = await response.json();
    let concatenatedResult = result;

    // Store into cache
    const existingData = await getCachedData(cacheKey);
    if (existingData) {
      concatenatedResult = [...existingData, ...result];
    }

    await database.setItem(cacheKey, concatenatedResult);

    // Store metadata
    const metadata = await storeMetadata(cacheKey, offset, expirySeconds, false);

    onResponse({data: result, metadata});

    await _makeRequest(offset + limit);
  };

  try {
    await _makeRequest(offset);
  } catch (e) {
    // On error, remove from storage.
    console.error("Error making chunked request: ", e.message);

    await deleteCache(cacheKey);
  }
};
