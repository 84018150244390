/**
 * Created by Dejan on 28/06/2016.
 */
import ccPHPVars from './phpconstants';
import {getUrlForRoute} from "./route";

var $contentNotes = $("#content-notes");
var notesRoute = ccPHPVars.NOTES_URL;

(() => {
    if ($contentNotes.length === 0) {
        return;
    }

    var $addNoteForm = $("#AddNoteForm"),
        $entityType = $addNoteForm.find("[name=entity_type]"),
        $entityId = $addNoteForm.find("[name=entity_id]"),
        $note = $addNoteForm.find("#Note"),
        $saveNoteButton = $addNoteForm.find("[name=SaveNote]"),
        $textGroup = $addNoteForm.find('.text-group'),
        $noteFileInput = $addNoteForm.find('.attachment'),
        $noteActionUpload = $addNoteForm.find('.action-container.upload-attachment');

    function resetFileInput($fileInput) {
        // Wrap the file input element in a form, call the reset function
        // on the form, then unwrap.
        $fileInput.wrap('<form>').closest('form').get(0).reset();
        $fileInput.unwrap();

        $fileInput.trigger('change');
    }

    $addNoteForm.on('iron-form-presubmit', function () {
        if ($entityType.length > 0) {
            var formData = new FormData();
            var files = $noteFileInput.prop('files');

            if (files.length === 1) {
                formData.append('file', files[ 0 ]);
            }else {
                // If we have no files AND no note, don't allow
                // posting this note.
                if (!$note.val().trim().length) {
                    alert("Note cannot be empty.");

                    return false;
                }
            }

            $saveNoteButton.addClass("disabled");
            $saveNoteButton.prop('disabled', true);

            var saveURL = getUrlForRoute(notesRoute, {
                action: "save"
            });

            var data = {
                entity_type: $entityType.val(),
                entity_id: $entityId.val(),
                note: $note.val()
            };

            for (var key in data) {
                formData.append(key, data[ key ]);
            }

            // Let's do an AJAX request.
            $.ajax({
                url: saveURL,
                type: "POST",
                contentType: false,
                processData: false,
                data: formData,
                success: function (data) {
                    if (data.error) {
                        alert("There was an error adding your note: " + data.message);

                        return;
                    }

                    var $notesContainer = $('.notes-container');
                    var $newNote = $(data.display_html);

                    $newNote.hide();

                    // Prepend our new note before our last most recent note.
                    $notesContainer.prepend($newNote);

                    // Animate it in.
                    $newNote.slideDown("slow", function () {
                        // Remove the AJAX blueprint class.
                        $newNote.removeClass("ajax-blueprint");
                    });

                    $addNoteForm.get(0).reset();
                },
                complete: function () {
                    $saveNoteButton.removeClass("disabled");
                    $saveNoteButton.prop('disabled', false);
                }
            });

            return false;
        }
    });

    $contentNotes.on('click', "[id^=delete-note-]", function (e) {
        // Delete a note
        e.preventDefault();

        var noteId = $(this).attr("id").match("delete-note-(.+)")[ 1 ];

        var deleteURL = getUrlForRoute(notesRoute, {
            action: "delete",
            id: noteId
        });

        var $noteEle = $("#note_" + noteId);
        $noteEle.slideUp("slow");

        $.ajax({
            type: "POST",
            url: deleteURL,
            success: function (data) {
                $noteEle.remove();
            }
        });

        return false;
    });

    $addNoteForm.on('click', '.btn-remove', function () {
        // Remove an attachment
        resetFileInput($noteFileInput);
    });

    $addNoteForm.on('reset', function () {
        window.requestAnimationFrame(function () {
            // Trigger the change event on the file input when we reset our form,
            // as this doesn't happen automatically.
            // We need to do this after a short delay as the files prop doesn't get cleared
            // instantly.
            $noteFileInput.trigger('change');

            // Also trigger the "input" event on the note textarea to
            // ensure it gets resized after we reset the form.
            $note.trigger('input');
        });
    });

    $note.on('input', function () {
        // Auto resize note textarea.
        $note.css('height', 'auto');
        $note.css('height', $note.prop('scrollHeight') + 'px');
    });

    $noteActionUpload.on('click', function () {
        // Open file upload dialog on attachment icon click.
        $noteFileInput.trigger('click');
    });

    $noteFileInput.on('click', function (e) {
        // Ensure we don't recursively click the upload button, as the hidden
        // file upload element is a child of the upload button.
        e.stopPropagation();
    });

    $noteFileInput.on('change', function () {
        // Show the attached file as a preview if it is an image.
        var files = $noteFileInput.prop('files');

        if (files.length === 0) {
            // Remove any existing attachment previews.
            $addNoteForm.find('.attachment-div').remove();

            return;
        }

        if (files[ 0 ].type.match('^image\/')) {
            var reader = new FileReader();

            reader.onload = function () {
                // Remove any existing attachment previews.
                $addNoteForm.find('.attachment-div').remove();

                $textGroup.after('<div class="attachment-div"><img src="' + reader.result + '" /><span class="btn-remove icon-remove"></span></div>');
            };

            reader.readAsDataURL(files[ 0 ]);
        }else {
            // Remove any existing attachment previews.
            $addNoteForm.find('.attachment-div').remove();

            $textGroup.after('<div class="attachment-div"><span>attached: ' + files[ 0 ].name + '</span></div>');
        }
    });
})();
