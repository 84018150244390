$(function () {
    // DOM deletion to remove recipients on message_send.php
    $("#content-recipients .people-list p.no-recipients").hide();
    $("#content-recipients .people-list").on("click", ".person_summary a.remove", function () {
        $(this).closest(".person_summary").remove();
        if ($("#content-recipients .people-list .person_summary").length === 0) {
            $("#content-recipients .people-list p.no-recipients").show();
        }
    });

    // Update characters remaining on SMS dashboard widget and SMS advanced search 
    $("#sms_message").keydown(function() {
        counter_sms_char(this, "change");
    });
    $("#sms_message").keyup(function() {
        counter_sms_char(this, "blur");
    });

});

function textCounter(field, cntfield, maxlimit) {

    var field_length = field.val().replace(/\r(?!\n)|\n(?!\r)/g, "\r\n").length;
    if (field_length > maxlimit) {
        // if too long...trim it!
        field.val(field.val().replace(/\r(?!\n)|\n(?!\r)/g, "\r\n").substring(0, maxlimit));
    } else {
        // otherwise, update 'characters left' counter
        cntfield.val(maxlimit - field_length);
    }
}

function counter_sms_char(element, action) {
    switch (action) {
        case 'blur':
            textCounter($("#sms_message"), $("#sms_counter"), 160);
            break;
        case 'change':
            textCounter($("#sms_message"), $("#sms_counter"), 160);
            break;
        default:
    } // switch
}
