$(function () {
    var urlHash = window.location.hash;

    if (urlHash.length > 0) {
        setTimeout(function () {
            $("a[href='" + urlHash + "']").click();

            window.dispatchEvent(new CustomEvent('hash-tab-selected', {
                detail: urlHash,
            }));
        }, 1000);
    }

    // For ldap_identity_switcher, check the radio box when clicking anywhere
    // in a table row.
    $('.loginbox').on('click', 'tr', function() {
        $(this).find('input[name=person]').prop('checked', true);
    });

    // When we click a social button, we should be redirected to the social URL.
    $(".btn-social-media").on('click', function() {
        window.location = "/auth/sociallogin?provider=" + $(this).val() + "&do_social_login=1";
    });

    var $modalBlock = $(".modal-block");
    var $modalAlert = $(".modal-alert");
    var $modalAlertMessage = $(".modal-alert-message");

    function showModalBlock(message, dismissble) {
        // Ensure we remove any previously set click handlers.
        $modalBlock.off('click');
        $modalAlertMessage.text(message);

        $modalBlock.show();

        if( dismissble ) {
            // After a small delay, set a one-time click handler to allow closing the
            // modal block.
            setTimeout(function () {
                $modalBlock.one('click', modalBlockClickHandler);
            }, 1500);
        }
    }

    function modalBlockClickHandler(e) {
        // Only hide the modal block if we've clicked the actual block or the
        // x button, not any of the children elements, such as the message
        // box.
        var validClosingElements = [
            $modalBlock[0],
            $(".modal-x")[0]
        ];

        for( var i = 0; i < validClosingElements.length; i++ ) {
            if( e.target == validClosingElements[ i ] ) {
                $modalAlert.slideUp(undefined, function () {
                    $modalBlock.hide();
                });
                return;
            }
        }

        $modalBlock.one('click', modalBlockClickHandler);
    }

    var modalMessageText = $("#modal-message-text");
    if( modalMessageText.length > 0 ) {
        showModalBlock(modalMessageText.val(), true);
        $modalAlert.slideDown();
    }
});

export const regenerateID = function (element) {
    var id = $(element).attr("id");
    var randomNumber = Math.floor(Math.random() * 10001);
    var newID = '';
    if (typeof (id) !== "undefined") {
        newID = id.replace(/\d+/g, randomNumber);
    } else {
        newID = "element_" + randomNumber;
    }
    $(element).attr("id", newID);
};

export const reformatDatePickerTime = function (raw_time) {
    if (typeof (raw_time) !== "undefined") {
        if (raw_time === null || raw_time.length === 0) {
            return raw_time;
        }
        var re;
        re = /(\d{4})\-(\d{2})\-(\d{2}) (\d{2}):(\d{2}):(\d{2})/g;

        var regexMatch = re.exec(raw_time);
        if (regexMatch !== null) {
            return regexMatch[3] + "-" + regexMatch[2] + "-" + regexMatch[1] + " " + regexMatch[4] + ":" + regexMatch[5];
        } else {
            return raw_time;
        }
    } else {
        return '';
    }
};

/**
 * POSTs data to the specified URL.<br/>
 * Creates a form via jQuery, appends inputs based on the data provided and
 * submits the form.
 * @param {string} url The URL to post the data to.
 * @param {object} data A JavaScript object (key: value) of data to POST.
 */
export function postToURL(url, data) {
    // Create a dummy form.
    var postForm = $("<form />", {
        method: "POST",
        action: url
    });

    // Save the submit function in case it gets overriden by
    // an input with the name "submit"
    var submitFn = postForm[0].submit;

    // Create an array of inputs based on the data we've passed.
    var inputs = generateFormInputs(data);

    // Add the inputs to the form.
    postForm.html(inputs.join("\n"));

    // Submit and remove the form.
    postForm.appendTo("body");

    // Call the submit function on our form.
    submitFn.call(postForm[0]);

    // We should never really get down here, but just in case - remove the form.
    postForm.remove();
}

/**
 * Generates an array of inputs suitable for appending to a form object.<br/>
 * Recursively calls itself to create arrays of inputs for nested objects.
 * @param {object} data The data object to create inputs for.
 * @returns {Array} An array of strings. <i>e.g. ["&lt;input type='hidden'
 *     name='first_name' value='Dejan' /&gt;"]</pre></i>
 */
function generateFormInputs(data) {

    // Using an anonymous function to obfuscate the outer_objects param.
    var _theFunc = function(data, outer_objects) {
        var inputs = [];

        for( var x in data ) {
            if( data.hasOwnProperty(x) ) {
                if( typeof data[x] === "object" ) {
                    // We need to use a tmp variable, otherwise the outer_objects variable
                    // will be used even when we're not in a recursive call of this function.
                    var tmp_outer_objects;
                    if( typeof outer_objects !== "string" ) {
                        tmp_outer_objects = x;
                    }else {
                        tmp_outer_objects = outer_objects + "[" + x + "]";
                    }
                    inputs = inputs.concat(_theFunc(data[x], tmp_outer_objects));
                }else {
                    // Create the name of the input based on the outer_objects str we've been
                    // appending to.
                    var name = x;
                    if( typeof outer_objects === "string" ) {
                        name = outer_objects + "[" + x + "]";
                    }

                    // Push the new input into our inputs array.
                    inputs.push('<input type="hidden" name="' + name + '" value="' + data[x] + '" />');
                }
            }
        }

        return inputs;
    };

    return _theFunc(data);
}
