$(function () {
    $('#dw_campaign_id').on('change', function () {
        var $contributions = $('#contributions');

        // Set contributions count and contributions amount text to empty.
        $contributions.find('.payments_count, .total_amount').html('&nbsp;');
        // Display loading spinner gif.
        $contributions.find('.loading').fadeIn();

        $.ajax({
            url: 'contributions/campaign',
            type: 'POST',
            data: {campaign_id: $(this).val()},
            success: function (data) {
                if (data.operation == 'success') {
                    // Remove loading spinner gif.
                    $contributions.find('.loading').fadeOut();
                    // Update contributions count.
                    $contributions.find('.payments_count').fadeOut(function() {
                        $(this).text(data.result.number_payments_display).fadeIn();
                    });
                    // Update contributions amount.
                    $contributions.find('.total_amount').fadeOut(function() {
                        var totalAmountDisplayStr = "";
                        for( var currency in data.result.total_amount_display ) {
                            totalAmountDisplayStr += "<div>" + currency + ": " + data.result.total_amount_display[ currency ] + "</div>";
                        }

                        $(this).html(totalAmountDisplayStr).fadeIn();
                    });
                }
            }
        });
    });
    
    // Trigger ajax call on page load, if the page contains the contributions widget.
    if (typeof $('#contributions')[0] !== "undefined") {
        $('#dw_campaign_id').trigger('change');
    }
});
