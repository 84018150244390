/**
 * Created by dejan on 18/02/2016.
 */
$(document).on('ready', function() {
    $("input[name='choose_login_type']").on('click', function() {
        var $this = $(this);
        var $username = $("#username");

        $username.val("");

        if( $this.hasClass('radio_existing_user') ) {
            var username = $this.attr('data-username');

            $username.val(username);
        }

        var value = $('input[name=choose_login_type]:checked').val();
        if( value === "existing" || $this.hasClass("radio_existing_user") ) {
            $("#new_user_form").slideUp(200);

            $("#existing_user_form").slideDown(200, function() {
                $("#username").focus();
            });
        }else if( value === "new" ) {
            $("#existing_user_form").slideUp(200);

            $("#new_user_form").slideDown(200, function() {
                $("#first_name").focus();
            });
        }
    });

    $("#username").on('keyup', function() {
        var $username = $(this);

        var $existingUserRadios = $(".radio_existing_user");

        var found = false;
        $existingUserRadios.each(function() {
            var $radio = $(this);
            var username_str = $radio.attr('data-username');

            if( username_str === $username.val() ) {
                $radio.prop('checked', true);
                found = true;
                return false; // breaks out of a jquery $.each loop.
            }
        });

        if( !found ) {
            $("input[type=radio][value=existing]").prop('checked', true);
        }
    });
});