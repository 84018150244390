import {regenerateID} from "./general";
import {clearRosterChart, loadRosterChart} from "./roster";
import addPerson from "./cbar-add-person.module";
import LocalStorageAjax from './localstorage-caching.module';

$(function () {
    $("#content-roles").on("click", ".add_role_link", function (event) {
        var roleDialog = document.querySelector('.role-dialog');
        roleDialog.show();

        event.preventDefault();
        var thisForm = $("div.edit_role");

        roleResetForm();

        $('.role-dialog-title').text('Add Position');
        $('.role-dialog').find(".submit").val('Add').text('Add');
        thisForm.find("input.action").val('new');
    });

    $('paper-button.roles-cancel').on('tap', function(){
        var spinner = this.previousElementSibling;

        if(spinner.style.display === "none") {
            this.closest('md-dialog').close();
        }
    })

    $("#content-roles table.roles").on("click", ".actions a", function (event) {
        event.preventDefault();
        var thisForm = $("div.edit_role");
        var thisRoleData = roleData[$(this).attr('data-role-id')];
        var roleDialog = document.querySelector('.role-dialog');

        $('.role-dialog-title').text('Edit Position');
        $('.role-dialog').find(".submit").val('Update').text('Update');

        thisForm.find("input.quota").val(thisRoleData.id);
        thisForm.find(".name").val(thisRoleData.relationship_to);
        thisForm.find("select.role_type").val(thisRoleData.relationship_role_id);

        if ($('paper-dropdown-menu.role_type')[0]) {
            $('paper-dropdown-menu.role_type')[0].contentElement.selected = thisRoleData.relationship_role_id;
        }
        thisForm.find(".start_time").val(thisRoleData.start_time_display);
        thisForm.find(".end_time").val(thisRoleData.end_time_display);

        thisForm.find(".min").val(thisRoleData.min);
        thisForm.find(".target").val(thisRoleData.target);
        thisForm.find(".max").val(thisRoleData.max);

        thisForm.find(".is_aggregated").prop("checked", thisRoleData.is_aggregated);
        thisForm.find(".is_rostered").prop("checked", thisRoleData.is_rostered);

        if ($(this).attr('data-action') === "edit") {
            // If it's edit, we just need to display the form
            thisForm.find("input.action").val('update-role');

            roleDialog.show();
        } else if ($(this).attr('data-action') === "delete") {
            // If it's delete, we need to submit it but not display
            thisForm.find("input.action").val($(this).attr('data-action'));
            roleAction(this);
        }
    });

    // Submit the "Add Role" form
    $(".role-dialog").on("click", ".submit", function (event) {
        event.preventDefault();
        var thisForm = $("div.edit_role");
        var roleDialog = document.querySelector('.role-dialog');
        var error = false;

        // Validation
        var nameElement = thisForm.find(".name");
        if (nameElement.val().length === 0) {
            // polymer element things;
            nameElement[0].invalid = true;
            error = true;
        } else {
            nameElement[0].invalid = false;
        }

        var roleElement = thisForm.find(".role_type");

        if (!roleElement.val() || parseInt(roleElement.val()) === 0 || (roleElement[0].contentElement && roleElement[0].contentElement.selected === '0')) {
            // for polymer
            roleElement[0].invalid = true;
            error = true;
        } else {
            // for polymer
            roleElement[0].invalid = false;
        }

        // Submittal...
        if (error === false) {
            roleAction(this);
        }
    });

    var roleResetForm = function () {
        var thisForm = $("div.edit_role");
        thisForm.find(".name").val('');
        thisForm.find(".role_type").val('');
        if (thisForm.find('.role_type')[0].contentElement) {
            thisForm.find('.role_type')[0].contentElement.selected = 1;
            thisForm.find('.role_type')[0].contentElement.selected = 0;
        }
        thisForm.find(".start_time").val('');
        thisForm.find(".end_time").val('');
        thisForm.find(".quota").val('');
        thisForm.find(".min").val('');
        thisForm.find(".max").val('');
        thisForm.find(".target").val('');
        thisForm.find(".is_aggregated").prop("checked", true);
        thisForm.find(".is_rostered").prop("checked", true);
        thisForm.find("input").removeClass('error');

        $(roleDialog).find(".roles_error").text("");
        $(roleDialog).find(".roles_error").hide();
    };

    var roleAction = function (element) {
        var thisForm = $("div.edit_role");
        var roleTable = $("table.roles");
        var spinner = $(element).find('paper-spinner');
        var roleDialog = document.querySelector('.role-dialog');

        var properties = ["action", "name", "role_type", "start_time", "end_time", "min", "target", "max", "source_entity", "source_entity_id", "target_entity", "target_entity_id", "quota", "relationship_id"];

        var checkMap = {
            Group: {
                action: "groupDropdowns",
                url: "/groups/getgroupdropdowns",
            },
            Event: {
                action: 'eventDropdowns',
                url: '/events/geteventdropdowns'
            }
        }

        var params = {};
        for (var i = 0; i < properties.length; i++) {
            if (thisForm.find("input." + properties[i]).length > 0) {
                params[properties[i]] = thisForm.find("input." + properties[i]).val();
            } else if (thisForm.find("select." + properties[i]).length > 0) {
                params[properties[i]] = thisForm.find("select." + properties[i]).val();
            } else if (thisForm.find("paper-input." + properties[i]).length > 0) {
                params[properties[i]] = thisForm.find("paper-input." + properties[i]).val();
            } else if (thisForm.find("paper-dropdown-menu." + properties[i]).length > 0) {
                params[properties[i]] = thisForm.find("paper-dropdown-menu." + properties[i])[0].contentElement.selected;
            } else {
                params[properties[i]] = '';
            }
        }

        // Set the checkbox properties depending on whether it's checked or not.
        params["is_aggregated"] = !!thisForm.find(".is_aggregated").prop("checked");
        params["is_rostered"] = !!thisForm.find(".is_rostered").prop("checked");

        var ajaxActions = ["new", "update-role", "toggle-is-rostered", "toggle-is-aggregated", "delete"];

        // Only run the AJAX call if the action requires it.
        if ($.inArray(params.action, ajaxActions) > -1) {

            var action_mesage = "";
            if (params.action == "new") {
                var action_mesage = "Adding new role";
            } else if (params.action == "update-role") {
                var action_mesage = "Updating role";
            } else if (params.action == "delete") {
                var action_mesage = "Deleting role";
            }

            if (action_mesage != "" && !spinner) {


                action_mesage = "<p class='action_mesage'><span class='action_message_action'>" + action_mesage + "</span> Please wait </p>";
                action_mesage += "<img src='/images/map_loading.gif' class='action_loading'>";
                action_mesage += "<div style='clear:both;'></div>";

                $(".action_mesage_box").html(action_mesage);
                $(".action_mesage_box").removeClass("error");
                $(".action_mesage_box").addClass("info");
                $(".action_mesage_box").show();
            }


            $('.roles-loading').show();

            $.ajax({
                url: '/ajax/entity-roles',
                type: 'POST',
                data: params,
                success: async function (data) {
//                        messageBox.empty();

                    if (data.error === false) {
                        // Reload roster if it is currently displayed.
                        if ($('#content-roster').length > 0) {
                            clearRosterChart();
                            loadRosterChart(1175);
                        }

                        $(roleDialog).find(".roles_error").text("");
                        $(roleDialog).find(".roles_error").hide();
                        var updateTable = false;
                        var newTableRow;
                        // Update the UI according to the action
                        switch (params.action) {
                            case 'new':
                                newTableRow = roleTable.find("tr.ajax-blueprint")
                                .clone()
                                .removeClass("ajax-blueprint");
                                updateTable = true;
                                regenerateID(newTableRow);


                                var displayName = data.data.display_name;
                                if (data.data.start_time === "" && data.data.end_time === "") {
                                    displayName += "( Not Scheduled  )"
                                }

                                var textInnerHTML = `<span class="text">${displayName}</span> <span class="actions"><a data-action="edit" data-role-id="${data.data.role_id}" class="icon-edit" title="Edit"></a> <a data-action="delete" data-role-id="${data.data.role_id}" class="icon-remove" title="Remove"></a></span>`;
                                var minHours = data.data.min_hrs;
                                var targetHours = data.data.target_hrs;

                                newTableRow.find('td.min').text(`${minHours} (${minHours} Hrs)`);
                                newTableRow.find('td.target').text(`${targetHours} (${targetHours} Hrs)`);

                                newTableRow.find('td.role_name').html(textInnerHTML);
                                newTableRow.find("td.actual").text(0);
                                newTableRow.find("td.actual_fte").text("0 (0 Hrs)");
                                newTableRow.find("td.actual_fte").css({"font-weight": "bold", "color": "red"});
                                newTableRow.find("td.capped_capacity").text("0%");
                                newTableRow.find("td.capped_capacity").css({"font-weight": "bold", "color": "red"});
                                newTableRow.appendTo(roleTable);
                                let addResult = await handleRoleChange(data, params.action, checkMap[data.data.target_entity]);

                                console.log(roleDialog)
                                if(roleDialog){
                                    roleDialog.close();
                                }
                                break;
                            case 'delete':
                                newTableRow = roleTable.find("tr[data-role-id=" + data.data.role_id + "]");
                                newTableRow.remove();
                                let deleteResult = await handleRoleChange(data, params.action, checkMap[data.data.target_entity]);
                                break;
                            case 'update-role':
                                newTableRow = roleTable.find("tr[data-role-id=" + data.data.role_id + "]");
                                updateTable = true;

                                let updateResults = await handleRoleChange(data, params.action, checkMap[data.data.target_entity]);

                                if(roleDialog){
                                    roleDialog.close();
                                }
                                break;
                        }

                        if (updateTable === true) {
                            displayName = data.data.display_name;
                            if (data.data.start_time === "" && data.data.end_time === "") {
                                displayName += " ( Not Scheduled ) "
                            }

                            newTableRow.attr('data-role-id', data.data.role_id);
                            newTableRow.find(".actions a").attr('data-role-id', data.data.role_id);
                            newTableRow.find("td.role_name .text").html(displayName);

                            var minQty = data.data.min === null ? 0 : data.data.min;
                            var minHrs = data.data.min_hrs === null ? 0 : data.data.min_hrs;
                            newTableRow.find("td.min").text(minQty + " (" + minHrs + " Hrs)");

                            newTableRow.find("td.max").text(data.data.max === null ? 0 : data.data.max);

                            var targetQty = data.data.target === null ? 0 : data.data.target;
                            var targetHrs = data.data.target_hrs === null ? 0 : data.data.target_hrs;
                            newTableRow.find("td.target").text(targetQty + " (" + targetHrs + " Hrs)");

                            newTableRow.find("td.start_time").text(data.data.start_time);
                            newTableRow.find("td.end_time").text(data.data.end_time);
                            newTableRow.find(".container_is_aggregated .is_aggregated").prop("checked", data.data.is_aggregated).prop('disabled', true);
                            newTableRow.find(".container_is_rostered .is_rostered").prop("checked", data.data.is_rostered).prop("disabled", true);

                            var role_id = data.data.role_id;
                            if (typeof (roleData[role_id]) === 'undefined') {
                                roleData[role_id] = {};
                            }

                            // TODO: check whether Group quotas use these vars or whether its safe to delete
                            roleData[role_id]['ID'] = data.data.role_id;
                            roleData[role_id]['EventID'] = data.data.target_entity_id;
                            roleData[role_id]['Actual'] = null;
                            roleData[role_id]['RelationshipTo'] = data.data.name;
                            roleData[role_id]['Min'] = data.data.min !== '' ? data.data.min : null;
                            roleData[role_id]['Max'] = data.data.max !== '' ? data.data.max : null;
                            roleData[role_id]['Target'] = data.data.target !== '' ? data.data.target : null;
                            roleData[role_id]['StartTime'] = data.data.start_time;
                            roleData[role_id]['EndTime'] = data.data.end_time;
                            roleData[role_id]['RelationshipRoleID'] = data.data.role_type;

                            // Event quotas use these vars
                            roleData[role_id]['id'] = data.data.role_id;
                            roleData[role_id]['relationship_role_id'] = parseInt(data.data.role_type);
                            roleData[role_id]['relationship_to'] = data.data.name;
                            roleData[role_id]['relationship_from'] = data.data.name;
                            roleData[role_id]['start_time_display'] = data.data.start_time;
                            roleData[role_id]['end_time_display'] = data.data.end_time;
                            roleData[role_id]['min'] = data.data.min !== 0 ? data.data.min : 0;
                            roleData[role_id]['max'] = data.data.max !== 0 ? data.data.max : 0;
                            roleData[role_id]['target'] = data.data.target !== 0 ? data.data.target : 0;
                            roleData[role_id]['is_aggregated'] = data.data.is_aggregated;
                            roleData[role_id]['is_rostered'] = data.data.is_rostered;
                        }

                        // Update event summary statistics, if available
                        if ($("body").is("#events") && typeof data.data.event_statistics !== "undefined") {
                            $(".event-target").text(data.data.event_target_display);
                            $(".event-actual").text(data.data.event_actual_display);
                            $(".event-capacity").text(data.data.event_capacity_display);
                        }

                        roleResetForm();

                        $(".action_mesage_box").hide();
                    } else {
                        if (data.message === "existingID") {
                            $(roleDialog).find(".roles_error").text("Role name and time already exists.");
                            $(roleDialog).find(".roles_error").show();
                        }
//                            $.each(data.message, function (index, element) {
//                                messageBox.append($("<div>").text(element));
//                            });

                        $(".action_mesage_box").removeClass("info");
                        $(".action_mesage_box").addClass("error");
                        $(".action_mesage_box").html(data.message[0]);
                    }

                    $('.roles-loading').hide();
                }
            });
        }
    };

    var handleRoleChange = async function (roleData, action, propToUpdate) {
        return new Promise(async (res, rej) => {
            let request = await LocalStorageAjax.get(propToUpdate.action, {
                url: `${propToUpdate.url}`,
                type: "GET",
                dataType: 'json'
            });


            if (!request.results) {
                res();
            }

            let roleToAction = roleData.data;
            let arrayOfEntities = request.results;

            let entityToUpdate = arrayOfEntities.find(prop => prop.value === roleToAction.target_entity_id.toString());

            if (!entityToUpdate) {
                let checkForPastEvent = await addPerson.grabPastEvent(roleToAction.target_entity_id);

                if (checkForPastEvent.event) {
                    entityToUpdate = checkForPastEvent.event;
                    arrayOfEntities.push(entityToUpdate);
                }
            }

            let indexToUpdate = arrayOfEntities.indexOf(entityToUpdate);

            if (action === "new") {
                entityToUpdate.quotas.push({
                    id: parseInt(roleToAction.role_id),
                    label: roleToAction.name,
                    start_time: roleToAction.start_time,
                    end_time: roleToAction.end_time
                });
            }

            if (action === "update-role") {
                console.log(entityToUpdate, arrayOfEntities, indexToUpdate);
                let index = entityToUpdate.quotas.indexOf(entityToUpdate.quotas.find(quota => quota.id.toString() === roleToAction.role_id.toString()));

                entityToUpdate.quotas[index] = {
                    id: parseInt(roleToAction.role_id),
                    label: roleToAction.name,
                    start_time: roleToAction.start_time,
                    end_time: roleToAction.end_time
                };

                let roleDisplayToChange = $(`.group_role_edit[data-quota="${roleToAction.role_id}"], .event_role_edit[data-quota="${roleToAction.role_id}"]`);

                roleDisplayToChange.attr('data-relationship-title', roleToAction.name);
                // role displayed times are appended inside the role name as a seperate div, however as these
                // count as children to the node, we have to loop and set manually
                roleDisplayToChange.each(function () {
                    this.firstChild.textContent = roleToAction.name;
                })

            }

            if (action === "delete") {
                let index = entityToUpdate.quotas.indexOf(entityToUpdate.quotas.find(quota => quota.id === roleToAction.role_id))
                entityToUpdate.quotas.splice(index, 1);
            }

            arrayOfEntities[indexToUpdate] = entityToUpdate;

            await LocalStorageAjax.remove(propToUpdate.action);
            await LocalStorageAjax.set(propToUpdate.action, {error: false, results: arrayOfEntities});

            let dialogEntities = {...addPerson.dialog.entities};
            let contextEventId = roleToAction.target_entity === "Event" ? entityToUpdate.id : null;
            let contextGroupId = roleToAction.target_entity === "Group" ? entityToUpdate.id : null;
            dialogEntities[roleToAction.target_entity] = await addPerson._formatResults({error: false, results: arrayOfEntities}, roleToAction.target_entity);

            addPerson.dialog.entities = false;

            requestAnimationFrame(() => {
                addPerson.dialog.entities = dialogEntities;

                addPerson.updateRoleDialog.syncRole({
                    id: parseInt(roleToAction.role_id),
                    title: roleToAction.name,
                    start_time: roleToAction.start_time,
                    end_time: roleToAction.end_time
                });

                // THIS FIXES AN ISSUE WITH THE DIRECT ADD DIALOG THAT RECHECKS THE PROMISE EVERYTIME ITS OPENED
                addPerson._eventsPromise =  new Promise((res, rej) => {res(dialogEntities['Event'])});
                addPerson._groupsPromise =  new Promise((res, rej) => {res(dialogEntities['Group'])});

                addPerson._buildContextEntityIDMap();
                addPerson.setContext(contextEventId, contextGroupId);
            });
            res();
        })
    }
});
