import {openDialog as openAddPersonDialog} from "./cbar-add-person.module";
import 'jquery-ui/ui/widgets/dialog';
import {handleFavouriteButton} from "./favourite-handler.module";

export function openAddGroupDialog() {
    var dialog = document.querySelector('cbar-add-group-dialog#add_group_dialog');
    dialog.opened = true;
}

$(function () {

    var newGroupName = $("#add_group_dialog .group_name").first();
    var groupOwnerMap = {};

    if ($("body").is("#groups")) {
        var $fab = $('.group-add-participant');
        var $photoFab = $('.group-add-photo');
        var groupId = $("input.target_entity_id").val();
        var $contentTeam = $('#content-team');
        var statusDialog = document.querySelector('md-dialog#status-dialog');
        var statusDropdown = document.querySelector('paper-dropdown-menu#status-dropdown').contentElement;
        var statusSpinner = document.querySelector('paper-spinner#update-status-spinner');
        var subscriberButton = document.querySelector('cbar-list-item#subscriber-option');
        var favouriteButton = document.querySelector('cbar-list-item#favourite-option');
        var subscriberConfirm = document.querySelector('#subscriber-dialog-confirm');
        var scrollElement = document.querySelector('cbar-entity-summary');

        var searchDisplay = document.querySelectorAll('.display-search');

        const paperTabs = document.querySelector('paper-tabs');
        const pages = document.querySelector('iron-pages');

        if(scrollElement){
            requestAnimationFrame(() => {
                scrollElement.fixHeaderDiv();
                window.scrollTo(0, scrollElement.titleDiv.offsetTop - 72);
            });
        }

        if(statusDialog){
            statusDialog.addEventListener('close', (e) => {
                if(!statusSpinner.hidden){
                    e.preventDefault();
                }
            })
        }

        if(subscriberButton) {
            subscriberButton.addEventListener('tap', (e) => {
                handleSubscriberButton(e)
            });
        }

        if(favouriteButton){
            favouriteButton.addEventListener('tap', (e) => {
                handleFavouriteButton(e)
            });
        }

        scrollElement.addEventListener('back-button-clicked', function(){
            history.back();
        });

        if(subscriberConfirm) {
            subscriberConfirm.addEventListener('tap', (e) => {
                handleSubscriberFormConfirmed(e, groupId)
            });
        }

        if(searchDisplay.length > 0){
            searchDisplay.forEach((display) => {
                display.addEventListener('tap', () => {
                    $(display).closest('.search_wrapper').find('.control.search.row').slideToggle();
                })
            });
        }

        if (paperTabs) {
            paperTabs.addEventListener('selected-changed', async function (e) {
                pages.selected = e.detail.value;
                var hash = "#" + pages.selected;
                if (location.hash !== hash) {
                    location.hash = hash;
                }

                window.scrollTo(0, paperTabs.offsetTop - 8);

                if (pages.selected === 'chat') {
                    const element = document.querySelector('cbar-chat-client');

                    if(element.roomId && element.accessToken && element.userId){
                        return;
                    }

                    let roomDetails = await getGroupRoomEntity(groupId);
                    setRoomDetails(roomDetails);
                }
            });

            setSelectedTabFromHash(paperTabs, pages);
        }

        $fab.on('tap', function () {
            openAddPersonDialog({groupId: groupId});
        });

        $photoFab.on('tap', function(){
            $('input#image_upload').click();
        })

        $(".transition-left-button").on("tap", function () {
            window.setTimeout(function () {
                $fab.addClass('transition-left');
            }, 100);
        });

        $('.status-button').on('tap', function (e) {
            statusDropdown.selected = e.target.dataset.relationship;
            statusDropdown.dataset.id = e.target.dataset.id;
            statusDialog.show();
        });

        $('.relationship-remove').on('tap', function (e) {
            var removeDialog = document.querySelector('#remove-dialog');
            var removeDialogButton = document.querySelector('#relationship-remove-confirm');

            Object.assign(removeDialogButton.dataset, e.target.dataset);
            removeDialog.show();
        });

        $('#relationship-remove-confirm').on('tap', function (e) {
            $('#relationship-remove-spinner')[0].removeAttribute('hidden');
            $('#relationship-remove-cancel')[0].setAttribute('disabled', true);
            $('#relationship-remove-confirm')[0].setAttribute('disabled', true);
            groupAction($(e.target));
        });

        $('#update-status-confirm').on('tap', function (e) {
            var statusCancelButton = document.querySelector('paper-button#update-status-cancel');
            e.target.setAttribute('disabled', true);
            statusCancelButton.setAttribute('disabled', true);
            statusSpinner.removeAttribute('hidden');

            updateGroupRelationshipStatus(statusDropdown.dataset.id, statusDropdown.selected)
        });

        $("cbar-chat-client").on('room-toggled', function (e) {
            // set this here to keep context
            var element = this;
            element.loading = true;
            element.noRoom = false;

            $.ajax({
                url: '/groups/ajaxaddgrouproom',
                type: 'POST',
                data: {
                    group_id: groupId,
                },
                success: function (e) {
                    if (e.error) {
                        console.error(e.message);
                        element.loading = false;
                    } else {
                        element.roomId = e.id;
                    }
                }
            })
        });

    }

    var addGroupDialog = document.querySelector('cbar-add-group-dialog#add_group_dialog');
    if(addGroupDialog) {
        addGroupDialog.addEventListener('confirm-location', function (event) {
            addGroup();
        });
    }

    // Action to take when user submits the form inside the "Add Group" dialog
    function addGroup() {
        var groupName = addGroupDialog.title;
        var parentId = addGroupDialog.selectedParentGroup;
        var groupInput = document.createElement('input');

        // the existing function requires an input of sorts to handle the data being sent
        // so we create a fake one here
        groupInput.type = "hidden";
        groupInput.value = groupName;
        groupInput.setAttribute('data-parentid', parentId);
        groupInput.setAttribute('data-action', 'new');
        document.body.appendChild(groupInput);

        groupAction($(groupInput));
    }

    // Reset styling on "Add Group" dialog
    function resetGroupDialog() {
        newGroupName.removeClass("ui-state-error");
        groupError.text("");
        groupConfirmed.text("");
    }

    var addGroupInEventDialog = $("#add_group_in_event_dialog").dialog({
        autoOpen: false,
        height: 250,
        width: 350,
        modal: true,
        dialogClass: 'cc-dialog',
        buttons: {
            "Add": function () {
                var $groupDropdown = $(".add_group_in_event");

                if ($groupDropdown.val() == '') {
                    $(".add_group_in_event_confirmed").text("");
                    $(".add_group_in_event_error").text("Please select group.");
                } else {
                    $(".add_group_in_event_error").text("");
                    $(".add_dialog_parent").val('');
                    $(".add_group_in_event_confirmed").text("Adding Event to Group. Please Wait ... ");

                    var groupId = $groupDropdown.val();
                    var groupName = $groupDropdown.find("option:selected").text();

                    $.ajax({
                        url: '/events/addgroup',
                        type: 'POST',
                        data: {
                            group_id: groupId,
                            event_id: $("#event_id").val()
                        },
                        success: function (data) {
                            var result = jQuery.parseJSON(data);

                            if (result.result === 'success') {
                                var $eventDetailsTable = $(".event-details").find('table tbody');

                                $eventDetailsTable.append('<tr><td><i class="icon-group"></i></td><td><a href="/groups/view/' + groupId + '">' + groupName + '</a></td></tr>');

                                $(".add_group_in_event_error").text("");
                                $(".add_group_in_event_confirmed").text(result.message);

                                setTimeout(function () {
                                    $(".add_group_in_event").val("");
                                    $(".add_group_in_event_confirmed").text("");
                                    $(".add_group_in_event_error").text("");
                                    addGroupInEventDialog.dialog("close");
                                }, 2000);
                            } else {
                                $(".add_group_in_event_confirmed").text("");
                                $(".add_group_in_event_error").text(result.message);
                                return;
                            }
                        }
                    });
                }
            },
            Cancel: function () {
                $(".add_group_in_event").val("");
                $(".add_group_in_event_confirmed").text("");
                $(".add_group_in_event_error").text("");
                addGroupInEventDialog.dialog("close");
            }
        },
        close: function () {
            $(".add_group_in_event").val("");
            $(".add_group_in_event_confirmed").text("");
            $(".add_group_in_event_error").text("");
            addGroupInEventDialog.dialog("close");
        }
    });

    // Show the "Add Group" dialog when selected
    $(".add_group_item").on("tap", function () {
        addGroupDialog.dialog("open");
    });

    $(".owner-group-add").on("tap", function () {
        var group_dialog = document.querySelector(".add-group-owners");
        group_dialog.toggle();
    });

    $(".owner-event-add").on("tap", function () {
        var event_dialog = document.querySelector(".add-event-owners");
        event_dialog.toggle();
    });

    $(".timezone-dropdown").on('change', function(e){
       var originalTimezone = document.querySelector('#original-timezone-input');
       var hiddenDiv = document.querySelector('.timezone-warning');

       if(hiddenDiv && originalTimezone) {
           hiddenDiv.hidden = originalTimezone.value === e.target.value;
       }
    });

    $('.add-group-cancel, .add-event-cancel, .close-button').on('tap', function(){
        var spinner = this.previousElementSibling;

        if(!spinner || spinner.style.display === "none" || spinner.hidden) {
            this.closest('md-dialog').close();
        }
    })

    $(".add-group-owner-accept").on("tap", async function () {
        var existingOwners = document.querySelectorAll('cbar-places-item.group-owner');
        var groupSection = document.querySelector('.owner-groups-display');
        var addGroupSpinner = document.querySelector('.add-group-spinner');
        var group_dialog = document.querySelector(".add-group-owners");
        var dropdown = document.querySelector("cbar-select2[name=groups]");
        var errorDiv = document.querySelector('.owner-groups-error-display');
        var selectedId = dropdown.value;

        if(!selectedId || selectedId === '0'){
            return;
        }

        addGroupSpinner.active = true;

        var existsInList = false;
        existingOwners.forEach(node => {
            if (node.id === selectedId) {
                existsInList = true;
            }
        });

        if (!existsInList) {
            await handleAddedGroupOwner(selectedId, groupOwnerMap);

            var newListElement = document.createElement('cbar-places-item');
            newListElement.classList.add('group-owner');
            errorDiv.innerHTML = "";
            newListElement.id = selectedId;
            newListElement.title = dropdown.selectEle.selectedOptions[0].innerHTML;

            groupSection.appendChild(newListElement);

            var deleteListButton = document.createElement('paper-icon-button');
            deleteListButton.icon = "delete";

            deleteListButton.addEventListener('tap', () => {handleRemovedGroup(newListElement, groupOwnerMap, selectedId)});

            newListElement.appendChild(deleteListButton);
        }

        dropdown.value = "0";
        addGroupSpinner.active = false;
        group_dialog.toggle();
    });

    $(".add-event-owner-accept").on("tap", function () {
        var existingOwners = document.querySelectorAll('cbar-places-item.event-owner');
        var eventSection = document.querySelector('.owner-events-display');
        var event_dialog = document.querySelector(".add-event-owners");
        var dropdown = document.querySelector("cbar-select2[name=events]");
        var errorDiv = document.querySelector('.owner-events-error-display');

        var selectedId = dropdown.value;

        if(!selectedId || selectedId === '0'){
            return;
        }

        var existsInList = false;
        existingOwners.forEach(node => {
            if (node.id === selectedId) {
                existsInList = true;
            }
        });

        if (!existsInList) {
            var newListElement = document.createElement('cbar-places-item');
            newListElement.classList.add('event-owner');
            errorDiv.innerHTML = "";
            newListElement.id = selectedId;
            newListElement.title = dropdown.selectEle.selectedOptions[0].innerHTML;

            eventSection.appendChild(newListElement);

            var deleteListButton = document.createElement('paper-icon-button');
            deleteListButton.icon = "delete";

            deleteListButton.addEventListener('tap', function () {
                newListElement.remove();
            });

            newListElement.appendChild(deleteListButton);
        }

        dropdown.value = "0";
        event_dialog.toggle();
    });


    $(".add_event_link").on("tap", function (event) {
        event.preventDefault();
        $(".add_event").toggle();
        $(".add_event > span.ajax_message").text('');
    });

    $('.event-owner-delete').on('tap', async function (e){
        this.parentNode.remove();
    })

    $('.group-owner-delete').on('tap', async function (e) {
        this.hidden = true;
        var selectedId = this.parentNode.id;
        var selectedNodeSpinner = this.parentNode.querySelector('paper-spinner');

        // we have to find the events for that group
        // this will happen on edit initial
        if(!groupOwnerMap[selectedId]){
            selectedNodeSpinner.active = true;
            groupOwnerMap[selectedId] = {
                events: []
            }

            let eventsResponse = await getGroupEvents(selectedId);
            eventsResponse.events.forEach(event => {
                groupOwnerMap[selectedId].events.push({
                    id: event.id
                })
            });
        }

        await handleRemovedGroup(this.parentNode, groupOwnerMap, selectedId);
        this.parentNode.remove();
    });

    $(".add_event").on("tap", "paper-button[type=submit]", function (event) {
        event.preventDefault();
        var thisEvent = $(this).closest(".add_event");
        var addressInput = document.querySelector('cbar-address-autocomplete');
        addressInput.optionalFields = ['address2', 'address3'];

        var parentGroups = document.querySelectorAll("cbar-places-item.group-owner");
        var parentEvents = document.querySelectorAll("cbar-places-item.event-owner");
        var groupsErrorDiv = document.querySelector('.owner-groups-error-display');
        var eventsErrorDiv = document.querySelector('.owner-events-error-display');

        if (!addressInput.validate() || parentGroups.length === 0) {
            if (parentGroups.length === 0) {
                groupsErrorDiv.innerHTML = "This Event must belong to at least one group.";
                // get the error within view
                groupsErrorDiv.scrollIntoView(false);
            }
            return;
        }

        groupsErrorDiv.innerHTML = "";

        var spinner = this.closest('.add_event').querySelector('paper-spinner.loading-spinner');
        spinner.active = true;

        thisEvent.find(".loading").fadeIn("slow");
        thisEvent.children("div.form").hide();
        thisEvent.find("p.input_errors").text("");

        var ajaxData = {
            name: thisEvent.find("paper-input[name=name]").val(),
            description: thisEvent.find("paper-input[name=description]").val(),
            start_time: thisEvent.find("paper-input[name=start_time]").val(),
            end_time: thisEvent.find("paper-input[name=end_time]").val(),
            location_title: addressInput.title,
            address_line_1: addressInput.streetAddress,
            suburb: addressInput.suburb,
            post_code: addressInput.postcode,
            state: addressInput.state,
            country: addressInput.country,
            notes: thisEvent.find("paper-input[name=notes]").val(),
            notify: thisEvent.find("input.notify").val(),
            profile_id: thisEvent.find("input.profile_id").val(),
            action: thisEvent.find("input.action").val(),
            location_id: thisEvent.find("input.location_id").val(),
            timezone: thisEvent.find('[name=timezone]').val(),
            permissions: document.querySelector("paper-dropdown-menu[name=permissions]").contentElement.selected
        };


        var groupIds = [];
        var eventIds = [];

        parentGroups.forEach(group => {
            groupIds.push(group.id);
        });

        parentEvents.forEach(event => {
            eventIds.push(event.id);
        })

        ajaxData.group_ids = groupIds;
        ajaxData.event_ids = eventIds;

        // Reset our error message.
        $(".add_event .ajax_message").text("");

        $.ajax({
            url: '/events/add',
            type: 'POST',
            data: ajaxData,
            success: function (data) {
                spinner.active = false;
                thisEvent.find(".loading").fadeOut("slow");
                if (data.error === false) {
                    // allow for our localstorage cache to reset, adding the new event
                    indexedDB.deleteDatabase('CC-STORAGE');
                    thisEvent.find("input[type=textfield]").val('');
                    $(".add_event .ajax_message").text("Event added. Redirecting to event page...");
                    // Redirect to the event page
                    window.setTimeout(function () {
                        window.location.href = window.location.origin + "/events/view/" + data.event_id;
                    }, 2000);
                } else {
                    thisEvent.children("div.form").show();
                    thisEvent.find("p.input_errors").text(data.message);
                    $(".add_event .ajax_message").text("There was a problem adding your event.");
                }
            }
        });
    });


    $("ul.group-list li.new span.linklabel").show();
    $("ul.group-list li span.inputfield").hide();

    $("ul.group-list").on("click", "li.new a", function (element) {
        $(this).closest("li.new").find("span.linklabel").hide();
        $(this).closest("li.new").find("span.inputfield input").val("");
        $(this).closest("li.new").find("span.inputfield").slideDown("fast");
    });

    // People widget > Group tab > Click on 'tick' icon.
    $("ul.group-list").on("click", "li span.inputfield i", function () {
        var input = $(this).parent().find("input");
        groupAction(input);
    });

    // People widget > Group tab > Press 'Enter' in next group input.
    $("ul.group-list").on("keyup", "li span.inputfield input", function (e) {
        if (e.keyCode === 13) {
            var input = $(this);
            groupAction(input);
        }
    });

    $("ul.group-list").on("click", "li span.actions a", function () {
        var input = $(this);
        groupAction(input);
    });

    $("ul.group-list").on("change", "li span.inputfield select", function () {
        var input = $(this);
        $(input).attr('data-group', $(input).val());
        $(input).attr('data-relationship', "Volunteer");
        groupAction(input);
    });

    $("div.group-parents-wrap, div.group-children-wrap").on("change", "select.add-parent-existing, select.add-child-existing", function () {
        var input = $(this);
        groupAction(input);
    });

    // Group page > Group Administration > add existing group to parent or sub-group.

    var groupAction = function (input) {

        var properties = [
            "action",
            "profile",
            "group",
            "groupto",
            "relationship",
            "parentid",
        ];
        var params = {
            value: input.val()
        };
        for (var i = 0; i < properties.length; i++) {
            if (typeof (input.attr("data-" + properties[i])) !== 'undefined') {
                params[properties[i]] = input.attr("data-" + properties[i]);
            } else {
                params[properties[i]] = '';
            }
        }

        // Load up the blueprint object up
        var groupList = $(input).closest("ul.group-list");
        var groupListItem = $(input).closest("ul.group-list li");
        var blueprint = $(groupList).find("li.ajax-blueprint");

        // Do not proceed with ajax if new group name is empty.
        if (params.action === "new" && !$.trim(params.value)) {
            return;
        }

        if (params.action === "edit") {
            // Custom behaviour for 'edit' action
            $("span.inputfield").hide();
            $("span.title").show();
            groupListItem.find("span.inputfield").show();
            groupListItem.find("span.title").hide();
        }

        if (params.action === "add") {
            params.value = $(input).find('option:selected').attr('label');
        }

        var ajaxActions = [
            "new",
            "update",
            "delete",
            "add",
            "remove",
            "revokeparentchild"
        ];

        // Only run the AJAX call if the action requires it.
        if ($.inArray(params.action, ajaxActions) > -1) {

            $.ajax({
                url: '/ajax/group',
                type: 'POST',
                data: params,
                success: function (data) {

                    switch (params.action) {
                        case 'new':
                            // Show success
                            addGroupDialog.confirmed = true;
                            addGroupDialog.opened = false;
                            addGroupDialog.formLoading = false;
                            addGroupDialog.reset();

                            addGroupDialog.set('parentGroups', [...addGroupDialog.parentGroups, {id: data.group_data.group, title: data.group_data.value}]);

                            // let our uac reset before the user possibly visits the group
                            indexedDB.deleteDatabase('CC-STORAGE');

                            location.href = "/groups/view?id=" + data.group_data.group;
                            break;
                        case 'update':
                            var newGroupName = $(groupListItem).find("span.inputfield input").val();
                            if (groupListItem.find("span.title a").length === 1) {
                                $(groupListItem).find("span.title a").text(newGroupName);
                            } else {
                                $(groupListItem).find("span.title").text(newGroupName);
                            }
                            $(groupListItem).find("span.inputfield").hide();
                            $(groupListItem).find("span.title").show();
                            // Just in case we update a group that we're also a member of - update the other displays
                            $("ul.group-list li").each(function () {
                                if (typeof ($(this).attr("data-id")) !== "undefined" && $(this).attr("data-id") === params.group) {
                                    if ($(this).find("span.title a").length === 1) {
                                        $(this).find("span.title a").text(newGroupName);
                                    } else {
                                        $(this).find("span.title").text(newGroupName);
                                    }
                                }
                            });
                            break;
                        case 'delete':
                            $(groupListItem).slideUp("fast");
                            // Just in case we delete a group that we're also a member of
                            $("ul.group-list li").each(function () {
                                if (typeof ($(this).attr("data-id")) !== "undefined" && $(this).attr("data-id") === params.group) {
                                    $(this).slideUp("fast");
                                }
                            });
                            break;
                        case 'add':
                            appendGroupListItem(data.group_data.profile, data.group_data.group, data.group_data.value, data.group_data.relationship);
                            break;
                        case 'remove':
                        case 'revokeparentchild':
                            $(groupListItem).slideUp("fast");
                            location.reload();
                            break;
                    }
                    // allow our indexedDB cache to reset, allowing for any updates above to be reflected
                    indexedDB.deleteDatabase('CC-STORAGE');
                }
            });
        }
    };


    if ($('body').is('#groups')) {
        var noteSubmit = document.querySelector('#note-submit');
        if (noteSubmit) {
            noteSubmit.addEventListener('tap', function () {
                document.querySelector('#AddNoteForm').submit();
            });
        }
    }
});

// Append the profile to group relationship after assignment in person.php
var appendGroupListItem = function (profile_id, group_id, group_name, profile_relationship_id, relationship_to) {
    // Load up the blueprint object up
    var input = $("ul.group-list li span.inputfield select");
    var groupList;
    if ($(input).length > 0) {
        groupList = $(input).closest("ul.group-list");
    } else {
        groupList = $("ul.group-list-getGroupMemberships").first();
    }
    var blueprint = $(groupList).find("li.ajax-blueprint");

    // Clone ajax-blueprint and add to the end of the list, removing the class
    var newGroup = blueprint.clone()
    .removeClass("ajax-blueprint");

    // Update all the important info on the new group
    newGroup.attr('data-id', group_id);
    if (newGroup.find("span.title a").length === 1) {
        var title = newGroup.find("span.title a").attr("title") + group_name;
        var href = newGroup.find("span.title a").attr("href") + group_id;
        newGroup.find("span.title a").attr("title", title);
        newGroup.find("span.title a").attr("href", href);
        newGroup.find("span.title a").text(group_name);
    } else {
        newGroup.find("span.title").text(group_name);
    }

    if (relationship_to !== undefined && relationship_to.length > 0) {
        var relationshipSpan = newGroup.find("span.relationship-to");
        if (relationshipSpan.length === 1) {
            // Add the relationship to the output.
            relationshipSpan.text(relationship_to);
        }
    }

    newGroup.find("span.actions").append(
        "<a class='icon-minus-sign' title='Remove' data-action='remove'></a>"
    );
    newGroup.find("span.actions a").attr("data-profile", profile_id);
    newGroup.find("span.actions a").attr("data-group", group_id);
    newGroup.find("span.actions a").attr("data-relationship", profile_relationship_id);

    // Add the cloned object to the end of the list
    newGroup.hide().insertBefore(blueprint).slideDown("fast", function () {
        // Set the edit box back to its original state
        $(groupList).find("li.new span.inputfield").hide();
        $(groupList).find("li.new span.linklabel").show();
    });
};

function updateGroupRelationshipStatus(relationshipID, statusID) {
    var statusDropdown = document.querySelector('paper-dropdown-menu#status-dropdown').contentElement;
    var successDialog = document.querySelector('md-dialog#status-success');
    var statusSpinner = document.querySelector('paper-spinner#update-status-spinner');
    var statusCancelButton = document.querySelector('paper-button#update-status-cancel');
    var statusUpdateButton = document.querySelector('paper-button#update-status-confirm');
    var statusDialog = document.querySelector('md-dialog#status-dialog');
    var updateTarget = document.querySelector('a[data-id="' + relationshipID + '"]');
    var updateButtons = document.querySelectorAll('paper-icon-button[data-id="' + relationshipID + '"]');

    $.ajax({
        url: '/groups/ajaxupdaterelationshipstatus',
        type: 'POST',
        data: {
            id: relationshipID,
            status_id: statusID
        },
        success: function (response) {

            statusSpinner.setAttribute('hidden', true);
            statusUpdateButton.removeAttribute('disabled');
            statusCancelButton.removeAttribute('disabled');

            if (response.message) {
                console.error(response.message);
                statusDropdown.invalid = true;
                statusDropdown.errorMessage = "Issue setting status ID, try again later."
            } else {
                statusDropdown.invalid = false;
                statusDropdown.errorMessage = "";

                var innerHTML = updateTarget.innerHTML.split(" (")[0];
                if (response.status_text.toLowerCase() !== "accepted") {
                    innerHTML += " (" + response.status_text + ")";
                }

                var classToRemove = 'role_' + response.old_status.toLowerCase();
                var classToAdd = 'role_' + response.status_text.toLowerCase();

                updateTarget.innerHTML = innerHTML;

                updateTarget.classList.remove(classToRemove);
                updateTarget.classList.add(classToAdd);

                updateButtons.forEach(function (button) {
                    button.dataset.relationship = response.status;
                    button.classList.remove(classToRemove);
                    button.classList.add(classToAdd);
                });

                statusDialog.close();
                successDialog.show();
            }
        }
    });
}

var roomCache = {};

async function getGroupRoomEntity(groupId) {
    if (roomCache.hasOwnProperty(groupId)) {
        return roomCache[groupId];
    }

    return new Promise((res, rej) => {
        $.ajax({
            url: '/groups/ajaxgetgrouproom',
            type: 'POST',
            data: {
                group_id: groupId,
            },
            success: function (data) {
                roomCache[groupId] = data;

                return res(data);
            }
        });
    });
}

function setRoomDetails(details) {
    const element = document.querySelector('cbar-chat-client');
    if (!element) {
        return console.warn("No chat client element found");
    }

    element.roomId = details.room_id ? "!" + details.room_id : '';
    element.accessToken = details.matrix_access_token;
    element.userId = details.matrix_user_id;
}

function handleSubscriberButton(e) {
    var subscriberDialog = document.querySelector('#subscriber-dialog');
    subscriberDialog.show();
}

function handleSubscriberFormConfirmed(e, groupId) {
    var emailInput = document.querySelector('.subscriber-dialog-email');
    var usernameInput = document.querySelector('.subscriber-dialog-username');
    var inputsToValidate = [emailInput, usernameInput];
    var dialogSpinner = document.querySelector('#subscriber-dialog-spinner');
    var dialogButtons = document.querySelectorAll('.subscriber-dialog-button');
    var dialogError = document.querySelector('#subscriber-dialog-error');
    var subscriberDialog = document.querySelector('#subscriber-dialog');

    var confirmDialog = document.querySelector('#subscriber-confirm-dialog');
    var confirmDialogUsernameDiv = document.querySelector('#subscriber-confirm-username');
    var confirmDialogPasswordDiv = document.querySelector('#subscriber-confirm-password');

    var isValid = true;

    dialogError.innerText = "";

    // check form is valid
    inputsToValidate.forEach((input) => {
        if (!!input && !input.validate()) {
            if (!input.value) {
                input.errorMessage = "Required";
            }

            isValid = false;
        }
    });

    if (!isValid) {
        return;
    }

    dialogSpinner.active = true;

    dialogButtons.forEach((button) => {
        button.disabled = true;
    });

    $.ajax({
        url: '/groups/ajaxsubmitsubscriber',
        type: 'POST',
        data: {
            group_id: groupId,
            username: usernameInput.value.toLowerCase(),
            // REMOVED FOR NOW, MAY NOT RETURN
            // email: emailInput.value.toLowerCase()
        },
        success: function (response) {
            dialogSpinner.active = false;

            dialogButtons.forEach((button) => {
                button.disabled = false;
            });

            if (response.error) {
                dialogError.innerText = response.error;
                return;
            }

            // allow the search to occur based on having an extra member
            $(".page_size").val($(".page_size").val() + 1);

            $("#group_people_search_button").click();

            $("#subscriber-option").attr('item-title', 'Subscribed');
            $(".subscriber-dialog-title").text('Subscribed');
            $(".subscriber-dialog-name").text(response.username);
            $(".subscriber-dialog-mail").text(response.email);

            $(".subscriber-dialog-non, #subscriber-dialog-cancel, #subscriber-dialog-confirm, #subscriber-dialog-spinner").hide();
            $(".subscriber-dialog-exists, #subscriber-dialog-ok").removeAttr('hidden');

            confirmDialogUsernameDiv.innerHTML = "Username : " + response.username;
            confirmDialogPasswordDiv.innerHTML = "Password : " + response.password;

            subscriberDialog.close();
            confirmDialog.show();
        },
        error: function (message) {
            dialogSpinner.active = false;

            dialogButtons.forEach((button) => {
                button.disabled = false;
            });

            // undefined error message here
            console.error("Error Happened: ", message);
        }
    });
}

export function setSelectedTabFromHash(paperTabs, pages) {
    var tabtoSelect = location.hash.substring(1);

    if (tabtoSelect) {
        // we need to check that the hash is a valid hash when visiting the page, otherwise itll break
        // this will fallback on wall
        Array.from(paperTabs.children).some(function (tab) {
            if (tab.getAttribute('value') === tabtoSelect) {
                paperTabs.select(tabtoSelect);
                pages.select(tabtoSelect);
                return true;
            }
        });
    }
}

async function handleAddedGroupOwner(selectedId, groupOwnerMap) {
    return new Promise(async (res, rej) => {
        var dropdownToHandle = document.querySelector('.add-event-owners cbar-select2');

        var eventsResponse = await getGroupEvents(selectedId);

        var events = [];
        var eventsToDisplay = eventsResponse.events;
        var eventsAlreadyDisplaying = Array.from(dropdownToHandle.selectEle.options);

        groupOwnerMap[selectedId] = {
            events
        }

        eventsToDisplay.forEach((eventToDisplay) => {
            if (eventsAlreadyDisplaying.find(eventAlreadyDisplaying => eventAlreadyDisplaying.value === eventToDisplay.id)) {
                return
            }

            events.push(eventToDisplay);
        })

        if (events.length === 0) {
            res();
            return;
        }

        $('.event-owner-row').removeAttr('hidden');

        events.forEach(event => {
            var newOption = new Option(event.name, event.id, false, false);
            dropdownToHandle.select2Ele.append(newOption).trigger('change');
        });

        res();
    })
}

async function handleRemovedGroup(elementToRemove, groupOwnerMap, selectedId){
    elementToRemove.remove();

    var existingOwnerEvents = document.querySelectorAll('cbar-places-item.event-owner');
    var existingOwners = document.querySelectorAll('cbar-places-item.group-owner');
    var dropdownToHandle = document.querySelector('.add-event-owners cbar-select2');
    var dropdownOptions = Array.from(dropdownToHandle.selectEle.options);

    if(existingOwners.length === 0){
        $('.event-owner-row').attr('hidden', true);
    }

    let eventOwnersToRemove = groupOwnerMap[selectedId].events;

    eventOwnersToRemove.forEach(eventOwner => {
        let optionToRemove = dropdownOptions.find(optionElement => optionElement.value === eventOwner.id.toString());
        let entryToRemove = Array.from(existingOwnerEvents).find(itemElement => itemElement.id.toString() === eventOwner.id.toString());

        if(optionToRemove) {
            optionToRemove.remove();
        }

        if(entryToRemove){
            entryToRemove.remove();
        }
    });

    if(dropdownToHandle.selectEle.options.length === 0){
        $('.event-owner-row').attr('hidden', true);
    }

    dropdownToHandle.select2Ele.val("").trigger('change');

    delete groupOwnerMap[selectedId];
}

async function getGroupEvents(groupId){
    return new Promise((res, rej) => {
        $.ajax({
            url: '/groups/ajaxgetgroupevents',
            type: 'GET',
            data: {
                group_ids: [groupId],
                excluding_ids: $("#event_id").val() ? [$("#event_id").val()] : []
            },
            success: function (response) {
                res(response)
            }
        })
    })
}
