import {reformatDatePickerTime} from "./general";

$(function () {
    var getAvailableRoles = function (groupID) {
        $(".loading-roles").fadeIn("fast");
        var params = { 
            "action": "list-roles-operation",
            "source_entity": "Profile",
            "source_entity_id": 0,
            "target_entity": "Group",
            "target_entity_id": groupID
        };

        $.ajax({
            url: "/ajax/entity-roles",
            type: "POST",
            data: params,
            success: function (data) {
                if (data.error === false) {
                    $(".roles_to_add option").remove();
                    $(".roles_to_add")
                        .append($("<option>", { value : 0 })
                        .text("- Available Roles -"));

                    $.each(data.data, function (index, element) {
                        var optionParams = {
                            "data-start-time": reformatDatePickerTime(element.start_time),
                            "data-end-time": reformatDatePickerTime(element.end_time),
                            "data-quota": element.role_id,
                            "value": element.role_id
                        };
                        $(".roles_to_add")
                            .append($("<option>", optionParams)
                            .text(element.role_name));
                    });

                    $(".start_time").val("");
                    $(".end_time").val("");
                }
                $(".loading-roles").hide();
            }
        });
    };


    if ($("body").is("#profiles, #addperson")) {
        $("#form").on("change", ".groups", function() {
            if (parseInt($(".groups").val()) > 0) {
                $(".roles_to_add, .start_time, .end_time").show();
                getAvailableRoles($(".groups").val());
            } else {
                $(".roles_to_add, .start_time, .end_time").hide();
            }
        });

        $("#form").on("change", ".roles_to_add", function() {
            if (parseInt($(".roles_to_add").val()) > 0) {
                var selectedRole = $(".roles_to_add").find(":selected");
                $(".start_time").val(selectedRole.attr("data-start-time"));
                $(".end_time").val(selectedRole.attr("data-end-time"));
            } else {
                $(".start_time").val("");
                $(".end_time").val("");
            }
        });

        $(".groups").trigger("change");
        $(".roles_to_add").trigger("change");

    }

});
