

if($('body').is('#contacts')) {
    let loadingContacts = false;

    window.addEventListener('scroll', function (e) {
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 500)) {
            requestAnimationFrame(function(){
                if(!loadingContacts) {
                    loadingContacts = true;
                    getMoreResponses();
                }
            });
        }
    });

    function getMoreResponses() {
        let countOfResponses = document.querySelector('#total-count').value;
        let contacts = document.querySelector('cbar-contacts-list');
        let spinner = document.querySelector('paper-spinner');

        if (contacts.list.length >= countOfResponses) {
            return
        }

        spinner.active = true;

        $.ajax({
            type: "GET",
            dataType: "json",
            url: "/contacts/ajaxgetcontacts",
            data: {
                current_count: contacts.list.length
            },
            success: function (response) {
                let existingContacts = contacts.list;
                contacts.list = [];
                requestAnimationFrame(function () {
                    contacts.list = existingContacts.concat(response.results);
                })
                spinner.active = false;
                loadingContacts = false;
            }
        });
    }
}